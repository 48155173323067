




































import { Component, Vue } from 'vue-property-decorator';
import LinkWithArrow from '@/components/partials/LinkWithArrow.vue';
import Logo from '@/components/partials/Logo.vue';

@Component({
  components: { LinkWithArrow, Logo },
})
export default class Footer extends Vue {

}
