import { Configuration as ProjectApiConfig } from '@/apis/projectapi/configuration';
import { DefaultApi as ProjectApi } from '@/apis/projectapi';
import { Configuration as UserApiConfig } from '@/apis/userapi/configuration';
import { DefaultApi as UserApi } from '@/apis/userapi';
import { Configuration as ContentPagesApiConfig } from '@/apis/contentpagesapi/configuration';
import { DefaultApi as ContentPagesApi } from '@/apis/contentpagesapi';
import { Configuration as SurveyApiConfig } from '@/apis/surveyapi/configuration';
import { DefaultApi as SurveyApi } from '@/apis/surveyapi';
import { Configuration as MailApiConfig } from '@/apis/mail-service/configuration';
import { DefaultApi as MailApi } from '@/apis/mail-service';
import { Configuration as NewsApiConfig } from '@/apis/newsapi/configuration';
import { DefaultApi as NewsApi } from '@/apis/newsapi';

export default class Services {
    private static apiGatewayUrl: string = process.env.VUE_APP_API_GATEWAY_URL;

    public static projects: ProjectApi = new ProjectApi(new ProjectApiConfig({ basePath: Services.apiGatewayUrl, baseOptions: { withCredentials: true } }));
    public static users: UserApi = new UserApi(new UserApiConfig({ basePath: Services.apiGatewayUrl, baseOptions: { withCredentials: true } }));
    public static contentPages: ContentPagesApi = new ContentPagesApi(new ContentPagesApiConfig({ basePath: Services.apiGatewayUrl, baseOptions: { withCredentials: true } }));
    public static surveys: SurveyApi = new SurveyApi(new SurveyApiConfig({ basePath: Services.apiGatewayUrl, baseOptions: { withCredentials: true } }));
    public static mails: MailApi = new MailApi(new MailApiConfig({ basePath: Services.apiGatewayUrl, baseOptions: { withCredentials: true } }));
    public static news: NewsApi = new NewsApi(new NewsApiConfig({ basePath: Services.apiGatewayUrl, baseOptions: { withCredentials: true } }));
}
