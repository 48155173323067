




import { Component, Vue, Prop } from 'vue-property-decorator';
import Header from '@/assets/headers';

@Component({})
export default class TextHeader extends Vue {

    @Prop({ required: true })
    private readonly text!:string;

    @Prop({ required: true })
    private readonly variant!:Header;

    @Prop({ required: true })
    private readonly color!: string;

    private get classes() {
      let classes = 'break-words text-left ';
      if (this.variant === Header.PRIMARY) {
        classes += 'text-3xl';
      } else if (this.variant === Header.SECONDARY) {
        classes += 'text-2xl';
      } else if (this.variant === Header.TERTIARY) {
        classes += 'text-1xl';
      }
      return `${classes} text-${this.color}`;
    }

}
