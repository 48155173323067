



















































































































import { Component, Vue } from 'vue-property-decorator';
import { RouteConfig } from 'vue-router';
import { routes } from '@/router/router';
import RoundedButtonOutlined from '@/components/partials/RoundedButtonOutlined.vue';
import CircleWithInitials from '@/components/partials/CircleWithInitials.vue';
import Logo from '@/components/partials/Logo.vue';
import Role from '@/assets/roles';
import Authservice from '@/assets/services/Authservice';
import ButtonType from '@/assets/buttonTypes';

@Component({
  components: {
    RoundedButtonOutlined,
    CircleWithInitials,
    Logo,
  },
})
export default class NavigationBar extends Vue {
  private showMobileMenu = false;
  private ButtonType = ButtonType;

  private login() {
    Authservice.login();
  }

  private async logout() {
    await Authservice.logout();
  }

  private get isLoggedIn() {
    return this.$store.state.isLoggedIn;
  }

  private get userInitials() {
    return this.$store.state.userInitials;
  }

  private get mainMenuItems() {
    return routes.filter((route: RouteConfig) => route.meta?.navigationbar && route.meta?.roles.includes(this.$store.state.role));
  }

  private get personalDataAndSignOutItems() {
    if (this.$store.state.role === Role.ADMIN) {
      return [
        { name: 'Pflichtseiten bearbeiten', href: '/content-management' },
        { name: 'News verwalten', href: '/news-management' },
        { name: 'Persönliche Daten', href: '/personal-data' },
        { name: 'Ausloggen', href: '/', function: () => this.logout() },
      ];
    }

    return [
      { name: 'Persönliche Daten', href: '/personal-data' },
      { name: 'Ausloggen', href: '/', function: () => this.logout() },
    ];
  }

  private get dropDownContents() {
    if (this.$store.state.role === Role.DEVELOPER) {
      return [
        { name: 'Apps &amp; Projekte', href: '/project-management' },
        { name: 'Meine Umfragen', href: '/surveys-developers' },
      ];
    }

    if (this.$store.state.role === Role.ADMIN) {
      return [
        { name: 'Benutzerverwaltung', href: '/user-management' },
        { name: 'Alle Apps &amp; Projekte', href: '/project-management' },
        { name: 'Alle Umfragen', href: '/surveys-developers' },
      ];
    }

    if (this.$store.state.role === Role.USER) {
      return [
        { name: 'Meine Apps &amp; Projekte', href: '/myapps' },
      ];
    }

    return [];
  }
}
