export default class DateService {

    public static dateTimeFormatter = new Intl.DateTimeFormat('de-DE', {
      year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit',
    })

    public static dateTimeFormatterWithoutTime = new Intl.DateTimeFormat('de-DE', {
      year: 'numeric', month: 'long', day: '2-digit',
    });

    public static dateTimeFormatterWith2DigitMonthAndWithoutTime = new Intl.DateTimeFormat('de-DE', {
      year: 'numeric', month: '2-digit', day: '2-digit',
    })

}
