







import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class CircleWithInitials extends Vue {
  @Prop({ required: true })
  private readonly initials!: string;
}
