










import {
  Component, Emit, Prop, Vue,
} from 'vue-property-decorator';

@Component({})
export default class SearchBar extends Vue {
  @Prop({ default: 'Suchen...' })
  private placeholder!:string;

  private searchText:string = '';

  @Emit()
  private searchButtonClicked() {
    return this.searchText;
  }
}
