/* tslint:disable */
/* eslint-disable */
/**
 * Innovamo Mail Service
 * Innovamo Mail Service
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface ContactRequest
 */
export interface ContactRequest {
    /**
     * 
     * @type {string}
     * @memberof ContactRequest
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof ContactRequest
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof ContactRequest
     */
    message?: string;
}
/**
 * 
 * @export
 * @interface InlineObject
 */
export interface InlineObject {
    /**
     * 
     * @type {string}
     * @memberof InlineObject
     */
    userId?: string;
}
/**
 * 
 * @export
 * @interface InlineObject1
 */
export interface InlineObject1 {
    /**
     * 
     * @type {string}
     * @memberof InlineObject1
     */
    surveyName?: string;
}
/**
 * 
 * @export
 * @interface InlineObject2
 */
export interface InlineObject2 {
    /**
     * 
     * @type {string}
     * @memberof InlineObject2
     */
    developerMail?: string;
    /**
     * 
     * @type {string}
     * @memberof InlineObject2
     */
    surveyName?: string;
    /**
     * 
     * @type {string}
     * @memberof InlineObject2
     */
    rejectionReason?: string;
}
/**
 * 
 * @export
 * @interface InlineObject3
 */
export interface InlineObject3 {
    /**
     * 
     * @type {string}
     * @memberof InlineObject3
     */
    surveyName?: string;
}
/**
 * 
 * @export
 * @interface InlineObject4
 */
export interface InlineObject4 {
    /**
     * 
     * @type {string}
     * @memberof InlineObject4
     */
    developerMail?: string;
    /**
     * 
     * @type {string}
     * @memberof InlineObject4
     */
    surveyName?: string;
    /**
     * 
     * @type {string}
     * @memberof InlineObject4
     */
    rejectionReason?: string;
}

/**
 * DefaultApi - axios parameter creator
 * @export
 */
export const DefaultApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {ContactRequest} [contactRequest] the contact request containing name, email and message
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendContactRequest: async (contactRequest?: ContactRequest, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/mail/contactrequest`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(contactRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {InlineObject} [inlineObject] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendMailWithNewUserDataToAdmins: async (inlineObject?: InlineObject, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/mail/new-dev-user-notification`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(inlineObject, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ContactRequest} [contactRequest] the contact request containing name, email and message
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendProjectAcceptedMail: async (contactRequest?: ContactRequest, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/mail/project-accepted-notification`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(contactRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {InlineObject4} [inlineObject4] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendProjectRejectedMail: async (inlineObject4?: InlineObject4, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/mail/project-rejected-notification`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(inlineObject4, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {InlineObject3} [inlineObject3] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendProjectToReviewNotificationToAdmins: async (inlineObject3?: InlineObject3, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/mail/project-to-review-notification`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(inlineObject3, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ContactRequest} [contactRequest] the contact request containing name, email and message
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendSurveyAcceptedMail: async (contactRequest?: ContactRequest, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/mail/survey-accepted-notification`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(contactRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {InlineObject2} [inlineObject2] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendSurveyRejectedMail: async (inlineObject2?: InlineObject2, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/mail/survey-rejected-notification`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(inlineObject2, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {InlineObject1} [inlineObject1] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendSurveyToReviewNotificationToAdmins: async (inlineObject1?: InlineObject1, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/mail/survey-to-review-notification`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(inlineObject1, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DefaultApi - functional programming interface
 * @export
 */
export const DefaultApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DefaultApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {ContactRequest} [contactRequest] the contact request containing name, email and message
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sendContactRequest(contactRequest?: ContactRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sendContactRequest(contactRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {InlineObject} [inlineObject] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sendMailWithNewUserDataToAdmins(inlineObject?: InlineObject, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sendMailWithNewUserDataToAdmins(inlineObject, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ContactRequest} [contactRequest] the contact request containing name, email and message
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sendProjectAcceptedMail(contactRequest?: ContactRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sendProjectAcceptedMail(contactRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {InlineObject4} [inlineObject4] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sendProjectRejectedMail(inlineObject4?: InlineObject4, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sendProjectRejectedMail(inlineObject4, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {InlineObject3} [inlineObject3] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sendProjectToReviewNotificationToAdmins(inlineObject3?: InlineObject3, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sendProjectToReviewNotificationToAdmins(inlineObject3, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ContactRequest} [contactRequest] the contact request containing name, email and message
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sendSurveyAcceptedMail(contactRequest?: ContactRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sendSurveyAcceptedMail(contactRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {InlineObject2} [inlineObject2] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sendSurveyRejectedMail(inlineObject2?: InlineObject2, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sendSurveyRejectedMail(inlineObject2, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {InlineObject1} [inlineObject1] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sendSurveyToReviewNotificationToAdmins(inlineObject1?: InlineObject1, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sendSurveyToReviewNotificationToAdmins(inlineObject1, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DefaultApi - factory interface
 * @export
 */
export const DefaultApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DefaultApiFp(configuration)
    return {
        /**
         * 
         * @param {ContactRequest} [contactRequest] the contact request containing name, email and message
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendContactRequest(contactRequest?: ContactRequest, options?: any): AxiosPromise<void> {
            return localVarFp.sendContactRequest(contactRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {InlineObject} [inlineObject] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendMailWithNewUserDataToAdmins(inlineObject?: InlineObject, options?: any): AxiosPromise<void> {
            return localVarFp.sendMailWithNewUserDataToAdmins(inlineObject, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ContactRequest} [contactRequest] the contact request containing name, email and message
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendProjectAcceptedMail(contactRequest?: ContactRequest, options?: any): AxiosPromise<void> {
            return localVarFp.sendProjectAcceptedMail(contactRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {InlineObject4} [inlineObject4] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendProjectRejectedMail(inlineObject4?: InlineObject4, options?: any): AxiosPromise<void> {
            return localVarFp.sendProjectRejectedMail(inlineObject4, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {InlineObject3} [inlineObject3] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendProjectToReviewNotificationToAdmins(inlineObject3?: InlineObject3, options?: any): AxiosPromise<void> {
            return localVarFp.sendProjectToReviewNotificationToAdmins(inlineObject3, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ContactRequest} [contactRequest] the contact request containing name, email and message
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendSurveyAcceptedMail(contactRequest?: ContactRequest, options?: any): AxiosPromise<void> {
            return localVarFp.sendSurveyAcceptedMail(contactRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {InlineObject2} [inlineObject2] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendSurveyRejectedMail(inlineObject2?: InlineObject2, options?: any): AxiosPromise<void> {
            return localVarFp.sendSurveyRejectedMail(inlineObject2, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {InlineObject1} [inlineObject1] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendSurveyToReviewNotificationToAdmins(inlineObject1?: InlineObject1, options?: any): AxiosPromise<void> {
            return localVarFp.sendSurveyToReviewNotificationToAdmins(inlineObject1, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DefaultApi - object-oriented interface
 * @export
 * @class DefaultApi
 * @extends {BaseAPI}
 */
export class DefaultApi extends BaseAPI {
    /**
     * 
     * @param {ContactRequest} [contactRequest] the contact request containing name, email and message
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public sendContactRequest(contactRequest?: ContactRequest, options?: any) {
        return DefaultApiFp(this.configuration).sendContactRequest(contactRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {InlineObject} [inlineObject] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public sendMailWithNewUserDataToAdmins(inlineObject?: InlineObject, options?: any) {
        return DefaultApiFp(this.configuration).sendMailWithNewUserDataToAdmins(inlineObject, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ContactRequest} [contactRequest] the contact request containing name, email and message
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public sendProjectAcceptedMail(contactRequest?: ContactRequest, options?: any) {
        return DefaultApiFp(this.configuration).sendProjectAcceptedMail(contactRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {InlineObject4} [inlineObject4] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public sendProjectRejectedMail(inlineObject4?: InlineObject4, options?: any) {
        return DefaultApiFp(this.configuration).sendProjectRejectedMail(inlineObject4, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {InlineObject3} [inlineObject3] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public sendProjectToReviewNotificationToAdmins(inlineObject3?: InlineObject3, options?: any) {
        return DefaultApiFp(this.configuration).sendProjectToReviewNotificationToAdmins(inlineObject3, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ContactRequest} [contactRequest] the contact request containing name, email and message
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public sendSurveyAcceptedMail(contactRequest?: ContactRequest, options?: any) {
        return DefaultApiFp(this.configuration).sendSurveyAcceptedMail(contactRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {InlineObject2} [inlineObject2] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public sendSurveyRejectedMail(inlineObject2?: InlineObject2, options?: any) {
        return DefaultApiFp(this.configuration).sendSurveyRejectedMail(inlineObject2, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {InlineObject1} [inlineObject1] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public sendSurveyToReviewNotificationToAdmins(inlineObject1?: InlineObject1, options?: any) {
        return DefaultApiFp(this.configuration).sendSurveyToReviewNotificationToAdmins(inlineObject1, options).then((request) => request(this.axios, this.basePath));
    }
}


