














import { Component, Prop, Vue } from 'vue-property-decorator';
import LoadingAnimation from '@/components/partials/LoadingAnimation.vue';
import TextHeader from '@/components/partials/TextHeader.vue';
import Header from '@/assets/headers';
import UnexpectedErrorMessage from '@/components/partials/UnexpectedErrorMessage.vue';

@Component({
  components: {
    LoadingAnimation, TextHeader, UnexpectedErrorMessage,
  },
})
export default class Page extends Vue {
    private Header = Header;

    @Prop({ default: '' })
    private headerText!: string;

    @Prop({ default: false })
    private loading!: boolean;

    @Prop({ default: false })
    private showUnexpectedErrorMessage!: boolean;

}
