export default class Sort {
  public static readonly NAME_ASCENDING = new Sort('name', 'asc');
  public static readonly NAME_DESCENDING = new Sort('name', 'desc');
  public static readonly DATE_ASCENDING = new Sort('date', 'asc');
  public static readonly DATE_DESCENDING = new Sort('date', 'desc');

  public readonly value: 'name' | 'date';
  public readonly order: 'asc' | 'desc';

  private constructor(value: 'name' | 'date', order: 'asc' | 'desc') {
    this.value = value;
    this.order = order;
  }
}
