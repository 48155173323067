/* tslint:disable */
/* eslint-disable */
/**
 * Survey Service
 * This is the API documentation of the Survey service
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface AnswerEvaluation
 */
export interface AnswerEvaluation {
    /**
     * 
     * @type {number}
     * @memberof AnswerEvaluation
     */
    answerId?: number;
    /**
     * 
     * @type {string}
     * @memberof AnswerEvaluation
     */
    freetextanswer?: string;
    /**
     * 
     * @type {number}
     * @memberof AnswerEvaluation
     */
    count?: number;
}
/**
 * 
 * @export
 * @interface FileUpload
 */
export interface FileUpload {
    /**
     * 
     * @type {number}
     * @memberof FileUpload
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof FileUpload
     */
    uuid?: string;
    /**
     * 
     * @type {string}
     * @memberof FileUpload
     */
    filename?: string;
    /**
     * 
     * @type {string}
     * @memberof FileUpload
     */
    filetype?: string;
    /**
     * 
     * @type {boolean}
     * @memberof FileUpload
     */
    isAppLogo?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FileUpload
     */
    isTemporaryUpload?: boolean;
}
/**
 * 
 * @export
 * @interface InlineResponse200
 */
export interface InlineResponse200 {
    /**
     * 
     * @type {boolean}
     * @memberof InlineResponse200
     */
    submissionExists?: boolean;
}
/**
 * 
 * @export
 * @interface PaginatedSurveyOverviewResponse
 */
export interface PaginatedSurveyOverviewResponse {
    /**
     * 
     * @type {Array<SurveyOverviewRestDTO>}
     * @memberof PaginatedSurveyOverviewResponse
     */
    surveys?: Array<SurveyOverviewRestDTO>;
    /**
     * 
     * @type {number}
     * @memberof PaginatedSurveyOverviewResponse
     */
    totalPages?: number;
    /**
     * 
     * @type {number}
     * @memberof PaginatedSurveyOverviewResponse
     */
    totalItemsFound?: number;
    /**
     * 
     * @type {number}
     * @memberof PaginatedSurveyOverviewResponse
     */
    currentPage?: number;
}
/**
 * 
 * @export
 * @enum {string}
 */

export enum QuestionType {
    Freetext = 'FREETEXT',
    Scale = 'SCALE',
    Choice = 'CHOICE',
    Slider = 'SLIDER'
}

/**
 * 
 * @export
 * @interface RejectionReason
 */
export interface RejectionReason {
    /**
     * 
     * @type {string}
     * @memberof RejectionReason
     */
    surveyUuid?: string;
    /**
     * 
     * @type {string}
     * @memberof RejectionReason
     */
    reviewerUuid?: string;
    /**
     * 
     * @type {string}
     * @memberof RejectionReason
     */
    text?: string;
    /**
     * 
     * @type {string}
     * @memberof RejectionReason
     */
    date?: string;
}
/**
 * 
 * @export
 * @interface ScaleSubQuestionAnswer
 */
export interface ScaleSubQuestionAnswer {
    /**
     * 
     * @type {number}
     * @memberof ScaleSubQuestionAnswer
     */
    subQuestionId?: number;
    /**
     * 
     * @type {number}
     * @memberof ScaleSubQuestionAnswer
     */
    scale_choice?: number;
}
/**
 * 
 * @export
 * @interface SliderColorDataDto
 */
export interface SliderColorDataDto {
    /**
     * 
     * @type {SliderColorDto}
     * @memberof SliderColorDataDto
     */
    slider_color_left?: SliderColorDto;
    /**
     * 
     * @type {SliderColorDto}
     * @memberof SliderColorDataDto
     */
    slider_color_right?: SliderColorDto;
}
/**
 * 
 * @export
 * @interface SliderColorDto
 */
export interface SliderColorDto {
    /**
     * 
     * @type {string}
     * @memberof SliderColorDto
     */
    hex?: string;
}
/**
 * 
 * @export
 * @interface SurveyAnswerRestDTO
 */
export interface SurveyAnswerRestDTO {
    /**
     * 
     * @type {number}
     * @memberof SurveyAnswerRestDTO
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof SurveyAnswerRestDTO
     */
    survey_result_id?: number;
    /**
     * 
     * @type {number}
     * @memberof SurveyAnswerRestDTO
     */
    survey_question_id?: number;
    /**
     * 
     * @type {QuestionType}
     * @memberof SurveyAnswerRestDTO
     */
    questionType?: QuestionType;
    /**
     * 
     * @type {Array<ScaleSubQuestionAnswer>}
     * @memberof SurveyAnswerRestDTO
     */
    scale_choices?: Array<ScaleSubQuestionAnswer>;
    /**
     * 
     * @type {number}
     * @memberof SurveyAnswerRestDTO
     */
    choice_answer_id?: number;
    /**
     * 
     * @type {Array<number>}
     * @memberof SurveyAnswerRestDTO
     */
    choice_multiple_answer_ids?: Array<number>;
    /**
     * 
     * @type {string}
     * @memberof SurveyAnswerRestDTO
     */
    freetest_answer?: string;
    /**
     * 
     * @type {number}
     * @memberof SurveyAnswerRestDTO
     */
    slider_value?: number;
}
/**
 * 
 * @export
 * @interface SurveyEvaluationResult
 */
export interface SurveyEvaluationResult {
    /**
     * 
     * @type {number}
     * @memberof SurveyEvaluationResult
     */
    questionId?: number;
    /**
     * 
     * @type {number}
     * @memberof SurveyEvaluationResult
     */
    subQuestionId?: number;
    /**
     * 
     * @type {Array<AnswerEvaluation>}
     * @memberof SurveyEvaluationResult
     */
    answerCounts?: Array<AnswerEvaluation>;
}
/**
 * 
 * @export
 * @interface SurveyOverviewRestDTO
 */
export interface SurveyOverviewRestDTO {
    /**
     * 
     * @type {number}
     * @memberof SurveyOverviewRestDTO
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof SurveyOverviewRestDTO
     */
    uuid?: string;
    /**
     * 
     * @type {string}
     * @memberof SurveyOverviewRestDTO
     */
    slug?: string;
    /**
     * 
     * @type {string}
     * @memberof SurveyOverviewRestDTO
     */
    developer_id?: string;
    /**
     * 
     * @type {SurveyStatus}
     * @memberof SurveyOverviewRestDTO
     */
    status?: SurveyStatus;
    /**
     * 
     * @type {string}
     * @memberof SurveyOverviewRestDTO
     */
    title?: string;
    /**
     * 
     * @type {string}
     * @memberof SurveyOverviewRestDTO
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof SurveyOverviewRestDTO
     */
    time_start?: string;
    /**
     * 
     * @type {string}
     * @memberof SurveyOverviewRestDTO
     */
    time_end?: string;
    /**
     * 
     * @type {string}
     * @memberof SurveyOverviewRestDTO
     */
    created_at?: string;
    /**
     * 
     * @type {string}
     * @memberof SurveyOverviewRestDTO
     */
    updated_at?: string;
    /**
     * 
     * @type {boolean}
     * @memberof SurveyOverviewRestDTO
     */
    published?: boolean;
    /**
     * 
     * @type {number}
     * @memberof SurveyOverviewRestDTO
     */
    nr_of_questions?: number;
    /**
     * 
     * @type {number}
     * @memberof SurveyOverviewRestDTO
     */
    nr_of_answers?: number;
    /**
     * 
     * @type {number}
     * @memberof SurveyOverviewRestDTO
     */
    nr_of_impressions?: number;
    /**
     * 
     * @type {boolean}
     * @memberof SurveyOverviewRestDTO
     */
    is_app_specific?: boolean;
    /**
     * 
     * @type {FileUpload}
     * @memberof SurveyOverviewRestDTO
     */
    image?: FileUpload;
}
/**
 * 
 * @export
 * @interface SurveyQuestionAnswerRestDTO
 */
export interface SurveyQuestionAnswerRestDTO {
    /**
     * 
     * @type {number}
     * @memberof SurveyQuestionAnswerRestDTO
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof SurveyQuestionAnswerRestDTO
     */
    question_id?: number;
    /**
     * 
     * @type {string}
     * @memberof SurveyQuestionAnswerRestDTO
     */
    answer_text?: string;
    /**
     * 
     * @type {number}
     * @memberof SurveyQuestionAnswerRestDTO
     */
    position?: number;
}
/**
 * 
 * @export
 * @interface SurveyQuestionRestDTO
 */
export interface SurveyQuestionRestDTO {
    /**
     * 
     * @type {number}
     * @memberof SurveyQuestionRestDTO
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof SurveyQuestionRestDTO
     */
    section_id?: number;
    /**
     * 
     * @type {QuestionType}
     * @memberof SurveyQuestionRestDTO
     */
    question_type?: QuestionType;
    /**
     * 
     * @type {string}
     * @memberof SurveyQuestionRestDTO
     */
    scale_type?: SurveyQuestionRestDTOScaleTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof SurveyQuestionRestDTO
     */
    scale_num_of_answers?: number;
    /**
     * 
     * @type {string}
     * @memberof SurveyQuestionRestDTO
     */
    choice_type?: SurveyQuestionRestDTOChoiceTypeEnum;
    /**
     * 
     * @type {boolean}
     * @memberof SurveyQuestionRestDTO
     */
    choice_allow_freetext?: boolean;
    /**
     * 
     * @type {string}
     * @memberof SurveyQuestionRestDTO
     */
    choice_freetext_hint?: string;
    /**
     * 
     * @type {string}
     * @memberof SurveyQuestionRestDTO
     */
    freetext_question?: string;
    /**
     * 
     * @type {string}
     * @memberof SurveyQuestionRestDTO
     */
    slider_question?: string;
    /**
     * 
     * @type {SliderColorDataDto}
     * @memberof SurveyQuestionRestDTO
     */
    slider_colors?: SliderColorDataDto;
    /**
     * 
     * @type {boolean}
     * @memberof SurveyQuestionRestDTO
     */
    is_optional?: boolean;
    /**
     * 
     * @type {number}
     * @memberof SurveyQuestionRestDTO
     */
    position?: number;
    /**
     * 
     * @type {Array<SurveyQuestionAnswerRestDTO>}
     * @memberof SurveyQuestionRestDTO
     */
    scale_subquestions?: Array<SurveyQuestionAnswerRestDTO>;
    /**
     * 
     * @type {Array<SurveyQuestionAnswerRestDTO>}
     * @memberof SurveyQuestionRestDTO
     */
    choice_answers?: Array<SurveyQuestionAnswerRestDTO>;
}

/**
    * @export
    * @enum {string}
    */
export enum SurveyQuestionRestDTOScaleTypeEnum {
    Smileys = 'smileys',
    Numbers = 'numbers',
    Yesno = 'yesno'
}
/**
    * @export
    * @enum {string}
    */
export enum SurveyQuestionRestDTOChoiceTypeEnum {
    Single = 'single',
    Multiple = 'multiple',
    Combobox = 'combobox'
}

/**
 * 
 * @export
 * @interface SurveyRestDTO
 */
export interface SurveyRestDTO {
    /**
     * 
     * @type {number}
     * @memberof SurveyRestDTO
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof SurveyRestDTO
     */
    uuid?: string;
    /**
     * 
     * @type {string}
     * @memberof SurveyRestDTO
     */
    developer_id?: string;
    /**
     * 
     * @type {SurveyStatus}
     * @memberof SurveyRestDTO
     */
    status?: SurveyStatus;
    /**
     * 
     * @type {string}
     * @memberof SurveyRestDTO
     */
    title?: string;
    /**
     * 
     * @type {string}
     * @memberof SurveyRestDTO
     */
    slug?: string;
    /**
     * 
     * @type {string}
     * @memberof SurveyRestDTO
     */
    description?: string;
    /**
     * 
     * @type {number}
     * @memberof SurveyRestDTO
     */
    nrOfImpressions?: number;
    /**
     * 
     * @type {number}
     * @memberof SurveyRestDTO
     */
    nrOfSubmissions?: number;
    /**
     * 
     * @type {string}
     * @memberof SurveyRestDTO
     */
    time_start?: string;
    /**
     * 
     * @type {string}
     * @memberof SurveyRestDTO
     */
    time_end?: string;
    /**
     * 
     * @type {boolean}
     * @memberof SurveyRestDTO
     */
    isAppSpecific?: boolean;
    /**
     * 
     * @type {string}
     * @memberof SurveyRestDTO
     */
    mobileAppKey?: string;
    /**
     * 
     * @type {string}
     * @memberof SurveyRestDTO
     */
    created_at?: string;
    /**
     * 
     * @type {string}
     * @memberof SurveyRestDTO
     */
    updated_at?: string;
    /**
     * 
     * @type {boolean}
     * @memberof SurveyRestDTO
     */
    published?: boolean;
    /**
     * 
     * @type {Array<SurveySectionRestDTO>}
     * @memberof SurveyRestDTO
     */
    sections?: Array<SurveySectionRestDTO>;
    /**
     * 
     * @type {FileUpload}
     * @memberof SurveyRestDTO
     */
    image?: FileUpload;
    /**
     * 
     * @type {Array<RejectionReason>}
     * @memberof SurveyRestDTO
     */
    rejections?: Array<RejectionReason>;
}
/**
 * 
 * @export
 * @interface SurveyResultRestDTO
 */
export interface SurveyResultRestDTO {
    /**
     * 
     * @type {string}
     * @memberof SurveyResultRestDTO
     */
    survey_uuid?: string;
    /**
     * 
     * @type {string}
     * @memberof SurveyResultRestDTO
     */
    created_at?: string;
    /**
     * 
     * @type {Array<SurveyAnswerRestDTO>}
     * @memberof SurveyResultRestDTO
     */
    answers?: Array<SurveyAnswerRestDTO>;
}
/**
 * 
 * @export
 * @interface SurveySectionRestDTO
 */
export interface SurveySectionRestDTO {
    /**
     * 
     * @type {number}
     * @memberof SurveySectionRestDTO
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof SurveySectionRestDTO
     */
    survey_id?: number;
    /**
     * 
     * @type {string}
     * @memberof SurveySectionRestDTO
     */
    section_title?: string;
    /**
     * 
     * @type {string}
     * @memberof SurveySectionRestDTO
     */
    section_description?: string;
    /**
     * 
     * @type {number}
     * @memberof SurveySectionRestDTO
     */
    position?: number;
    /**
     * 
     * @type {Array<SurveyQuestionRestDTO>}
     * @memberof SurveySectionRestDTO
     */
    questions?: Array<SurveyQuestionRestDTO>;
}
/**
 * 
 * @export
 * @enum {string}
 */

export enum SurveyStatus {
    Editing = 'EDITING',
    InReview = 'IN_REVIEW',
    Rejected = 'REJECTED',
    WaitingForStart = 'WAITING_FOR_START',
    InProgress = 'IN_PROGRESS',
    Done = 'DONE'
}

/**
 * 
 * @export
 * @interface SurveyUploadRestDTO
 */
export interface SurveyUploadRestDTO {
    /**
     * 
     * @type {number}
     * @memberof SurveyUploadRestDTO
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof SurveyUploadRestDTO
     */
    survey_id?: number;
    /**
     * 
     * @type {string}
     * @memberof SurveyUploadRestDTO
     */
    uuid?: string;
    /**
     * 
     * @type {string}
     * @memberof SurveyUploadRestDTO
     */
    filename?: string;
    /**
     * 
     * @type {string}
     * @memberof SurveyUploadRestDTO
     */
    uploaded_at?: string;
}

/**
 * DefaultApi - axios parameter creator
 * @export
 */
export const DefaultApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * an admin can accept a survey proposed by a developer
         * @summary Accept proposed survey
         * @param {string} surveyId The UUID of the survey
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        acceptSurvey: async (surveyId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'surveyId' is not null or undefined
            assertParamExists('acceptSurvey', 'surveyId', surveyId)
            const localVarPath = `/surveys/accept/{surveyId}`
                .replace(`{${"surveyId"}}`, encodeURIComponent(String(surveyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Creates a new Survey
         * @summary Create new Survey
         * @param {SurveyRestDTO} survey Survey object that shall be created
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addSurvey: async (survey: SurveyRestDTO, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'survey' is not null or undefined
            assertParamExists('addSurvey', 'survey', survey)
            const localVarPath = `/surveys`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(survey, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} imageId The ID of the image
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteImage: async (imageId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'imageId' is not null or undefined
            assertParamExists('deleteImage', 'imageId', imageId)
            const localVarPath = `/surveys/image/{imageId}`
                .replace(`{${"imageId"}}`, encodeURIComponent(String(imageId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Deletes the Survey with the given UUID
         * @param {string} surveyId The UUID of the survey
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSurveyById: async (surveyId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'surveyId' is not null or undefined
            assertParamExists('deleteSurveyById', 'surveyId', surveyId)
            const localVarPath = `/surveys/{surveyId}`
                .replace(`{${"surveyId"}}`, encodeURIComponent(String(surveyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} imageId The ID of the image
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTemporaryImage: async (imageId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'imageId' is not null or undefined
            assertParamExists('deleteTemporaryImage', 'imageId', imageId)
            const localVarPath = `/surveys/image/temporary/{imageId}`
                .replace(`{${"imageId"}}`, encodeURIComponent(String(imageId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} imageId The ID of the image
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadImage: async (imageId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'imageId' is not null or undefined
            assertParamExists('downloadImage', 'imageId', imageId)
            const localVarPath = `/surveys/image/{imageId}`
                .replace(`{${"imageId"}}`, encodeURIComponent(String(imageId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} imageId The ID of the image
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadTemporaryImage: async (imageId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'imageId' is not null or undefined
            assertParamExists('downloadTemporaryImage', 'imageId', imageId)
            const localVarPath = `/surveys/image/temporary/{imageId}`
                .replace(`{${"imageId"}}`, encodeURIComponent(String(imageId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get all surveys
         * @summary Get all surveys
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllSurveys: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/surveys`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns the published surveys that can be filled in
         * @param {number} [page] The page number to return
         * @param {number} [pageSize] The number of items to return in one page
         * @param {string} [q] The string used for searching the list of apps
         * @param {'name' | 'start-date' | 'end-date'} [sortBy] The property to sort the results by
         * @param {'asc' | 'desc'} [order] Determines ascending or descending sorting
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPublishedSurveys: async (page?: number, pageSize?: number, q?: string, sortBy?: 'name' | 'start-date' | 'end-date', order?: 'asc' | 'desc', options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/surveys/published`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (q !== undefined) {
                localVarQueryParameter['q'] = q;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sortBy'] = sortBy;
            }

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns the survey with the given UUID
         * @param {string} surveyId The UUID of the survey
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSurveyById: async (surveyId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'surveyId' is not null or undefined
            assertParamExists('getSurveyById', 'surveyId', surveyId)
            const localVarPath = `/surveys/{surveyId}`
                .replace(`{${"surveyId"}}`, encodeURIComponent(String(surveyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns the survey with the given UUID
         * @param {string} surveySlug The slug of the survey
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSurveyBySlug: async (surveySlug: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'surveySlug' is not null or undefined
            assertParamExists('getSurveyBySlug', 'surveySlug', surveySlug)
            const localVarPath = `/surveys/slug/{surveySlug}`
                .replace(`{${"surveySlug"}}`, encodeURIComponent(String(surveySlug)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns the evaluation results for a survey
         * @param {string} surveyId The UUID of the survey
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSurveyEvaluationById: async (surveyId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'surveyId' is not null or undefined
            assertParamExists('getSurveyEvaluationById', 'surveyId', surveyId)
            const localVarPath = `/surveys/evaluation/{surveyId}`
                .replace(`{${"surveyId"}}`, encodeURIComponent(String(surveyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns the surveys of the current logged in developer or all surveys if the current user is an admin
         * @param {number} [page] The page number to return
         * @param {number} [pageSize] The number of items to return in one page
         * @param {string} [q] The string used for searching the list of apps
         * @param {Array<SurveyStatus>} [filterBy] The App/Project type to filter the results by
         * @param {'name' | 'start-date' | 'end-date'} [sortBy] The property to sort the results by
         * @param {'asc' | 'desc'} [order] Determines ascending or descending sorting
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSurveyOverviewsForDeveloper: async (page?: number, pageSize?: number, q?: string, filterBy?: Array<SurveyStatus>, sortBy?: 'name' | 'start-date' | 'end-date', order?: 'asc' | 'desc', options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/surveys/mysurveys`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (q !== undefined) {
                localVarQueryParameter['q'] = q;
            }

            if (filterBy) {
                localVarQueryParameter['filterBy'] = filterBy;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sortBy'] = sortBy;
            }

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * gets all surveys who has the given project slug name assigned
         * @summary gets all surveys who has the given project slug name assigned
         * @param {string} mobileAppKey the mobileAppKey from a project which the survey is assigned to
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSurveysForMobileAppsByMobileAppKey: async (mobileAppKey: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'mobileAppKey' is not null or undefined
            assertParamExists('getSurveysForMobileAppsByMobileAppKey', 'mobileAppKey', mobileAppKey)
            const localVarPath = `/surveys/mobileapp/{mobileAppKey}`
                .replace(`{${"mobileAppKey"}}`, encodeURIComponent(String(mobileAppKey)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Check if current user has already submitted answers for a survey
         * @summary Check if current user has already submitted answers for a survey
         * @param {number} surveyId The UUID of the survey
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        hasUserSubmittedSurvey: async (surveyId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'surveyId' is not null or undefined
            assertParamExists('hasUserSubmittedSurvey', 'surveyId', surveyId)
            const localVarPath = `/surveys/submit/submitted/{surveyId}`
                .replace(`{${"surveyId"}}`, encodeURIComponent(String(surveyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Increases the impression count of the Survey with the given UUID by one
         * @param {string} surveyId The UUID of the survey
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        increaseImpressionCount: async (surveyId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'surveyId' is not null or undefined
            assertParamExists('increaseImpressionCount', 'surveyId', surveyId)
            const localVarPath = `/surveys/increaseimpressions/{surveyId}`
                .replace(`{${"surveyId"}}`, encodeURIComponent(String(surveyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * an admin can reject a survey proposed by a developer
         * @summary Reject proposed survey
         * @param {RejectionReason} rejectionReason Rejection reason
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rejectSurvey: async (rejectionReason: RejectionReason, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'rejectionReason' is not null or undefined
            assertParamExists('rejectSurvey', 'rejectionReason', rejectionReason)
            const localVarPath = `/surveys/reject`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(rejectionReason, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Adds a new set of answers for a survey
         * @summary Submit survey answers
         * @param {string} surveyId The UUID of the survey
         * @param {SurveyResultRestDTO} surveyResultRestDTO Answered Survey
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        submitSurvey: async (surveyId: string, surveyResultRestDTO: SurveyResultRestDTO, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'surveyId' is not null or undefined
            assertParamExists('submitSurvey', 'surveyId', surveyId)
            // verify required parameter 'surveyResultRestDTO' is not null or undefined
            assertParamExists('submitSurvey', 'surveyResultRestDTO', surveyResultRestDTO)
            const localVarPath = `/surveys/submit/{surveyId}`
                .replace(`{${"surveyId"}}`, encodeURIComponent(String(surveyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(surveyResultRestDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Adds a new set of answers for a survey
         * @summary Submit survey answers from a mobile app
         * @param {string} surveyId The UUID of the survey
         * @param {SurveyResultRestDTO} surveyResultRestDTO Answered Survey
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        submitSurveyFromMobileApp: async (surveyId: string, surveyResultRestDTO: SurveyResultRestDTO, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'surveyId' is not null or undefined
            assertParamExists('submitSurveyFromMobileApp', 'surveyId', surveyId)
            // verify required parameter 'surveyResultRestDTO' is not null or undefined
            assertParamExists('submitSurveyFromMobileApp', 'surveyResultRestDTO', surveyResultRestDTO)
            const localVarPath = `/surveys/submit/mobileapps/{surveyId}`
                .replace(`{${"surveyId"}}`, encodeURIComponent(String(surveyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(surveyResultRestDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Updates the Survey with the given UUID
         * @param {string} surveyId The UUID of the survey
         * @param {SurveyRestDTO} surveyRestDTO The new version of the survey
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSurveyById: async (surveyId: string, surveyRestDTO: SurveyRestDTO, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'surveyId' is not null or undefined
            assertParamExists('updateSurveyById', 'surveyId', surveyId)
            // verify required parameter 'surveyRestDTO' is not null or undefined
            assertParamExists('updateSurveyById', 'surveyRestDTO', surveyRestDTO)
            const localVarPath = `/surveys/{surveyId}`
                .replace(`{${"surveyId"}}`, encodeURIComponent(String(surveyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(surveyRestDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary uploads image files for an app/project
         * @param {any} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadImageTemporary: async (file?: any, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/surveys/upload-image-temporary`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DefaultApi - functional programming interface
 * @export
 */
export const DefaultApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DefaultApiAxiosParamCreator(configuration)
    return {
        /**
         * an admin can accept a survey proposed by a developer
         * @summary Accept proposed survey
         * @param {string} surveyId The UUID of the survey
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async acceptSurvey(surveyId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.acceptSurvey(surveyId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Creates a new Survey
         * @summary Create new Survey
         * @param {SurveyRestDTO} survey Survey object that shall be created
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addSurvey(survey: SurveyRestDTO, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SurveyRestDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addSurvey(survey, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} imageId The ID of the image
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteImage(imageId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteImage(imageId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Deletes the Survey with the given UUID
         * @param {string} surveyId The UUID of the survey
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteSurveyById(surveyId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteSurveyById(surveyId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} imageId The ID of the image
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteTemporaryImage(imageId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteTemporaryImage(imageId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} imageId The ID of the image
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async downloadImage(imageId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.downloadImage(imageId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} imageId The ID of the image
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async downloadTemporaryImage(imageId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.downloadTemporaryImage(imageId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get all surveys
         * @summary Get all surveys
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllSurveys(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SurveyRestDTO>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllSurveys(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Returns the published surveys that can be filled in
         * @param {number} [page] The page number to return
         * @param {number} [pageSize] The number of items to return in one page
         * @param {string} [q] The string used for searching the list of apps
         * @param {'name' | 'start-date' | 'end-date'} [sortBy] The property to sort the results by
         * @param {'asc' | 'desc'} [order] Determines ascending or descending sorting
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPublishedSurveys(page?: number, pageSize?: number, q?: string, sortBy?: 'name' | 'start-date' | 'end-date', order?: 'asc' | 'desc', options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedSurveyOverviewResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPublishedSurveys(page, pageSize, q, sortBy, order, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Returns the survey with the given UUID
         * @param {string} surveyId The UUID of the survey
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSurveyById(surveyId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SurveyRestDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSurveyById(surveyId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Returns the survey with the given UUID
         * @param {string} surveySlug The slug of the survey
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSurveyBySlug(surveySlug: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SurveyRestDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSurveyBySlug(surveySlug, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Returns the evaluation results for a survey
         * @param {string} surveyId The UUID of the survey
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSurveyEvaluationById(surveyId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SurveyEvaluationResult>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSurveyEvaluationById(surveyId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Returns the surveys of the current logged in developer or all surveys if the current user is an admin
         * @param {number} [page] The page number to return
         * @param {number} [pageSize] The number of items to return in one page
         * @param {string} [q] The string used for searching the list of apps
         * @param {Array<SurveyStatus>} [filterBy] The App/Project type to filter the results by
         * @param {'name' | 'start-date' | 'end-date'} [sortBy] The property to sort the results by
         * @param {'asc' | 'desc'} [order] Determines ascending or descending sorting
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSurveyOverviewsForDeveloper(page?: number, pageSize?: number, q?: string, filterBy?: Array<SurveyStatus>, sortBy?: 'name' | 'start-date' | 'end-date', order?: 'asc' | 'desc', options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedSurveyOverviewResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSurveyOverviewsForDeveloper(page, pageSize, q, filterBy, sortBy, order, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * gets all surveys who has the given project slug name assigned
         * @summary gets all surveys who has the given project slug name assigned
         * @param {string} mobileAppKey the mobileAppKey from a project which the survey is assigned to
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSurveysForMobileAppsByMobileAppKey(mobileAppKey: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SurveyRestDTO>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSurveysForMobileAppsByMobileAppKey(mobileAppKey, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Check if current user has already submitted answers for a survey
         * @summary Check if current user has already submitted answers for a survey
         * @param {number} surveyId The UUID of the survey
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async hasUserSubmittedSurvey(surveyId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse200>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.hasUserSubmittedSurvey(surveyId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Increases the impression count of the Survey with the given UUID by one
         * @param {string} surveyId The UUID of the survey
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async increaseImpressionCount(surveyId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.increaseImpressionCount(surveyId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * an admin can reject a survey proposed by a developer
         * @summary Reject proposed survey
         * @param {RejectionReason} rejectionReason Rejection reason
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async rejectSurvey(rejectionReason: RejectionReason, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.rejectSurvey(rejectionReason, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Adds a new set of answers for a survey
         * @summary Submit survey answers
         * @param {string} surveyId The UUID of the survey
         * @param {SurveyResultRestDTO} surveyResultRestDTO Answered Survey
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async submitSurvey(surveyId: string, surveyResultRestDTO: SurveyResultRestDTO, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.submitSurvey(surveyId, surveyResultRestDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Adds a new set of answers for a survey
         * @summary Submit survey answers from a mobile app
         * @param {string} surveyId The UUID of the survey
         * @param {SurveyResultRestDTO} surveyResultRestDTO Answered Survey
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async submitSurveyFromMobileApp(surveyId: string, surveyResultRestDTO: SurveyResultRestDTO, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.submitSurveyFromMobileApp(surveyId, surveyResultRestDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Updates the Survey with the given UUID
         * @param {string} surveyId The UUID of the survey
         * @param {SurveyRestDTO} surveyRestDTO The new version of the survey
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateSurveyById(surveyId: string, surveyRestDTO: SurveyRestDTO, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SurveyRestDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateSurveyById(surveyId, surveyRestDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary uploads image files for an app/project
         * @param {any} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uploadImageTemporary(file?: any, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FileUpload>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.uploadImageTemporary(file, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DefaultApi - factory interface
 * @export
 */
export const DefaultApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DefaultApiFp(configuration)
    return {
        /**
         * an admin can accept a survey proposed by a developer
         * @summary Accept proposed survey
         * @param {string} surveyId The UUID of the survey
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        acceptSurvey(surveyId: string, options?: any): AxiosPromise<void> {
            return localVarFp.acceptSurvey(surveyId, options).then((request) => request(axios, basePath));
        },
        /**
         * Creates a new Survey
         * @summary Create new Survey
         * @param {SurveyRestDTO} survey Survey object that shall be created
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addSurvey(survey: SurveyRestDTO, options?: any): AxiosPromise<SurveyRestDTO> {
            return localVarFp.addSurvey(survey, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} imageId The ID of the image
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteImage(imageId: number, options?: any): AxiosPromise<void> {
            return localVarFp.deleteImage(imageId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Deletes the Survey with the given UUID
         * @param {string} surveyId The UUID of the survey
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSurveyById(surveyId: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteSurveyById(surveyId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} imageId The ID of the image
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTemporaryImage(imageId: number, options?: any): AxiosPromise<void> {
            return localVarFp.deleteTemporaryImage(imageId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} imageId The ID of the image
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadImage(imageId: number, options?: any): AxiosPromise<any> {
            return localVarFp.downloadImage(imageId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} imageId The ID of the image
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadTemporaryImage(imageId: number, options?: any): AxiosPromise<any> {
            return localVarFp.downloadTemporaryImage(imageId, options).then((request) => request(axios, basePath));
        },
        /**
         * Get all surveys
         * @summary Get all surveys
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllSurveys(options?: any): AxiosPromise<Array<SurveyRestDTO>> {
            return localVarFp.getAllSurveys(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns the published surveys that can be filled in
         * @param {number} [page] The page number to return
         * @param {number} [pageSize] The number of items to return in one page
         * @param {string} [q] The string used for searching the list of apps
         * @param {'name' | 'start-date' | 'end-date'} [sortBy] The property to sort the results by
         * @param {'asc' | 'desc'} [order] Determines ascending or descending sorting
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPublishedSurveys(page?: number, pageSize?: number, q?: string, sortBy?: 'name' | 'start-date' | 'end-date', order?: 'asc' | 'desc', options?: any): AxiosPromise<PaginatedSurveyOverviewResponse> {
            return localVarFp.getPublishedSurveys(page, pageSize, q, sortBy, order, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns the survey with the given UUID
         * @param {string} surveyId The UUID of the survey
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSurveyById(surveyId: string, options?: any): AxiosPromise<SurveyRestDTO> {
            return localVarFp.getSurveyById(surveyId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns the survey with the given UUID
         * @param {string} surveySlug The slug of the survey
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSurveyBySlug(surveySlug: string, options?: any): AxiosPromise<SurveyRestDTO> {
            return localVarFp.getSurveyBySlug(surveySlug, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns the evaluation results for a survey
         * @param {string} surveyId The UUID of the survey
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSurveyEvaluationById(surveyId: string, options?: any): AxiosPromise<Array<SurveyEvaluationResult>> {
            return localVarFp.getSurveyEvaluationById(surveyId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns the surveys of the current logged in developer or all surveys if the current user is an admin
         * @param {number} [page] The page number to return
         * @param {number} [pageSize] The number of items to return in one page
         * @param {string} [q] The string used for searching the list of apps
         * @param {Array<SurveyStatus>} [filterBy] The App/Project type to filter the results by
         * @param {'name' | 'start-date' | 'end-date'} [sortBy] The property to sort the results by
         * @param {'asc' | 'desc'} [order] Determines ascending or descending sorting
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSurveyOverviewsForDeveloper(page?: number, pageSize?: number, q?: string, filterBy?: Array<SurveyStatus>, sortBy?: 'name' | 'start-date' | 'end-date', order?: 'asc' | 'desc', options?: any): AxiosPromise<PaginatedSurveyOverviewResponse> {
            return localVarFp.getSurveyOverviewsForDeveloper(page, pageSize, q, filterBy, sortBy, order, options).then((request) => request(axios, basePath));
        },
        /**
         * gets all surveys who has the given project slug name assigned
         * @summary gets all surveys who has the given project slug name assigned
         * @param {string} mobileAppKey the mobileAppKey from a project which the survey is assigned to
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSurveysForMobileAppsByMobileAppKey(mobileAppKey: string, options?: any): AxiosPromise<Array<SurveyRestDTO>> {
            return localVarFp.getSurveysForMobileAppsByMobileAppKey(mobileAppKey, options).then((request) => request(axios, basePath));
        },
        /**
         * Check if current user has already submitted answers for a survey
         * @summary Check if current user has already submitted answers for a survey
         * @param {number} surveyId The UUID of the survey
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        hasUserSubmittedSurvey(surveyId: number, options?: any): AxiosPromise<InlineResponse200> {
            return localVarFp.hasUserSubmittedSurvey(surveyId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Increases the impression count of the Survey with the given UUID by one
         * @param {string} surveyId The UUID of the survey
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        increaseImpressionCount(surveyId: string, options?: any): AxiosPromise<void> {
            return localVarFp.increaseImpressionCount(surveyId, options).then((request) => request(axios, basePath));
        },
        /**
         * an admin can reject a survey proposed by a developer
         * @summary Reject proposed survey
         * @param {RejectionReason} rejectionReason Rejection reason
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rejectSurvey(rejectionReason: RejectionReason, options?: any): AxiosPromise<void> {
            return localVarFp.rejectSurvey(rejectionReason, options).then((request) => request(axios, basePath));
        },
        /**
         * Adds a new set of answers for a survey
         * @summary Submit survey answers
         * @param {string} surveyId The UUID of the survey
         * @param {SurveyResultRestDTO} surveyResultRestDTO Answered Survey
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        submitSurvey(surveyId: string, surveyResultRestDTO: SurveyResultRestDTO, options?: any): AxiosPromise<void> {
            return localVarFp.submitSurvey(surveyId, surveyResultRestDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * Adds a new set of answers for a survey
         * @summary Submit survey answers from a mobile app
         * @param {string} surveyId The UUID of the survey
         * @param {SurveyResultRestDTO} surveyResultRestDTO Answered Survey
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        submitSurveyFromMobileApp(surveyId: string, surveyResultRestDTO: SurveyResultRestDTO, options?: any): AxiosPromise<void> {
            return localVarFp.submitSurveyFromMobileApp(surveyId, surveyResultRestDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Updates the Survey with the given UUID
         * @param {string} surveyId The UUID of the survey
         * @param {SurveyRestDTO} surveyRestDTO The new version of the survey
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSurveyById(surveyId: string, surveyRestDTO: SurveyRestDTO, options?: any): AxiosPromise<SurveyRestDTO> {
            return localVarFp.updateSurveyById(surveyId, surveyRestDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary uploads image files for an app/project
         * @param {any} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadImageTemporary(file?: any, options?: any): AxiosPromise<FileUpload> {
            return localVarFp.uploadImageTemporary(file, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DefaultApi - object-oriented interface
 * @export
 * @class DefaultApi
 * @extends {BaseAPI}
 */
export class DefaultApi extends BaseAPI {
    /**
     * an admin can accept a survey proposed by a developer
     * @summary Accept proposed survey
     * @param {string} surveyId The UUID of the survey
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public acceptSurvey(surveyId: string, options?: any) {
        return DefaultApiFp(this.configuration).acceptSurvey(surveyId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Creates a new Survey
     * @summary Create new Survey
     * @param {SurveyRestDTO} survey Survey object that shall be created
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public addSurvey(survey: SurveyRestDTO, options?: any) {
        return DefaultApiFp(this.configuration).addSurvey(survey, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} imageId The ID of the image
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public deleteImage(imageId: number, options?: any) {
        return DefaultApiFp(this.configuration).deleteImage(imageId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Deletes the Survey with the given UUID
     * @param {string} surveyId The UUID of the survey
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public deleteSurveyById(surveyId: string, options?: any) {
        return DefaultApiFp(this.configuration).deleteSurveyById(surveyId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} imageId The ID of the image
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public deleteTemporaryImage(imageId: number, options?: any) {
        return DefaultApiFp(this.configuration).deleteTemporaryImage(imageId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} imageId The ID of the image
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public downloadImage(imageId: number, options?: any) {
        return DefaultApiFp(this.configuration).downloadImage(imageId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} imageId The ID of the image
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public downloadTemporaryImage(imageId: number, options?: any) {
        return DefaultApiFp(this.configuration).downloadTemporaryImage(imageId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get all surveys
     * @summary Get all surveys
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getAllSurveys(options?: any) {
        return DefaultApiFp(this.configuration).getAllSurveys(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns the published surveys that can be filled in
     * @param {number} [page] The page number to return
     * @param {number} [pageSize] The number of items to return in one page
     * @param {string} [q] The string used for searching the list of apps
     * @param {'name' | 'start-date' | 'end-date'} [sortBy] The property to sort the results by
     * @param {'asc' | 'desc'} [order] Determines ascending or descending sorting
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getPublishedSurveys(page?: number, pageSize?: number, q?: string, sortBy?: 'name' | 'start-date' | 'end-date', order?: 'asc' | 'desc', options?: any) {
        return DefaultApiFp(this.configuration).getPublishedSurveys(page, pageSize, q, sortBy, order, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns the survey with the given UUID
     * @param {string} surveyId The UUID of the survey
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getSurveyById(surveyId: string, options?: any) {
        return DefaultApiFp(this.configuration).getSurveyById(surveyId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns the survey with the given UUID
     * @param {string} surveySlug The slug of the survey
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getSurveyBySlug(surveySlug: string, options?: any) {
        return DefaultApiFp(this.configuration).getSurveyBySlug(surveySlug, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns the evaluation results for a survey
     * @param {string} surveyId The UUID of the survey
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getSurveyEvaluationById(surveyId: string, options?: any) {
        return DefaultApiFp(this.configuration).getSurveyEvaluationById(surveyId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns the surveys of the current logged in developer or all surveys if the current user is an admin
     * @param {number} [page] The page number to return
     * @param {number} [pageSize] The number of items to return in one page
     * @param {string} [q] The string used for searching the list of apps
     * @param {Array<SurveyStatus>} [filterBy] The App/Project type to filter the results by
     * @param {'name' | 'start-date' | 'end-date'} [sortBy] The property to sort the results by
     * @param {'asc' | 'desc'} [order] Determines ascending or descending sorting
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getSurveyOverviewsForDeveloper(page?: number, pageSize?: number, q?: string, filterBy?: Array<SurveyStatus>, sortBy?: 'name' | 'start-date' | 'end-date', order?: 'asc' | 'desc', options?: any) {
        return DefaultApiFp(this.configuration).getSurveyOverviewsForDeveloper(page, pageSize, q, filterBy, sortBy, order, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * gets all surveys who has the given project slug name assigned
     * @summary gets all surveys who has the given project slug name assigned
     * @param {string} mobileAppKey the mobileAppKey from a project which the survey is assigned to
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getSurveysForMobileAppsByMobileAppKey(mobileAppKey: string, options?: any) {
        return DefaultApiFp(this.configuration).getSurveysForMobileAppsByMobileAppKey(mobileAppKey, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Check if current user has already submitted answers for a survey
     * @summary Check if current user has already submitted answers for a survey
     * @param {number} surveyId The UUID of the survey
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public hasUserSubmittedSurvey(surveyId: number, options?: any) {
        return DefaultApiFp(this.configuration).hasUserSubmittedSurvey(surveyId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Increases the impression count of the Survey with the given UUID by one
     * @param {string} surveyId The UUID of the survey
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public increaseImpressionCount(surveyId: string, options?: any) {
        return DefaultApiFp(this.configuration).increaseImpressionCount(surveyId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * an admin can reject a survey proposed by a developer
     * @summary Reject proposed survey
     * @param {RejectionReason} rejectionReason Rejection reason
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public rejectSurvey(rejectionReason: RejectionReason, options?: any) {
        return DefaultApiFp(this.configuration).rejectSurvey(rejectionReason, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Adds a new set of answers for a survey
     * @summary Submit survey answers
     * @param {string} surveyId The UUID of the survey
     * @param {SurveyResultRestDTO} surveyResultRestDTO Answered Survey
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public submitSurvey(surveyId: string, surveyResultRestDTO: SurveyResultRestDTO, options?: any) {
        return DefaultApiFp(this.configuration).submitSurvey(surveyId, surveyResultRestDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Adds a new set of answers for a survey
     * @summary Submit survey answers from a mobile app
     * @param {string} surveyId The UUID of the survey
     * @param {SurveyResultRestDTO} surveyResultRestDTO Answered Survey
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public submitSurveyFromMobileApp(surveyId: string, surveyResultRestDTO: SurveyResultRestDTO, options?: any) {
        return DefaultApiFp(this.configuration).submitSurveyFromMobileApp(surveyId, surveyResultRestDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Updates the Survey with the given UUID
     * @param {string} surveyId The UUID of the survey
     * @param {SurveyRestDTO} surveyRestDTO The new version of the survey
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public updateSurveyById(surveyId: string, surveyRestDTO: SurveyRestDTO, options?: any) {
        return DefaultApiFp(this.configuration).updateSurveyById(surveyId, surveyRestDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary uploads image files for an app/project
     * @param {any} [file] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public uploadImageTemporary(file?: any, options?: any) {
        return DefaultApiFp(this.configuration).uploadImageTemporary(file, options).then((request) => request(this.axios, this.basePath));
    }
}


