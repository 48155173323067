











import { Component, Vue } from 'vue-property-decorator';
import MessageBox from '@/components/partials/MessageBox.vue';
import MessageBoxType from '@/assets/messageBoxTypes';

@Component({ components: { MessageBox } })
export default class UnexpectedErrorMessage extends Vue {
    private MessageBoxType = MessageBoxType;
}
