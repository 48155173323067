































import {
  Component, Emit, Prop, VModel, Vue,
} from 'vue-property-decorator';

@Component
export default class ConfirmDialog extends Vue {

  @VModel({ type: Boolean, default: false })
  private show!: boolean;

  @Prop({ required: true })
  private readonly title!: string;

  @Prop({ default: false })
  private readonly showCancelButton!: boolean;

  @Emit()
  private confirm() {
    // emits event
  }

  @Emit()
  private cancel() {
    // emits event
  }

}
