

























import { Component, Prop, Vue } from 'vue-property-decorator';
import MessageBoxType from '@/assets/messageBoxTypes';

@Component({})
export default class MessageBox extends Vue {

    @Prop({ })
    private variant!: MessageBoxType | undefined;

    @Prop({ required: true })
    private messages!: string[];

}
