














































































































import { Component, Ref, Vue } from 'vue-property-decorator';
import L from 'leaflet';
import Header from '@/assets/headers';
import TextHeader from '@/components/partials/TextHeader.vue';
import NewsCard from '@/components/news/NewsCard.vue';
import { FileUpload, NewsPageOverview } from '@/apis/newsapi';
import CustomSelect, { Option } from '@/components/partials/CustomSelect.vue';
import PulsatingButton from '@/components/partials/PulsatingButton.vue';
import { ProjectOverviewRestDto } from '@/apis/projectapi';
import ButtonType from '@/assets/buttonTypes';
import Services from '@/assets/services/Services';
import Sort from '@/assets/types/sorting';
import { Filter } from '@/assets/types/types';
import FilterButtonBar from '@/components/partials/FilterButtonBar.vue';
import RoundedButtonOutlined from '@/components/partials/RoundedButtonOutlined.vue';
import SearchBar from '@/components/partials/SearchBar.vue';
import ProjectGrid from '@/components/projects/ProjectGrid.vue';
import ConfirmDialog from '@/components/ConfirmDialog.vue';
import DateService from '@/assets/services/DateService';
import UnexpectedErrorMessage from '@/components/partials/UnexpectedErrorMessage.vue';
import LoadingAnimation from '@/components/partials/LoadingAnimation.vue';

const GeoJson = require('@mapbox/leaflet-pip');

@Component({
  components: {
    TextHeader,
    NewsCard,
    CustomSelect,
    PulsatingButton,
    FilterButtonBar,
    SearchBar,
    RoundedButtonOutlined,
    ProjectGrid,
    ConfirmDialog,
    UnexpectedErrorMessage,
    LoadingAnimation,
  },
  metaInfo: {
    title: 'Entdecken │ innovaMo',
    meta: [
      {
        vmid: 'description',
        name: 'description',
        content:
          'Entdecken Sie die neuesten Projekte und Applikationen und erfahren Sie, welche Mobilitätslösung für Sie die Beste ist!',
      },
      {
        vmid: 'preview-image',
        property: 'og:image',
        content: 'https://www.innovamo.de/img/seo/highway.png',
      },
      {
        vmid: 'preview-image-twitter',
        property: 'twitter:image',
        content: 'https://www.innovamo.de/img/seo/highway.png',
      },
    ],
  },
})
export default class Home extends Vue {
  private initialLoading: boolean = true;
  private showUnexpectedErrorProjects: boolean = false;
  private imageName = 'industry-mobility-automotive-public-transportation-aviation.png';
  private header = 'innovaMo – der zentrale Marktplatz für digitale Mobilitätskonzepte';
  private typedHeader = '';
  private typing = false;
  private Header = Header;
  private ButtonType = ButtonType;
  private SortBy = Sort;
  private developerIsInExaminationDialogDialogTitle = 'Ihr Konto wird momentan überprüft';
  private developerIsInExaminationDialogDialogMessage = 'Ihr Konto wird derzeit noch von unseren Administratoren überprüft. Sobald die Überprüfung abgeschlossen wurde, wird Ihr Konto freigeschaltet und Sie können den Mobilitätsmarktplatz in vollem Umfang benutzen.'
  private showAccessUserPositionDialog: boolean = false;
  private isAccessToUserPositionAllowed: boolean = false;
  private loadingStatusText: string = '';

  private pageNumber = 0;
  private totalPages = 0;
  private filterValue: Filter = undefined;
  private searchProjectsNearUserPositionIsActive = false;
  private sortValue: Option = {
    name: 'Erscheinungsdatum (ab)',
    value: Sort.DATE_DESCENDING,
  };

  private sortOptions: Option[] = [
    {
      name: 'Erscheinungsdatum (ab)',
      value: Sort.DATE_DESCENDING,
    },
    {
      name: 'Erscheinungsdatum (auf)',
      value: Sort.DATE_ASCENDING,
    },
    {
      name: 'Name (ab)',
      value: Sort.NAME_DESCENDING,
    },
    {
      name: 'Name (auf)',
      value: Sort.NAME_ASCENDING,
    },
  ];

  private searchTerm: string = '';
  private userLatitude: number = 0;
  private userLongitude: number = 0;
  private radiusInWhichTheProjectsMustBe: number = 100;
  private userFederalState: string = '';
  private userCounty: string = '';

  @Ref('content')
  private contentDiv!: HTMLElement;

  private projects: ProjectOverviewRestDto[] = [];
  private news: NewsPageOverview[] = [];

  private dateTimeFormatter = DateService.dateTimeFormatterWithoutTime;

  private async mounted() {
    this.typing = true;
    this.typeHeader();

    this.projects = await this.searchProjects(this.pageNumber, this.searchTerm, this.filterValue, this.sortValue, this.userLatitude, this.userLongitude, this.radiusInWhichTheProjectsMustBe, this.userFederalState, this.userCounty);

    try {
      const newsResponse = await Services.news.getAllPublishedNews(0, 3, '', 'date', 'desc');
      this.news = newsResponse.data.newsPages as NewsPageOverview[];
    } catch (e) {
      console.error(e);
    }
    this.initialLoading = false;
  }

  private getNewsImageLink(image: FileUpload) {
    if (!image || !image.uuid) return '';
    return `${process.env.VUE_APP_NEWS_SERVICE_IMAGE_URL}${image.uuid}`;
  }

  private formatAndGetNewsPublishDate(dateString: string): string {
    if (dateString) {
      return this.dateTimeFormatter.format(new Date(Date.parse(dateString)));
    }
    return this.dateTimeFormatter.format(new Date());
  }

  private get showMoreButtonEnabled(): boolean {
    return this.pageNumber < this.totalPages - 1;
  }

  private get showDeveloperIsInExaminationDialog(): boolean {
    return this.$store.state.showDeveloperIsInExaminationDialog;
  }

  private confirmDeveloperIsInExaminationDialog() {
    this.$store.commit('setShowDeveloperIsInExaminationDialog', false);
  }

  private typeHeader() {
    if (this.typedHeader.length < this.header.length) {
      this.typedHeader = this.header.substring(0, this.typedHeader.length + 1);
      const delay = Math.random() * 50 + 50;
      setTimeout(this.typeHeader, delay);
    } else {
      this.typing = false;
      this.typedHeader = this.header;
    }
  }

  private scrollToContent() {
    this.contentDiv.scrollIntoView({ block: 'start', behavior: 'smooth' });
  }

  private async searchButtonClicked(searchTerm: string) {
    this.pageNumber = 0;
    this.searchTerm = searchTerm;
    this.projects = await this.searchProjects(this.pageNumber, this.searchTerm, this.filterValue, this.sortValue, this.userLatitude, this.userLongitude, this.radiusInWhichTheProjectsMustBe, this.userFederalState, this.userCounty);

  }

  private filterValueChanged(filterValue: Filter) {
    this.filterValue = filterValue;
    this.searchButtonClicked(this.searchTerm);
  }

  private sortValueChanged() {
    this.searchButtonClicked(this.searchTerm);
  }

  private async searchProjects(pageNumber: number = 0, searchTerm: string, filterBy: Filter, sortBy: Option, userLatitude: number, userLongitude: number, radiusInWhichTheProjectsMustBe: number, userFederalState: string, userCounty: string): Promise<ProjectOverviewRestDto[]> {
    try {
      this.initialLoading = true;
      let response;
      if (this.searchProjectsNearUserPositionIsActive) {
        response = await Services.projects.getPublishedProjectsNearUserPosition(pageNumber, 6, searchTerm, filterBy, sortBy.value.value, sortBy.value.order, userLatitude, userLongitude, radiusInWhichTheProjectsMustBe, userFederalState, userCounty);
      } else {
        response = await Services.projects.getPublishedProjects(pageNumber, 6, searchTerm, filterBy, sortBy.value.value, sortBy.value.order);
      }
      this.pageNumber = response.data.currentPage as number;
      this.totalPages = response.data.totalPages as number;

      if (searchTerm.trim()) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        if (this.$matomo) {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          this.$matomo.trackSiteSearch(searchTerm, 'published projects', response.data.totalItemsFound);
        }
      }

      return response.data.projects as ProjectOverviewRestDto[];
    } catch (e) {
      this.showUnexpectedErrorProjects = true;
      return [];
    } finally {
      this.initialLoading = false;
    }
  }

  private async showMore() {
    this.pageNumber += 1;
    const additionalProjects = await this.searchProjects(this.pageNumber, this.searchTerm, this.filterValue, this.sortValue, this.userLatitude, this.userLongitude, this.radiusInWhichTheProjectsMustBe, this.userFederalState, this.userCounty);

    this.projects.push(...additionalProjects);
  }

  private async openDialogForAccessingUserPosition() {
    // This not work in Safari because there is no navigator.permissions object.
    if ('geolocation' in navigator && navigator.permissions) {
      const isAllowed = await navigator.permissions.query({ name: 'geolocation' });
      if (isAllowed.state === 'granted') {
        this.isAccessToUserPositionAllowed = true;
        await this.searchNearUserPosition();
      } else if (isAllowed.state === 'prompt') {
        this.isAccessToUserPositionAllowed = false;
        this.showAccessUserPositionDialog = true;
      } else if (isAllowed.state === 'denied') {
        this.isAccessToUserPositionAllowed = false;
        this.showAccessUserPositionDialog = true;
      }
      navigator.permissions.query({ name: 'geolocation' }).then((state) => {
        state.addEventListener('change', (event) => {
          if ((event.target as PermissionStatus).state === 'granted') {
            this.searchNearUserPosition();
          } else if ((event.target as PermissionStatus).state === 'prompt') {
            this.isAccessToUserPositionAllowed = false;
          } else {
            this.isAccessToUserPositionAllowed = false;
          }
        });
      });
    }
    if ('geolocation' in navigator) {
      if (navigator.vendor === 'Apple Computer, Inc.') {
        navigator.geolocation.getCurrentPosition(async () => {
          this.isAccessToUserPositionAllowed = true;
          await this.searchNearUserPosition();
        }, (positionError) => {
          if (positionError.PERMISSION_DENIED) {
            this.isAccessToUserPositionAllowed = false;
            this.showAccessUserPositionDialog = true;
          }
        });
      } else {
        navigator.geolocation.getCurrentPosition(async () => {
          // only for asking
        });
      }
    }
  }

  private async searchNearUserPosition() {
    this.initialLoading = true;
    if ('geolocation' in navigator) {
      this.loadingStatusText = 'Ihre Position wird ermittelt...';
      navigator.geolocation.getCurrentPosition(async (position) => {
        const userFederalStateAndCounty = await this.getUserFederalStateAndCounty(position.coords.latitude, position.coords.longitude);
        this.loadingStatusText = 'Projekte werden gesucht...';
        this.searchProjectsNearUserPositionIsActive = true;
        this.userLatitude = position.coords.latitude;
        this.userLongitude = position.coords.longitude;
        [this.userFederalState, this.userCounty] = userFederalStateAndCounty;
        this.pageNumber = 0;
        this.projects = await this.searchProjects(this.pageNumber, this.searchTerm, this.filterValue, this.sortValue, this.userLatitude, this.userLongitude, this.radiusInWhichTheProjectsMustBe, this.userFederalState, this.userCounty);
        this.loadingStatusText = '';
        this.initialLoading = false;
      }, (positionError) => {
        if (positionError.POSITION_UNAVAILABLE || positionError.TIMEOUT) {
          this.showUnexpectedErrorProjects = true;
        }
      });
    } else {
      this.initialLoading = false;
    }
  }

  private async getUserFederalStateAndCounty(userLatitude:number, userLongitude:number): Promise<string[]> {
    try {
      const a: any = await Promise.allSettled([
        Promise.resolve(Services.projects.downloadGeoData('federalStates')),
        Promise.resolve(Services.projects.downloadGeoData('counties')),
      ]);
      const federalStatesData = a[0].value.data;
      const countyData = a[1].value.data;
      let userFederalState = '';
      let userCounty = '';

      federalStatesData.features.forEach((gj: any) => {
        const results = GeoJson.pointInLayer([userLongitude, userLatitude], L.geoJSON(gj), false);
        if (results && results.length > 0) {
          userFederalState = results[0].feature.properties.name;
        }
      });
      const gjLayer = L.geoJSON(countyData);
      const results = GeoJson.pointInLayer([userLongitude, userLatitude], gjLayer, false);
      if (results && results.length > 0) {
        userCounty = results[0].feature.properties.NAME_3;
      }
      return [userFederalState, userCounty];

    } catch (error) {
      return [];
    }

  }

  private confirmSearchNearUserPositionDialog() {
    this.showAccessUserPositionDialog = false;
    if (this.isAccessToUserPositionAllowed) {
      this.searchNearUserPosition();
    }
  }

  private async disableSearchNearUserPosition() {
    this.searchProjectsNearUserPositionIsActive = false;
    this.pageNumber = 0;
    this.projects = await this.searchProjects(this.pageNumber, this.searchTerm, this.filterValue, this.sortValue, this.userLatitude, this.userLongitude, this.radiusInWhichTheProjectsMustBe, this.userFederalState, this.userCounty);
  }

}
