
















import { Component, Prop, Vue } from 'vue-property-decorator';
import { ProjectOverviewRestDto } from '@/apis/projectapi';
import ProjectCard from '@/components/projects/ProjectCard.vue';

@Component({
  components: { ProjectCard },
})
export default class ProjectGrid extends Vue {

  @Prop({ required: true })
  private readonly projects!: ProjectOverviewRestDto[];

  private imageDownloadBasePath: string = process.env.VUE_APP_PROJECT_SERVICE_IMAGE_URL;
}
