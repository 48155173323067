import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    isInitialized: false,
    role: '',
    isLoggedIn: false,
    userInitials: '',
    userState: '',
    userId: '',
    showDeveloperIsInExaminationDialog: false,
  },
  mutations: {
    initialize(state, isInitialized) {
      state.isInitialized = isInitialized;
    },
    setRole(state, role) {
      state.role = role;
    },
    setIsLoggedIn(state, isLoggedIn) {
      state.isLoggedIn = isLoggedIn;
    },
    setUserInitials(state, userInitials) {
      state.userInitials = userInitials;
    },
    setUserState(state, userState) {
      state.userState = userState;
    },
    setUserId(state, userId) {
      state.userId = userId;
    },
    setShowDeveloperIsInExaminationDialog(state, showDeveloperIsInExaminationDialog) {
      state.showDeveloperIsInExaminationDialog = showDeveloperIsInExaminationDialog;
    },
  },
  actions: {
  },
  modules: {
  },
});
