








import { Component, Prop, Vue } from 'vue-property-decorator';
import { ContentPageRestDto } from '@/apis/contentpagesapi';
import Services from '@/assets/services/Services';
import Page from '@/components/partials/Page.vue';

@Component({
  components: { Page },
  metaInfo: {
    title: 'innovaMo - digitaler Mobilitäsmarktplatz',
    meta: [
      {
        vmid: 'description',
        name: 'description',
        content: '',
      },
    ],
  },
})
export default class ContentPage extends Vue {

  @Prop({ required: true })
  private pageIdentifier!: string;

  private initialLoading: boolean = true;
  private showUnexpectedErrorMessage: boolean = false;

  private pageContent: ContentPageRestDto = {
    name: '',
    updatedBy: '',
    updatedAt: '',
    markdownContent: '',
  };

  private async mounted() {
    try {
      const response = await Services.contentPages.getContentPageByName(this.pageIdentifier);
      this.pageContent = response.data;
    } catch (e) {
      this.showUnexpectedErrorMessage = true;
    } finally {
      this.initialLoading = false;
    }
  }
}
