






import {
  Component, Emit, Vue,
} from 'vue-property-decorator';

@Component
export default class PulsatingButton extends Vue {

  @Emit()
  private click() {
    // empty, emits event
  }
}
