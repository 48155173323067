






import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({})
export default class LinkWithArrow extends Vue {
  @Prop({ type: Boolean, default: false })
  private externalLink!: boolean;

  @Prop({ type: Boolean, default: false })
  private clickFunction!: boolean;

  @Prop({ default: '#' })
  private readonly href!: string | Record<string, unknown>;

  @Prop({ required: true })
  private readonly text!: string;

  @Prop({ required: true })
  private readonly color!: string;

  private get classes(): string {
    return `hover:underline text-${this.color}`;
  }

  private click() {
    this.$emit('click');
  }
}
