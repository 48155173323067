/* tslint:disable */
/* eslint-disable */
/**
 * Content-Pages Service
 * This is the API documentation of the innovaMo content-pages api
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface ContentPageRestDto
 */
export interface ContentPageRestDto {
    /**
     * 
     * @type {string}
     * @memberof ContentPageRestDto
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof ContentPageRestDto
     */
    markdownContent?: string;
    /**
     * 
     * @type {string}
     * @memberof ContentPageRestDto
     */
    updatedAt?: string;
    /**
     * 
     * @type {string}
     * @memberof ContentPageRestDto
     */
    updatedBy?: string;
}

/**
 * DefaultApi - axios parameter creator
 * @export
 */
export const DefaultApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllContentPages: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/content-pages`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} pageName The unique name of the page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getContentPageByName: async (pageName: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'pageName' is not null or undefined
            assertParamExists('getContentPageByName', 'pageName', pageName)
            const localVarPath = `/content-pages/{pageName}`
                .replace(`{${"pageName"}}`, encodeURIComponent(String(pageName)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} pageName The unique name of the page
         * @param {ContentPageRestDto} [contentPageRestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateContentPageByName: async (pageName: string, contentPageRestDto?: ContentPageRestDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'pageName' is not null or undefined
            assertParamExists('updateContentPageByName', 'pageName', pageName)
            const localVarPath = `/content-pages/{pageName}`
                .replace(`{${"pageName"}}`, encodeURIComponent(String(pageName)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(contentPageRestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DefaultApi - functional programming interface
 * @export
 */
export const DefaultApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DefaultApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllContentPages(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ContentPageRestDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllContentPages(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} pageName The unique name of the page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getContentPageByName(pageName: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ContentPageRestDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getContentPageByName(pageName, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} pageName The unique name of the page
         * @param {ContentPageRestDto} [contentPageRestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateContentPageByName(pageName: string, contentPageRestDto?: ContentPageRestDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ContentPageRestDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateContentPageByName(pageName, contentPageRestDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DefaultApi - factory interface
 * @export
 */
export const DefaultApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DefaultApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllContentPages(options?: any): AxiosPromise<Array<ContentPageRestDto>> {
            return localVarFp.getAllContentPages(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} pageName The unique name of the page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getContentPageByName(pageName: string, options?: any): AxiosPromise<ContentPageRestDto> {
            return localVarFp.getContentPageByName(pageName, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} pageName The unique name of the page
         * @param {ContentPageRestDto} [contentPageRestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateContentPageByName(pageName: string, contentPageRestDto?: ContentPageRestDto, options?: any): AxiosPromise<ContentPageRestDto> {
            return localVarFp.updateContentPageByName(pageName, contentPageRestDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DefaultApi - object-oriented interface
 * @export
 * @class DefaultApi
 * @extends {BaseAPI}
 */
export class DefaultApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getAllContentPages(options?: any) {
        return DefaultApiFp(this.configuration).getAllContentPages(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} pageName The unique name of the page
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getContentPageByName(pageName: string, options?: any) {
        return DefaultApiFp(this.configuration).getContentPageByName(pageName, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} pageName The unique name of the page
     * @param {ContentPageRestDto} [contentPageRestDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public updateContentPageByName(pageName: string, contentPageRestDto?: ContentPageRestDto, options?: any) {
        return DefaultApiFp(this.configuration).updateContentPageByName(pageName, contentPageRestDto, options).then((request) => request(this.axios, this.basePath));
    }
}


