import Vue from 'vue';
import VueRouter, { Route, RouteConfig } from 'vue-router';
import Authservice from '@/assets/services/Authservice';
import Role from '../assets/roles';
import Home from '../views/Home.vue';
import store from '@/store/store';
import Services from '@/assets/services/Services';
import { UserState } from '@/apis/userapi';

Vue.use(VueRouter);

export const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      title: 'Entdecken',
      navigationbar: true,
      auth: false,
      roles: [Role.ADMIN, Role.DEVELOPER, Role.NOROLE, Role.USER],
    },
  },
  {
    path: '/surveys',
    name: 'Surveys',
    meta: {
      title: 'Umfragen',
      navigationbar: true,
      roles: [Role.NOROLE, Role.USER, Role.ADMIN, Role.DEVELOPER],
    },
    component: () => import(/* webpackChunkName: "surveys" */ '../views/surveys/Surveys.vue'),
  },
  {
    path: '/surveys-developers',
    name: 'SurveysDevelopers',
    meta: {
      title: 'Umfragen',
      auth: true,
      roles: [Role.ADMIN, Role.DEVELOPER],
    },
    component: () => import(/* webpackChunkName: "surveys-developers" */ '../views/surveys/SurveyManagement.vue'),
  },
  {
    path: '/survey-editor',
    name: 'SurveyEditor',
    props: true,
    meta: {
      auth: true,
      roles: [Role.ADMIN, Role.DEVELOPER],
    },
    component: () => import(/* webpackChunkName: "surveys-developers" */ '../views/surveys/SurveyEditor.vue'),
  },
  {
    path: '/survey/:surveySlug',
    name: 'Survey',
    props: true,
    meta: {
      auth: false,
      roles: [Role.ADMIN, Role.DEVELOPER, Role.USER, Role.NOROLE],
    },
    component: () => import(/* webpackChunkName: "surveys" */ '../views/surveys/Survey.vue'),
  },
  {
    path: '/survey-evaluation',
    name: 'SurveyEvaluation',
    meta: {
      auth: true,
      roles: [Role.ADMIN, Role.DEVELOPER],
    },
    props: true,
    component: () => import(/* webpackChunkName: "survey-evaluation" */ '../views/surveys/SurveyEvaluation.vue'),
  },
  {
    path: '/about',
    name: 'About',
    meta: {
      title: 'Über uns',
      navigationbar: true,
      auth: false,
      roles: [Role.ADMIN, Role.DEVELOPER, Role.NOROLE, Role.USER],
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue'),
  },
  {
    path: '/contact',
    name: 'Contact',
    meta: {
      title: 'Kontakt',
      navigationbar: true,
      auth: false,
      roles: [Role.ADMIN, Role.DEVELOPER, Role.NOROLE, Role.USER],
    },
    component: () => import(/* webpackChunkName: "contact" */ '../views/Contact.vue'),
  },
  // {
  //  path: '/map',
  //  name: 'Map',
  //  meta: {
  //    title: 'Karte',
  //    navigationbar: true,
  //    auth: false,
  //    roles: [Role.ADMIN, Role.DEVELOPER, Role.NOROLE, Role.USER],
  //  },
  //  component: () => import(/* webpackChunkName: "map" */ '../views/Map.vue'),
  // },
  {
    path: '/imprint',
    name: 'Imprint',
    component: () => import(/* webpackChunkName: "imprint" */ '../views/contentPages/Imprint.vue'),
  },
  {
    path: '/privacy',
    name: 'PrivacyNotice',
    component: () => import(/* webpackChunkName: "privacy" */ '../views/contentPages/PrivacyNotice.vue'),
  },
  {
    path: '/login-with-innovamo',
    name: 'LoginWithInnovamo',
    component: () => import(/* webpackChunkName: "privacy" */ '../views/contentPages/LoginWithInnovamo.vue'),
  },
  {
    path: '/select-registration',
    name: 'SelectRole',
    meta: {
      auth: true,
    },
    component: () => import(/* webpackChunkName: "select-registration" */ '../views/PreRegistrationPage.vue'),
  },
  {
    path: '/project-details/:slug',
    name: 'ProjectDetails',
    meta: {
      auth: false,
      roles: [Role.ADMIN, Role.DEVELOPER, Role.NOROLE, Role.USER],
    },
    component: () => import(/* webpackChunkName: "project-details" */ '../views/projects/ProjectDetails.vue'),
  },
  {
    path: '/project-management',
    name: 'ProjectManagement',
    meta: {
      auth: true,
      roles: [Role.ADMIN, Role.DEVELOPER],
    },
    component: () => import(/* webpackChunkName: "project-management" */ '../views/projects/ProjectManagement.vue'),
  },
  {
    path: '/news-management',
    name: 'NewsManagement',
    meta: {
      auth: true,
      roles: [Role.ADMIN],
    },
    component: () => import(/* webpackChunkName: "project-management" */ '../views/news/NewsManagement.vue'),
  },
  {
    path: '/news-editor',
    name: 'NewsEditor',
    meta: {
      auth: true,
      roles: [Role.ADMIN],
    },
    component: () => import(/* webpackChunkName: "project-editing" */ '../views/news/NewsEditor.vue'),
    props: true,
  },
  {
    path: '/news/:slug',
    name: 'NewsArticle',
    meta: {
      auth: false,
      roles: [Role.ADMIN, Role.DEVELOPER, Role.USER, Role.NOROLE],
    },
    component: () => import(/* webpackChunkName: "project-editing" */ '../views/news/NewsArticle.vue'),
    props: true,
  },
  {
    path: '/personal-data',
    name: 'PersonalData',
    meta: {
      auth: true,
      roles: [Role.ADMIN, Role.DEVELOPER, Role.USER],
    },
    component: () => import(/* webpackChunkName: "personal-data" */ '../views/PersonalData.vue'),
  },
  {
    path: '/content-management',
    name: 'ContentManagement',
    meta: {
      auth: true,
      roles: [Role.ADMIN],
    },
    component: () => import(/* webpackChunkName: "personal-data" */ '../views/contentPages/ContentManagement.vue'),
  },
  {
    path: '/content-editor',
    name: 'ContentEditor',
    props: true,
    meta: {
      auth: true,
      roles: [Role.ADMIN],
    },
    component: () => import(/* webpackChunkName: "personal-data" */ '../views/contentPages/ContentEditor.vue'),
  },
  {
    path: '/project-editor',
    name: 'ProjectEditing',
    meta: {
      auth: true,
      roles: [Role.DEVELOPER, Role.ADMIN],
    },
    component: () => import(/* webpackChunkName: "project-editing" */ '../views/projects/ProjectEditing.vue'),
    props: true,
  },
  {
    path: '/user-management',
    name: 'UserManagement',
    meta: {
      auth: true,
      roles: [Role.ADMIN],
    },
    component: () => import(/* webpackChunkName: "user-management" */ '../views/userManagement/UserManagement.vue'),
  },
  {
    path: '/user-editing',
    name: 'UserEditing',
    meta: {
      auth: true,
      roles: [Role.ADMIN],
    },
    component: () => import(/* webpackChunkName: "user-editing" */ '../views/userManagement/UserEditing.vue'),
    props: true,
  },
  {
    path: '/myapps',
    name: 'MyApps',
    meta: {
      auth: true,
      roles: [Role.USER],
    },
    component: () => import(/* webpackChunkName: "my-apps" */ '../views/MyApps.vue'),
  },
  {
    path: '/developer-account-info',
    name: 'DeveloperAccounInfo',
    meta: {
      auth: true,
      roles: [Role.DEVELOPER],
    },
    component: () => import(/* webpackChunkName: "developer-account-info" */ '../views/DeveloperAccountInfo.vue'),
  },
  {
    path: '/keycloakClientTestForQM',
    name: 'KeycloakClientTestForQM',
    meta: {
      auth: false,
      roles: [Role.ADMIN, Role.DEVELOPER, Role.NOROLE, Role.USER],
    },
    component: () => import(/* webpackChunkName: "keycloak-client-test-for-qm" */ '../views/KeycloakClientTestForQM.vue'),
  },
  {
    path: '*',
    component: Home,
    beforeEnter: (to, from, next) => next('/'),
  },
];

const routesAllowedWhenUserHasNoRole = ['SelectRole', 'PrivacyNotice', 'Imprint', 'Contact'];
const routesAllowdWhenUserHasExaminationState = ['Home', 'PersonalData', 'PrivacyNotice', 'Imprint', 'Contact', 'ProjectDetails', 'Surveys', 'Survey', 'About', 'DeveloperAccounInfo'];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  scrollBehavior(to, from, savedPosition) {
    // on route change always scroll to top
    return { x: 0, y: 0 };
  },
});

const initStore = async () => {
  if (!store.state.isInitialized) {
    try {
      const isLoggedIn = await Authservice.isLoggedIn();
      store.commit('setIsLoggedIn', isLoggedIn);
      if (isLoggedIn) {
        const innovamoUserResponse = await Services.users.getCurrentUser();
        store.commit('setRole', innovamoUserResponse.data.role?.role);
        const userInitials = `${innovamoUserResponse.data.firstname?.substring(0, 1).toUpperCase()}${innovamoUserResponse.data.lastname?.substring(0, 1).toUpperCase()}`;
        store.commit('setUserInitials', userInitials);
        store.commit('setUserState', innovamoUserResponse.data.userState);
        store.commit('setUserId', innovamoUserResponse.data.userId);
      }
    } catch (error) {
      console.log(error);
    }
    store.commit('initialize', true);
  }
};

router.beforeEach(async (to: Route, from: Route, next) => {
  await initStore();

  if (to.meta && to.meta.auth && !store.state.isLoggedIn) {
    const redirectTo = to.fullPath;
    if (redirectTo !== '') {
      next(`/?redirect=${redirectTo}`);

      return;
    }
    next('/');
    return;
  }
  if (store.state.isLoggedIn) {
    try {
      if (!store.state.role && !routesAllowedWhenUserHasNoRole.includes(to.name as string)) {
        next({ path: '/select-registration' });
        return;
      }
      if (store.state.role === Role.DEVELOPER && store.state.userState === UserState.Examination && !routesAllowdWhenUserHasExaminationState.includes(to.name as string)) {
        store.commit('setShowDeveloperIsInExaminationDialog', true);
        next('/');
        return;
      }

      if (to.meta && to.meta.roles && !to.meta.roles.includes(store.state.role)) {
        const redirectTo = to.fullPath;
        if (redirectTo !== '') {
          next(`/?redirect=${redirectTo}`);
          return;
        }
        next('/');
        return;
      }
    } catch (error) {
      const redirectTo = to.fullPath;
      if (redirectTo !== '') {
        next(`/?redirect=${redirectTo}`);
        return;
      }
      next('/');
      return;
    }
  }
  next();
});

export default router;
