import Vue from 'vue';
import { Icon } from 'leaflet';
import VueApexCharts from 'vue-apexcharts';
import VueMarkdownEditor from '@kangc/v-md-editor';
import '@kangc/v-md-editor/lib/style/base-editor.css';
import vuepressTheme from '@kangc/v-md-editor/lib/theme/vuepress';
import '@kangc/v-md-editor/lib/theme/style/vuepress.css';
import Prism from 'prismjs';
import deDE from '@kangc/v-md-editor/lib/lang/de-DE';
import VueMatomo from 'vue-matomo';
import VCalendar from 'v-calendar';
import Meta from 'vue-meta';
import App from './App.vue';
import './registerServiceWorker';
import router from './router/router';
import store from './store/store';
import './assets/tailwind.css';
import './assets/icons';

Vue.use(VueApexCharts);
Vue.component('apexchart', VueApexCharts);
Vue.use(Meta);
Vue.use(VCalendar, {
  locale: 'de',
});
Vue.use(VueMarkdownEditor);
VueMarkdownEditor.use(vuepressTheme, {
  Prism,
});
VueMarkdownEditor.lang.use('de-DE', deDE);

Vue.config.productionTip = false;

Vue.use(VueMatomo, {
  host: process.env.VUE_APP_MATOMO_URL,
  siteId: 1,
  trackerFileName: 'matomo',
  router: router,
  enableLinkTracking: true,
  requireConsent: false,
  trackInitialView: true,
  disableCookies: true,
  enableHeartBeatTimer: false,
  heartBeatTimerInterval: 15,
  debug: false,
  userId: undefined,
  cookieDomain: undefined,
  domains: undefined,
  preInitActions: [],
});

type D = Icon.Default & {
  getIconUrl?: string;
};

delete (Icon.Default.prototype as D).getIconUrl;
Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
