











import { Component, Emit, Vue } from 'vue-property-decorator';
import { Filter } from '@/assets/types/types';
import HoverOutlineButton from '@/components/partials/HoverOutlineButton.vue';
import ButtonType from '@/assets/buttonTypes';

@Component({
  components: { HoverOutlineButton },
})
export default class FilterButtonBar extends Vue {
  private ButtonType = ButtonType;
  private buttons: {text: string, active: boolean, filterValue: Filter}[] = [
    {
      text: 'Alle',
      active: true,
      filterValue: undefined,
    },
    {
      text: 'iOS',
      active: false,
      filterValue: 'ios',
    },
    {
      text: 'Android',
      active: false,
      filterValue: 'android',
    },
    {
      text: 'Web',
      active: false,
      filterValue: 'web',
    },
    {
      text: 'Projekt',
      active: false,
      filterValue: 'project-only',
    },
  ];

  private currentActive: number = 0;

  @Emit()
  private valueChanged() {
    return this.buttons[this.currentActive].filterValue;
  }

  private onButtonClick(index: number) {
    this.buttons[this.currentActive].active = false;
    this.buttons[index].active = true;
    this.currentActive = index;
    this.valueChanged(); // emit event
  }
}
