




import {
  Component, Prop, Vue,
} from 'vue-property-decorator';

@Component({
  components: { },
})
export default class LoadingAnimation extends Vue {
  @Prop({ default: '60px' })
  private size!: string;

  @Prop({ default: '#FFA329' })
  private baseColor!: string;
}
