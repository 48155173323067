import axios from 'axios';
import { UserRole } from '@/apis/userapi';
import Services from './Services';
import Role from '../roles';
import store from '@/store/store';

export default class Authservice {

  public static async isLoggedIn(): Promise<boolean> {
    try {
      await Services.users.isLoggedIn();
      return true;
    } catch (error) {
      return false;
    }
  }

  public static login(): void {
    window.location.href = `${process.env.VUE_APP_API_GATEWAY_ONLY_URL}/oauth2/authorization/keycloak`;
  }

  public static async logout(): Promise<void> {
    try {
      await axios.get(`${process.env.VUE_APP_API_GATEWAY_URL}/logout`, {
        withCredentials: true,
      });
      window.location.reload();
    } catch (error) {
      window.location.reload();
    }
  }

  public static getUserRole(): UserRole {
    if (store.state.role) {
      return { role: store.state.role };
    }
    return { role: Role.NOROLE };
  }

  public static async addRole(role: string): Promise<void> {
    try {
      await Services.users.addRoleToCurrentUser({ role: role });
      store.commit('setRole', role);

      if (role === Role.DEVELOPER) {
        await Services.mails.sendMailWithNewUserDataToAdmins({ userId: store.state.userId });
        window.location.href = `${process.env.VUE_APP_FRONTEND_URL}/developer-account-info`;
      } else if (role === Role.USER) {
        Authservice.login();
      }
    } catch (error) {
      Authservice.login();
    }
  }

}
