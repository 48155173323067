import Vue from 'vue';

Vue.component('icon-edit', () => import(/* webpackChunkName: "icon-edit" */ './images/icons/edit.svg'));
Vue.component('icon-alert-circle', () => import(/* webpackChunkName: "icon-alert-circle" */ './images/icons/alert-circle.svg'));
Vue.component('icon-question-circle', () => import(/* webpackChunkName: "icon-question-circle" */ './images/icons/question-circle.svg'));
Vue.component('icon-eye', () => import(/* webpackChunkName: "icon-eye" */ './images/icons/eye.svg'));
Vue.component('icon-trash', () => import(/* webpackChunkName: "icon-trash" */ './images/icons/trash.svg'));
Vue.component('icon-desktop-computer', () => import(/* webpackChunkName: "icon-desktop-computer" */ './images/icons/desktop-computer.svg'));
Vue.component('icon-filter', () => import(/* webpackChunkName: "icon-filter" */ './images/icons/filter.svg'));
Vue.component('icon-check-circle', () => import(/* webpackChunkName: "icon-check-circle" */ './images/icons/check-circle.svg'));
Vue.component('icon-play', () => import(/* webpackChunkName: "icon-play" */ './images/icons/play.svg'));
Vue.component('icon-clock', () => import(/* webpackChunkName: "icon-clock" */ './images/icons/clock.svg'));
Vue.component('icon-search', () => import(/* webpackChunkName: "icon-search" */ './images/icons/search.svg'));
Vue.component('icon-plus', () => import(/* webpackChunkName: "icon-plus" */ './images/icons/plus.svg'));
Vue.component('icon-x', () => import(/* webpackChunkName: "icon-x" */ './images/icons/x.svg'));
Vue.component('icon-chevron-double-right', () => import(/* webpackChunkName: "icon-chevron-double-right" */ './images/icons/chevron-double-right.svg'));
Vue.component('icon-chevron-double-left', () => import(/* webpackChunkName: "icon-chevron-double-left" */ './images/icons/chevron-double-left.svg'));
Vue.component('icon-chevron-right', () => import(/* webpackChunkName: "icon-chevron-right" */ './images/icons/chevron-right.svg'));
Vue.component('icon-chevron-left', () => import(/* webpackChunkName: "icon-chevron-left" */ './images/icons/chevron-left.svg'));
Vue.component('icon-arrow-left', () => import(/* webpackChunkName: "icon-arrow-left" */ './images/icons/arrow-left.svg'));
Vue.component('icon-arrow-right', () => import(/* webpackChunkName: "icon-arrow-right" */ './images/icons/arrow-right--white.svg'));
Vue.component('icon-emoji-happy', () => import(/* webpackChunkName: "icon-emoji-happy" */ './images/icons/emoji-happy.svg'));
Vue.component('icon-emoji-sad', () => import(/* webpackChunkName: "icon-emoji-sad" */ './images/icons/emoji-sad.svg'));
Vue.component('icon-emoji-neutral', () => import(/* webpackChunkName: "icon-emoji-sad" */ './images/icons/smiley-indifferent.svg'));
Vue.component('icon-document-report', () => import(/* webpackChunkName: "icon-document-report" */ './images/icons/document-report.svg'));

Vue.component('logo-innovamo', () => import(/* webpackChunkName: "logo-innovamo" */ './images/logo/logo_innovamo.svg'));
Vue.component('logo-innovamo-inv', () => import(/* webpackChunkName: "logo-innovamo-inv" */ './images/logo/logo_innovamo_inv.svg'));
Vue.component('logo-small-light', () => import(/* webpackChunkName: "logo-small-light" */ './images/logo/logo-small-light.svg'));
Vue.component('logo-facebook', () => import(/* webpackChunkName: "logo-facebook" */ './images/logo/facebook.svg'));
Vue.component('logo-instagram', () => import(/* webpackChunkName: "logo-instagram" */ './images/icons/instagram.svg'));
Vue.component('logo-linkedin', () => import(/* webpackChunkName: "logo-linkedin" */ './images/logo/linkedin.svg'));
Vue.component('logo-xing', () => import(/* webpackChunkName: "logo-xing" */ './images/logo/xing.svg'));
Vue.component('icon-thump-up', () => import(/* webpackChunkName: "icon-thump-up" */ './images/icons/thumb-up.svg'));
Vue.component('icon-thump-up-filled', () => import(/* webpackChunkName: "icon-thump-up-filled" */ './images/icons/thumb-up-filled.svg'));
Vue.component('icon-ios', () => import(/* webpackChunkName: "icon-ios" */ './images/icons/os-system-apple.svg'));
Vue.component('icon-android', () => import(/* webpackChunkName: "icon-android" */ './images/icons/android-1.svg'));
Vue.component('icon-web', () => import(/* webpackChunkName: "icon-web" */ './images/icons/network-navigation.svg'));
Vue.component('icon-project', () => import(/* webpackChunkName: "icon-project" */ './images/icons/checklist.svg'));
Vue.component('icon-arrow-left', () => import(/* webpackChunkName: "icon-arrow-left" */ './images/icons/arrow-left.svg'));
Vue.component('icon-logout', () => import(/* webpackChunkName: "icon-logout" */ './images/icons/logout.svg'));
Vue.component('icon-remove', () => import(/* webpackChunkName: "icon-remove" */ './images/icons/remove.svg'));
Vue.component('icon-remove-white', () => import(/* webpackChunkName: "icon-remove-white" */ './images/icons/remove--white.svg'));
Vue.component('icon-download', () => import(/* webpackChunkName: "icon-download" */ './images/icons/download-thick-bottom.svg'));
Vue.component('icon-checkmark', () => import(/* webpackChunkName: "icon-checkmark" */ './images/icons/check-1.svg'));
Vue.component('icon-heart', () => import(/* webpackChunkName: "icon-heart" */ './images/icons/love-it.svg'));
Vue.component('icon-arrow-down', () => import(/* webpackChunkName: "icon-arrow-down" */ './images/icons/arrow-down.svg'));
Vue.component('icon-smartphone', () => import(/* webpackChunkName: "icon-smartphone" */ './images/icons/mobile-phone-1.svg'));
Vue.component('icon-arrow-button-down', () => import(/* webpackChunkName: "icon-arrow-button-down" */ './images/icons/arrow-button-down-2.svg'));
Vue.component('icon-add-text', () => import(/* webpackChunkName: "icon-add-text" */ './images/icons/add-textblock.svg'));
Vue.component('icon-add-image', () => import(/* webpackChunkName: "icon-add-image" */ './images/icons/add-imageblock.svg'));
Vue.component('icon-add-gallery', () => import(/* webpackChunkName: "icon-add-gallery" */ './images/icons/add-galleryblock.svg'));
Vue.component('icon-add-text-and-image', () => import(/* webpackChunkName: "icon-add-text-and-image" */ './images/icons/add-text-and-image.svg'));
Vue.component('icon-add-hero', () => import(/* webpackChunkName: "icon-add-hero" */ './images/icons/add-hero.svg'));
Vue.component('icon-add-video', () => import(/* webpackChunkName: "icon-add-video" */ './images/icons/add-video.svg'));
Vue.component('icon-close', () => import(/* webpackChunkName: "icon-close" */ './images/icons/icon-close.svg'));
Vue.component('icon-menu', () => import(/* webpackChunkName: "icon-menu" */ './images/icons/icon-menu.svg'));
Vue.component('icon-slider', () => import(/* webpackChunkName: "icon-slider" */ './images/icons/setting-slider-horizontal.svg'));
Vue.component('icon-text-bullets', () => import(/* webpackChunkName: "icon-text-bullets" */ './images/icons/paragraph-bullets.svg'));
Vue.component('icon-settings-horizontal', () => import(/* webpackChunkName: "icon-settings-horizontal" */ './images/icons/settings-horizontal.svg'));
Vue.component('icon-text-question', () => import(/* webpackChunkName: "icon-text-question" */ './images/icons/paragraph-normal.svg'));
