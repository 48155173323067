


























import {
  Component, Emit, Prop, VModel, Vue,
} from 'vue-property-decorator';
import { directive as onClickAway } from 'vue-clickaway';

export interface Option {
  name: string | undefined;
  value: any;
}

@Component({
  directives: {
    onClickAway,
  },
})
export default class CustomSelect extends Vue {

  @VModel({ default: () => ({ name: undefined, value: undefined }) })
  private selectedValue!: Option;

  @Prop({ default: '' })
  private label!: string;

  @Prop({ default: undefined })
  private noSelectionPlaceHolder!: string | undefined;

  private showOptions: boolean = false;

  @Prop({ required: true })
  private options!: Option[];

  @Emit()
  private change() {
    // emits event
  }

  private mounted() {

    if (this.selectedValue.name !== undefined && this.selectedValue.name.trim() !== '') return;

    if (this.noSelectionPlaceHolder !== undefined && this.noSelectionPlaceHolder.trim() !== '') {
      this.selectedValue.name = this.noSelectionPlaceHolder;
    } else {
      this.select(this.options[0]);
    }
  }

  private hideOptions() {
    this.showOptions = false;
  }

  private select(option: Option) {
    this.selectedValue.name = option.name;
    this.selectedValue.value = option.value;
    this.showOptions = false;
    this.change();
  }
}
