


























import { Component, Prop, Vue } from 'vue-property-decorator';
import Services from '@/assets/services/Services';
import BaseCard from '@/components/partials/BaseCard.vue';
import LinkWithArrow from '@/components/partials/LinkWithArrow.vue';

@Component({
  components: { BaseCard, LinkWithArrow },
})
export default class ProjectCard extends Vue {
  @Prop({ required: true })
  private readonly imageSrc!: string;

  @Prop({ required: true })
  private readonly heading!: string;

  @Prop({ required: true })
  private readonly description!: string;

  @Prop({ type: Number, default: 0 })
  private readonly thumbsUpCount!: number;

  @Prop({ required: true })
  private readonly linkHref!: string;

  @Prop({ default: false })
  private readonly hasAndroidApp!: boolean;

  @Prop({ default: false })
  private readonly hasIOSApp!: boolean;

  @Prop({ default: false })
  private readonly hasWebApp!: boolean;

  @Prop({ required: true })
  private readonly appUuid!: string;

  // only for launching purposes, remove if the real apps and projects has enough likes
  private showThumbUpIcon: boolean = false;

  private icons = {
    ios: require('@/assets/images/icons/os-system-apple.svg'),
    android: require('@/assets/images/icons/android-1.svg'),
    web: require('@/assets/images/icons/network-navigation.svg'),
    project: require('@/assets/images/icons/checklist.svg'),
  };

  private linkToProjectDetails() {
    this.$router.push(this.linkHref);
  }

  private isLikedByCurrentUser: boolean = false;

  private async mounted() {
    if (this.$store.state.isLoggedIn) {
      const response = await Services.projects.isProjectLikedByUser(this.appUuid, this.$store.state.userId);
      if (response.status === 200) {
        this.isLikedByCurrentUser = response.data.isLikedByUser as boolean;
      }
    }
  }
}
