







































import { Component, Prop, Vue } from 'vue-property-decorator';
import ButtonType from '@/assets/buttonTypes';

@Component({})
export default class RoundedButtonOutlined extends Vue {
  private ButtonType = ButtonType;

  @Prop({ required: true })
  private readonly text!: string;

  @Prop({ default: undefined })
  private readonly icon!: Vue.Component;

  @Prop({ default: undefined })
  private readonly iconComponentName!: string | undefined;

  @Prop({ default: false })
  private readonly iconLeft!: boolean;

  @Prop({ required: true })
  private variant!: ButtonType;

  private beforeMount() {
    const { components } = this.$options;
    if (components) {
      components.icon = this.icon;
    }
  }

  private click() {
    this.$emit('click');
  }

}
