











import {
  Component, Emit, Prop, VModel, Vue,
} from 'vue-property-decorator';
import ButtonType from '@/assets/buttonTypes';

@Component
export default class HoverOutlineButton extends Vue {
  private ButtonType = ButtonType;

  @Prop({ required: true })
  private readonly text!: string;

  @Prop({ required: true })
  private variant!: ButtonType;

  @VModel()
  private readonly focused!: boolean;

  @Emit()
  private click() {
    // empty
  }
}
