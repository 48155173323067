/* tslint:disable */
/* eslint-disable */
/**
 * News API
 * News API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @enum {string}
 */

export enum BlockType {
    Text = 'TEXT',
    Hero = 'HERO',
    Image = 'IMAGE',
    ImageWithText = 'IMAGE_WITH_TEXT',
    Gallery = 'GALLERY',
    Video = 'VIDEO'
}

/**
 * 
 * @export
 * @interface FileUpload
 */
export interface FileUpload {
    /**
     * 
     * @type {number}
     * @memberof FileUpload
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof FileUpload
     */
    uuid?: string;
    /**
     * 
     * @type {string}
     * @memberof FileUpload
     */
    filename?: string;
    /**
     * 
     * @type {string}
     * @memberof FileUpload
     */
    filetype?: string;
    /**
     * 
     * @type {boolean}
     * @memberof FileUpload
     */
    isAppLogo?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FileUpload
     */
    isTemporaryUpload?: boolean;
}
/**
 * 
 * @export
 * @interface NewsBlock
 */
export interface NewsBlock {
    /**
     * 
     * @type {string}
     * @memberof NewsBlock
     */
    uuid?: string;
    /**
     * 
     * @type {BlockType}
     * @memberof NewsBlock
     */
    type?: BlockType;
    /**
     * 
     * @type {number}
     * @memberof NewsBlock
     */
    position?: number;
    /**
     * 
     * @type {string}
     * @memberof NewsBlock
     */
    markdownText?: string;
    /**
     * 
     * @type {string}
     * @memberof NewsBlock
     */
    backgroundColor?: string;
    /**
     * 
     * @type {string}
     * @memberof NewsBlock
     */
    textColor?: string;
    /**
     * 
     * @type {string}
     * @memberof NewsBlock
     */
    videoLink?: string;
    /**
     * 
     * @type {Array<FileUpload>}
     * @memberof NewsBlock
     */
    images?: Array<FileUpload>;
    /**
     * 
     * @type {boolean}
     * @memberof NewsBlock
     */
    isImageOnLeftSide?: boolean;
}
/**
 * 
 * @export
 * @interface NewsPage
 */
export interface NewsPage {
    /**
     * 
     * @type {string}
     * @memberof NewsPage
     */
    uuid?: string;
    /**
     * 
     * @type {string}
     * @memberof NewsPage
     */
    title?: string;
    /**
     * 
     * @type {string}
     * @memberof NewsPage
     */
    shortDescription?: string;
    /**
     * 
     * @type {FileUpload}
     * @memberof NewsPage
     */
    titleImage?: FileUpload;
    /**
     * 
     * @type {NewsPageStatus}
     * @memberof NewsPage
     */
    status?: NewsPageStatus;
    /**
     * 
     * @type {string}
     * @memberof NewsPage
     */
    creationDate?: string;
    /**
     * 
     * @type {string}
     * @memberof NewsPage
     */
    publishDate?: string;
    /**
     * 
     * @type {string}
     * @memberof NewsPage
     */
    lastEditingUserId?: string;
    /**
     * 
     * @type {Array<NewsBlock>}
     * @memberof NewsPage
     */
    blocks?: Array<NewsBlock>;
}
/**
 * 
 * @export
 * @interface NewsPageOverview
 */
export interface NewsPageOverview {
    /**
     * 
     * @type {string}
     * @memberof NewsPageOverview
     */
    uuid?: string;
    /**
     * 
     * @type {string}
     * @memberof NewsPageOverview
     */
    title?: string;
    /**
     * 
     * @type {string}
     * @memberof NewsPageOverview
     */
    slug?: string;
    /**
     * 
     * @type {string}
     * @memberof NewsPageOverview
     */
    shortDescription?: string;
    /**
     * 
     * @type {FileUpload}
     * @memberof NewsPageOverview
     */
    titleImage?: FileUpload;
    /**
     * 
     * @type {NewsPageStatus}
     * @memberof NewsPageOverview
     */
    status?: NewsPageStatus;
    /**
     * 
     * @type {string}
     * @memberof NewsPageOverview
     */
    creationDate?: string;
    /**
     * 
     * @type {string}
     * @memberof NewsPageOverview
     */
    publishDate?: string;
    /**
     * 
     * @type {string}
     * @memberof NewsPageOverview
     */
    lastEditingUserId?: string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export enum NewsPageStatus {
    Editing = 'EDITING',
    WaitingToPublish = 'WAITING_TO_PUBLISH',
    Published = 'PUBLISHED'
}

/**
 * 
 * @export
 * @interface PaginatedNewsOverviewResponse
 */
export interface PaginatedNewsOverviewResponse {
    /**
     * 
     * @type {Array<NewsPageOverview>}
     * @memberof PaginatedNewsOverviewResponse
     */
    newsPages?: Array<NewsPageOverview>;
    /**
     * 
     * @type {number}
     * @memberof PaginatedNewsOverviewResponse
     */
    totalPages?: number;
    /**
     * 
     * @type {number}
     * @memberof PaginatedNewsOverviewResponse
     */
    totalItemsFound?: number;
    /**
     * 
     * @type {number}
     * @memberof PaginatedNewsOverviewResponse
     */
    currentPage?: number;
}

/**
 * DefaultApi - axios parameter creator
 * @export
 */
export const DefaultApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * creates a new newspage
         * @param {NewsPage} [newsPage] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createNewsPage: async (newsPage?: NewsPage, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/news`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(newsPage, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * deletes the news page with the given id
         * @param {string} uuid The UUID of the news page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteNewsPage: async (uuid: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'uuid' is not null or undefined
            assertParamExists('deleteNewsPage', 'uuid', uuid)
            const localVarPath = `/news/{uuid}`
                .replace(`{${"uuid"}}`, encodeURIComponent(String(uuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} uuid The UUID of the image to delete
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTemporaryImage: async (uuid: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'uuid' is not null or undefined
            assertParamExists('deleteTemporaryImage', 'uuid', uuid)
            const localVarPath = `/news/image/temporary/{uuid}`
                .replace(`{${"uuid"}}`, encodeURIComponent(String(uuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} uuid The UUID of the image
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadImage: async (uuid: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'uuid' is not null or undefined
            assertParamExists('downloadImage', 'uuid', uuid)
            const localVarPath = `/news/image/{uuid}`
                .replace(`{${"uuid"}}`, encodeURIComponent(String(uuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} uuid The UUID of the temporary saved image
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadTemporaryImage: async (uuid: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'uuid' is not null or undefined
            assertParamExists('downloadTemporaryImage', 'uuid', uuid)
            const localVarPath = `/news/image/temporary/{uuid}`
                .replace(`{${"uuid"}}`, encodeURIComponent(String(uuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * return a list of all news pages, only accessible for admins
         * @param {number} [page] The page number to return
         * @param {number} [pageSize] The number of items to return in one page
         * @param {string} [q] The string used for searching the list of news pages
         * @param {Array<NewsPageStatus>} [filterBy] The new status to filter the news pages by
         * @param {'name' | 'date'} [sortBy] The property to sort the results by
         * @param {'asc' | 'desc'} [order] Determines ascending or descending sorting
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllNews: async (page?: number, pageSize?: number, q?: string, filterBy?: Array<NewsPageStatus>, sortBy?: 'name' | 'date', order?: 'asc' | 'desc', options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/news`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (q !== undefined) {
                localVarQueryParameter['q'] = q;
            }

            if (filterBy) {
                localVarQueryParameter['filterBy'] = filterBy;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sortBy'] = sortBy;
            }

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * return a list of all published news pages
         * @param {number} [page] The page number to return
         * @param {number} [pageSize] The number of items to return in one page
         * @param {string} [q] The string used for searching the list of news pages
         * @param {'name' | 'date'} [sortBy] The property to sort the results by
         * @param {'asc' | 'desc'} [order] Determines ascending or descending sorting
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllPublishedNews: async (page?: number, pageSize?: number, q?: string, sortBy?: 'name' | 'date', order?: 'asc' | 'desc', options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/news/published`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (q !== undefined) {
                localVarQueryParameter['q'] = q;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sortBy'] = sortBy;
            }

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * returns the news page with the given uuid
         * @param {string} uuid The UUID of the news page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNewsPageById: async (uuid: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'uuid' is not null or undefined
            assertParamExists('getNewsPageById', 'uuid', uuid)
            const localVarPath = `/news/{uuid}`
                .replace(`{${"uuid"}}`, encodeURIComponent(String(uuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * returns the news page with the given slug
         * @param {string} slug The slug of the news page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNewsPageBySlug: async (slug: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'slug' is not null or undefined
            assertParamExists('getNewsPageBySlug', 'slug', slug)
            const localVarPath = `/news/slug/{slug}`
                .replace(`{${"slug"}}`, encodeURIComponent(String(slug)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * updates the news page with the given id
         * @param {string} uuid The UUID of the news page
         * @param {NewsPage} [newsPage] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateNewsPage: async (uuid: string, newsPage?: NewsPage, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'uuid' is not null or undefined
            assertParamExists('updateNewsPage', 'uuid', uuid)
            const localVarPath = `/news/{uuid}`
                .replace(`{${"uuid"}}`, encodeURIComponent(String(uuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(newsPage, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary uploads image files for an app/project
         * @param {any} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadImageTemporary: async (file?: any, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/news/image/temporary`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication cookieAuth required


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DefaultApi - functional programming interface
 * @export
 */
export const DefaultApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DefaultApiAxiosParamCreator(configuration)
    return {
        /**
         * creates a new newspage
         * @param {NewsPage} [newsPage] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createNewsPage(newsPage?: NewsPage, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NewsPage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createNewsPage(newsPage, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * deletes the news page with the given id
         * @param {string} uuid The UUID of the news page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteNewsPage(uuid: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteNewsPage(uuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} uuid The UUID of the image to delete
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteTemporaryImage(uuid: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteTemporaryImage(uuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} uuid The UUID of the image
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async downloadImage(uuid: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.downloadImage(uuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} uuid The UUID of the temporary saved image
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async downloadTemporaryImage(uuid: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.downloadTemporaryImage(uuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * return a list of all news pages, only accessible for admins
         * @param {number} [page] The page number to return
         * @param {number} [pageSize] The number of items to return in one page
         * @param {string} [q] The string used for searching the list of news pages
         * @param {Array<NewsPageStatus>} [filterBy] The new status to filter the news pages by
         * @param {'name' | 'date'} [sortBy] The property to sort the results by
         * @param {'asc' | 'desc'} [order] Determines ascending or descending sorting
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllNews(page?: number, pageSize?: number, q?: string, filterBy?: Array<NewsPageStatus>, sortBy?: 'name' | 'date', order?: 'asc' | 'desc', options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedNewsOverviewResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllNews(page, pageSize, q, filterBy, sortBy, order, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * return a list of all published news pages
         * @param {number} [page] The page number to return
         * @param {number} [pageSize] The number of items to return in one page
         * @param {string} [q] The string used for searching the list of news pages
         * @param {'name' | 'date'} [sortBy] The property to sort the results by
         * @param {'asc' | 'desc'} [order] Determines ascending or descending sorting
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllPublishedNews(page?: number, pageSize?: number, q?: string, sortBy?: 'name' | 'date', order?: 'asc' | 'desc', options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedNewsOverviewResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllPublishedNews(page, pageSize, q, sortBy, order, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * returns the news page with the given uuid
         * @param {string} uuid The UUID of the news page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getNewsPageById(uuid: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NewsPage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getNewsPageById(uuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * returns the news page with the given slug
         * @param {string} slug The slug of the news page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getNewsPageBySlug(slug: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NewsPage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getNewsPageBySlug(slug, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * updates the news page with the given id
         * @param {string} uuid The UUID of the news page
         * @param {NewsPage} [newsPage] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateNewsPage(uuid: string, newsPage?: NewsPage, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NewsPage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateNewsPage(uuid, newsPage, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary uploads image files for an app/project
         * @param {any} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uploadImageTemporary(file?: any, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FileUpload>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.uploadImageTemporary(file, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DefaultApi - factory interface
 * @export
 */
export const DefaultApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DefaultApiFp(configuration)
    return {
        /**
         * creates a new newspage
         * @param {NewsPage} [newsPage] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createNewsPage(newsPage?: NewsPage, options?: any): AxiosPromise<NewsPage> {
            return localVarFp.createNewsPage(newsPage, options).then((request) => request(axios, basePath));
        },
        /**
         * deletes the news page with the given id
         * @param {string} uuid The UUID of the news page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteNewsPage(uuid: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteNewsPage(uuid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} uuid The UUID of the image to delete
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTemporaryImage(uuid: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteTemporaryImage(uuid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} uuid The UUID of the image
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadImage(uuid: string, options?: any): AxiosPromise<any> {
            return localVarFp.downloadImage(uuid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} uuid The UUID of the temporary saved image
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadTemporaryImage(uuid: string, options?: any): AxiosPromise<any> {
            return localVarFp.downloadTemporaryImage(uuid, options).then((request) => request(axios, basePath));
        },
        /**
         * return a list of all news pages, only accessible for admins
         * @param {number} [page] The page number to return
         * @param {number} [pageSize] The number of items to return in one page
         * @param {string} [q] The string used for searching the list of news pages
         * @param {Array<NewsPageStatus>} [filterBy] The new status to filter the news pages by
         * @param {'name' | 'date'} [sortBy] The property to sort the results by
         * @param {'asc' | 'desc'} [order] Determines ascending or descending sorting
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllNews(page?: number, pageSize?: number, q?: string, filterBy?: Array<NewsPageStatus>, sortBy?: 'name' | 'date', order?: 'asc' | 'desc', options?: any): AxiosPromise<PaginatedNewsOverviewResponse> {
            return localVarFp.getAllNews(page, pageSize, q, filterBy, sortBy, order, options).then((request) => request(axios, basePath));
        },
        /**
         * return a list of all published news pages
         * @param {number} [page] The page number to return
         * @param {number} [pageSize] The number of items to return in one page
         * @param {string} [q] The string used for searching the list of news pages
         * @param {'name' | 'date'} [sortBy] The property to sort the results by
         * @param {'asc' | 'desc'} [order] Determines ascending or descending sorting
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllPublishedNews(page?: number, pageSize?: number, q?: string, sortBy?: 'name' | 'date', order?: 'asc' | 'desc', options?: any): AxiosPromise<PaginatedNewsOverviewResponse> {
            return localVarFp.getAllPublishedNews(page, pageSize, q, sortBy, order, options).then((request) => request(axios, basePath));
        },
        /**
         * returns the news page with the given uuid
         * @param {string} uuid The UUID of the news page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNewsPageById(uuid: string, options?: any): AxiosPromise<NewsPage> {
            return localVarFp.getNewsPageById(uuid, options).then((request) => request(axios, basePath));
        },
        /**
         * returns the news page with the given slug
         * @param {string} slug The slug of the news page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNewsPageBySlug(slug: string, options?: any): AxiosPromise<NewsPage> {
            return localVarFp.getNewsPageBySlug(slug, options).then((request) => request(axios, basePath));
        },
        /**
         * updates the news page with the given id
         * @param {string} uuid The UUID of the news page
         * @param {NewsPage} [newsPage] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateNewsPage(uuid: string, newsPage?: NewsPage, options?: any): AxiosPromise<NewsPage> {
            return localVarFp.updateNewsPage(uuid, newsPage, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary uploads image files for an app/project
         * @param {any} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadImageTemporary(file?: any, options?: any): AxiosPromise<FileUpload> {
            return localVarFp.uploadImageTemporary(file, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DefaultApi - object-oriented interface
 * @export
 * @class DefaultApi
 * @extends {BaseAPI}
 */
export class DefaultApi extends BaseAPI {
    /**
     * creates a new newspage
     * @param {NewsPage} [newsPage] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public createNewsPage(newsPage?: NewsPage, options?: any) {
        return DefaultApiFp(this.configuration).createNewsPage(newsPage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * deletes the news page with the given id
     * @param {string} uuid The UUID of the news page
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public deleteNewsPage(uuid: string, options?: any) {
        return DefaultApiFp(this.configuration).deleteNewsPage(uuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} uuid The UUID of the image to delete
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public deleteTemporaryImage(uuid: string, options?: any) {
        return DefaultApiFp(this.configuration).deleteTemporaryImage(uuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} uuid The UUID of the image
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public downloadImage(uuid: string, options?: any) {
        return DefaultApiFp(this.configuration).downloadImage(uuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} uuid The UUID of the temporary saved image
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public downloadTemporaryImage(uuid: string, options?: any) {
        return DefaultApiFp(this.configuration).downloadTemporaryImage(uuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * return a list of all news pages, only accessible for admins
     * @param {number} [page] The page number to return
     * @param {number} [pageSize] The number of items to return in one page
     * @param {string} [q] The string used for searching the list of news pages
     * @param {Array<NewsPageStatus>} [filterBy] The new status to filter the news pages by
     * @param {'name' | 'date'} [sortBy] The property to sort the results by
     * @param {'asc' | 'desc'} [order] Determines ascending or descending sorting
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getAllNews(page?: number, pageSize?: number, q?: string, filterBy?: Array<NewsPageStatus>, sortBy?: 'name' | 'date', order?: 'asc' | 'desc', options?: any) {
        return DefaultApiFp(this.configuration).getAllNews(page, pageSize, q, filterBy, sortBy, order, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * return a list of all published news pages
     * @param {number} [page] The page number to return
     * @param {number} [pageSize] The number of items to return in one page
     * @param {string} [q] The string used for searching the list of news pages
     * @param {'name' | 'date'} [sortBy] The property to sort the results by
     * @param {'asc' | 'desc'} [order] Determines ascending or descending sorting
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getAllPublishedNews(page?: number, pageSize?: number, q?: string, sortBy?: 'name' | 'date', order?: 'asc' | 'desc', options?: any) {
        return DefaultApiFp(this.configuration).getAllPublishedNews(page, pageSize, q, sortBy, order, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * returns the news page with the given uuid
     * @param {string} uuid The UUID of the news page
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getNewsPageById(uuid: string, options?: any) {
        return DefaultApiFp(this.configuration).getNewsPageById(uuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * returns the news page with the given slug
     * @param {string} slug The slug of the news page
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getNewsPageBySlug(slug: string, options?: any) {
        return DefaultApiFp(this.configuration).getNewsPageBySlug(slug, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * updates the news page with the given id
     * @param {string} uuid The UUID of the news page
     * @param {NewsPage} [newsPage] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public updateNewsPage(uuid: string, newsPage?: NewsPage, options?: any) {
        return DefaultApiFp(this.configuration).updateNewsPage(uuid, newsPage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary uploads image files for an app/project
     * @param {any} [file] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public uploadImageTemporary(file?: any, options?: any) {
        return DefaultApiFp(this.configuration).uploadImageTemporary(file, options).then((request) => request(this.axios, this.basePath));
    }
}


