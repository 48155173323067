











import {
  Component, Emit, Prop, Vue,
} from 'vue-property-decorator';

@Component
export default class BaseCard extends Vue {
  @Prop({ default: require('@/assets/images/buerger_klein.png') })
  private imgSrc!:string;

  @Prop({ default: false })
  private stretchImage!: boolean;

  @Emit()
  private click() {
    // emit event
  }
}
