




















import { Component, Prop, Vue } from 'vue-property-decorator';
import BaseCard from '@/components/partials/BaseCard.vue';
import LinkWithArrow from '@/components/partials/LinkWithArrow.vue';

@Component({
  components: {
    BaseCard,
    LinkWithArrow,
  },
})
export default class NewsCard extends Vue {
  @Prop({ required: true })
  private readonly imageSrc!: string;

  @Prop({ required: true })
  private readonly heading!: string;

  @Prop({ required: true })
  private readonly description!: string;

  @Prop({ required: true })
  private readonly publishDate!: string;

  @Prop({ required: true })
  private readonly linkHref!: string;

  @Prop({ default: false })
  private readonly isBigCard!: boolean;

  private icons = {
    ios: require('@/assets/images/icons/os-system-apple.svg'),
    android: require('@/assets/images/icons/android-1.svg'),
    web: require('@/assets/images/icons/network-navigation.svg'),
    project: require('@/assets/images/icons/checklist.svg'),
  };

  private linkToNewsArticle() {
    this.$router.push(this.linkHref);
  }
}
