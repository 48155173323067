/* tslint:disable */
/* eslint-disable */
/**
 * App & Project Service
 * This is the API documentation of the innovaMo app & project service
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface AndroidVersion
 */
export interface AndroidVersion {
    /**
     * 
     * @type {number}
     * @memberof AndroidVersion
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof AndroidVersion
     */
    name?: string;
}
/**
 * 
 * @export
 * @interface AppMarker
 */
export interface AppMarker {
    /**
     * 
     * @type {number}
     * @memberof AppMarker
     */
    lat?: number;
    /**
     * 
     * @type {number}
     * @memberof AppMarker
     */
    lng?: number;
    /**
     * 
     * @type {string}
     * @memberof AppMarker
     */
    federalState?: string;
    /**
     * 
     * @type {string}
     * @memberof AppMarker
     */
    county?: string;
}
/**
 * 
 * @export
 * @interface AppProject
 */
export interface AppProject {
    /**
     * 
     * @type {number}
     * @memberof AppProject
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof AppProject
     */
    uuid?: string;
    /**
     * 
     * @type {string}
     * @memberof AppProject
     */
    developerId?: string;
    /**
     * 
     * @type {string}
     * @memberof AppProject
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof AppProject
     */
    nameSlug?: string;
    /**
     * 
     * @type {string}
     * @memberof AppProject
     */
    shortDescription?: string;
    /**
     * 
     * @type {string}
     * @memberof AppProject
     */
    website?: string;
    /**
     * 
     * @type {string}
     * @memberof AppProject
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof AppProject
     */
    clientId?: string;
    /**
     * 
     * @type {string}
     * @memberof AppProject
     */
    clientSecret?: string;
    /**
     * 
     * @type {boolean}
     * @memberof AppProject
     */
    usesGps?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AppProject
     */
    usesContacts?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AppProject
     */
    usesCalendar?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AppProject
     */
    usesNotes?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AppProject
     */
    usesPhotos?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AppProject
     */
    usesBluetooth?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AppProject
     */
    usesVoiceRecognition?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AppProject
     */
    usesCamera?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AppProject
     */
    usesFilesystem?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AppProject
     */
    usesBodyData?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AppProject
     */
    usesMicrophone?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AppProject
     */
    usesSingleSignOn?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AppProject
     */
    usesSurveys?: boolean;
    /**
     * 
     * @type {string}
     * @memberof AppProject
     */
    surveyKey?: string;
    /**
     * 
     * @type {Array<AppTypeRestDto>}
     * @memberof AppProject
     */
    types?: Array<AppTypeRestDto>;
    /**
     * 
     * @type {Array<ProjectUploadRestDto>}
     * @memberof AppProject
     */
    uploads?: Array<ProjectUploadRestDto>;
    /**
     * 
     * @type {Array<string>}
     * @memberof AppProject
     */
    federalStates?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof AppProject
     */
    counties?: Array<string>;
    /**
     * 
     * @type {Array<AppMarker>}
     * @memberof AppProject
     */
    markers?: Array<AppMarker>;
    /**
     * 
     * @type {boolean}
     * @memberof AppProject
     */
    isPublished?: boolean;
    /**
     * 
     * @type {PublishTypeEnum}
     * @memberof AppProject
     */
    publishState?: PublishTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof AppProject
     */
    updatedAt?: string;
    /**
     * 
     * @type {string}
     * @memberof AppProject
     */
    createdAt?: string;
    /**
     * 
     * @type {Array<RejectionReason>}
     * @memberof AppProject
     */
    rejections?: Array<RejectionReason>;
}
/**
 * 
 * @export
 * @interface AppTypeRestDto
 */
export interface AppTypeRestDto {
    /**
     * 
     * @type {number}
     * @memberof AppTypeRestDto
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof AppTypeRestDto
     */
    appId?: number;
    /**
     * 
     * @type {string}
     * @memberof AppTypeRestDto
     */
    appType?: AppTypeRestDtoAppTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof AppTypeRestDto
     */
    weblink?: string;
    /**
     * 
     * @type {OSVersion}
     * @memberof AppTypeRestDto
     */
    minAndroidVersion?: OSVersion;
    /**
     * 
     * @type {OSVersion}
     * @memberof AppTypeRestDto
     */
    minIOSVersion?: OSVersion;
    /**
     * 
     * @type {Array<string>}
     * @memberof AppTypeRestDto
     */
    languages?: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof AppTypeRestDto
     */
    size?: number;
}

/**
    * @export
    * @enum {string}
    */
export enum AppTypeRestDtoAppTypeEnum {
    Android = 'android',
    Ios = 'ios',
    Webapp = 'webapp'
}

/**
 * 
 * @export
 * @interface IOSVersion
 */
export interface IOSVersion {
    /**
     * 
     * @type {number}
     * @memberof IOSVersion
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof IOSVersion
     */
    name?: string;
}
/**
 * 
 * @export
 * @interface OSVersion
 */
export interface OSVersion {
    /**
     * 
     * @type {number}
     * @memberof OSVersion
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof OSVersion
     */
    name?: string;
}
/**
 * 
 * @export
 * @interface PaginatedProjectOverviewRestDto
 */
export interface PaginatedProjectOverviewRestDto {
    /**
     * 
     * @type {Array<ProjectOverviewRestDto>}
     * @memberof PaginatedProjectOverviewRestDto
     */
    projects?: Array<ProjectOverviewRestDto>;
    /**
     * 
     * @type {number}
     * @memberof PaginatedProjectOverviewRestDto
     */
    totalPages?: number;
    /**
     * 
     * @type {number}
     * @memberof PaginatedProjectOverviewRestDto
     */
    totalItemsFound?: number;
    /**
     * 
     * @type {number}
     * @memberof PaginatedProjectOverviewRestDto
     */
    currentPage?: number;
}
/**
 * 
 * @export
 * @interface ProjectLikeStatus
 */
export interface ProjectLikeStatus {
    /**
     * 
     * @type {boolean}
     * @memberof ProjectLikeStatus
     */
    isLikedByUser?: boolean;
}
/**
 * 
 * @export
 * @interface ProjectOverviewRestDto
 */
export interface ProjectOverviewRestDto {
    /**
     * 
     * @type {number}
     * @memberof ProjectOverviewRestDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof ProjectOverviewRestDto
     */
    uuid?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectOverviewRestDto
     */
    developerId?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectOverviewRestDto
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectOverviewRestDto
     */
    nameSlug?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectOverviewRestDto
     */
    surveyKey?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectOverviewRestDto
     */
    shortDescription?: string;
    /**
     * 
     * @type {number}
     * @memberof ProjectOverviewRestDto
     */
    titleImageId?: number;
    /**
     * 
     * @type {boolean}
     * @memberof ProjectOverviewRestDto
     */
    hasAndroidApp?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ProjectOverviewRestDto
     */
    hasIOSApp?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ProjectOverviewRestDto
     */
    hasWebApp?: boolean;
    /**
     * 
     * @type {number}
     * @memberof ProjectOverviewRestDto
     */
    likeCount?: number;
    /**
     * 
     * @type {string}
     * @memberof ProjectOverviewRestDto
     */
    federalStateGeoData?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectOverviewRestDto
     */
    countyGeoData?: string;
    /**
     * 
     * @type {Array<AppMarker>}
     * @memberof ProjectOverviewRestDto
     */
    markers?: Array<AppMarker>;
    /**
     * 
     * @type {PublishTypeEnum}
     * @memberof ProjectOverviewRestDto
     */
    publishState?: PublishTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof ProjectOverviewRestDto
     */
    updatedAt?: string;
}
/**
 * 
 * @export
 * @interface ProjectRestDto
 */
export interface ProjectRestDto {
    /**
     * 
     * @type {number}
     * @memberof ProjectRestDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof ProjectRestDto
     */
    uuid?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectRestDto
     */
    developerId?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectRestDto
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectRestDto
     */
    nameSlug?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectRestDto
     */
    shortDescription?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectRestDto
     */
    website?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectRestDto
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectRestDto
     */
    clientId?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectRestDto
     */
    clientSecret?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ProjectRestDto
     */
    usesGps?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ProjectRestDto
     */
    usesContacts?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ProjectRestDto
     */
    usesCalendar?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ProjectRestDto
     */
    usesNotes?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ProjectRestDto
     */
    usesPhotos?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ProjectRestDto
     */
    usesBluetooth?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ProjectRestDto
     */
    usesVoiceRecognition?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ProjectRestDto
     */
    usesCamera?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ProjectRestDto
     */
    usesFilesystem?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ProjectRestDto
     */
    usesBodyData?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ProjectRestDto
     */
    usesMicrophone?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ProjectRestDto
     */
    usesSingleSignOn?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ProjectRestDto
     */
    usesSurveys?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ProjectRestDto
     */
    surveyKey?: string;
    /**
     * 
     * @type {Array<AppTypeRestDto>}
     * @memberof ProjectRestDto
     */
    types?: Array<AppTypeRestDto>;
    /**
     * 
     * @type {Array<ProjectUploadRestDto>}
     * @memberof ProjectRestDto
     */
    uploads?: Array<ProjectUploadRestDto>;
    /**
     * 
     * @type {Array<string>}
     * @memberof ProjectRestDto
     */
    federalStates?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof ProjectRestDto
     */
    counties?: Array<string>;
    /**
     * 
     * @type {Array<AppMarker>}
     * @memberof ProjectRestDto
     */
    markers?: Array<AppMarker>;
    /**
     * 
     * @type {boolean}
     * @memberof ProjectRestDto
     */
    isPublished?: boolean;
    /**
     * 
     * @type {PublishTypeEnum}
     * @memberof ProjectRestDto
     */
    publishState?: PublishTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof ProjectRestDto
     */
    updatedAt?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectRestDto
     */
    createdAt?: string;
    /**
     * 
     * @type {Array<RejectionReason>}
     * @memberof ProjectRestDto
     */
    rejections?: Array<RejectionReason>;
}
/**
 * 
 * @export
 * @interface ProjectUploadRestDto
 */
export interface ProjectUploadRestDto {
    /**
     * 
     * @type {number}
     * @memberof ProjectUploadRestDto
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof ProjectUploadRestDto
     */
    appId?: number;
    /**
     * 
     * @type {string}
     * @memberof ProjectUploadRestDto
     */
    uuid?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectUploadRestDto
     */
    filename?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectUploadRestDto
     */
    filetype?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ProjectUploadRestDto
     */
    isAppLogo?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ProjectUploadRestDto
     */
    isTemporaryUpload?: boolean;
}
/**
 * 
 * @export
 * @enum {string}
 */

export enum PublishTypeEnum {
    Published = 'PUBLISHED',
    InReview = 'IN_REVIEW',
    Rejected = 'REJECTED',
    Editing = 'EDITING'
}

/**
 * 
 * @export
 * @interface RejectionReason
 */
export interface RejectionReason {
    /**
     * 
     * @type {string}
     * @memberof RejectionReason
     */
    projectUuid?: string;
    /**
     * 
     * @type {string}
     * @memberof RejectionReason
     */
    reviewerUuid?: string;
    /**
     * 
     * @type {string}
     * @memberof RejectionReason
     */
    text?: string;
    /**
     * 
     * @type {string}
     * @memberof RejectionReason
     */
    date?: string;
}

/**
 * DefaultApi - axios parameter creator
 * @export
 */
export const DefaultApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * an admin can accept a project proposed by a developer
         * @summary Accept proposed project
         * @param {string} appId The UUID of the project
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        acceptProject: async (appId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'appId' is not null or undefined
            assertParamExists('acceptProject', 'appId', appId)
            const localVarPath = `/projects/accept/{appId}`
                .replace(`{${"appId"}}`, encodeURIComponent(String(appId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Adds a Project to the list of a particular user
         * @param {string} userId The user id provided by keycloak
         * @param {string} projectId The id of the project that shall be added to the users list
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addProjectToUserLikeList: async (userId: string, projectId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('addProjectToUserLikeList', 'userId', userId)
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('addProjectToUserLikeList', 'projectId', projectId)
            const localVarPath = `/projects/favourites`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (userId !== undefined) {
                localVarQueryParameter['userId'] = userId;
            }

            if (projectId !== undefined) {
                localVarQueryParameter['projectId'] = projectId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Creates a new app or project
         * @param {ProjectRestDto} projectRestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createProject: async (projectRestDto: ProjectRestDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectRestDto' is not null or undefined
            assertParamExists('createProject', 'projectRestDto', projectRestDto)
            const localVarPath = `/projects`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(projectRestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} imageId The ID of the image
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteImage: async (imageId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'imageId' is not null or undefined
            assertParamExists('deleteImage', 'imageId', imageId)
            const localVarPath = `/data/image/{imageId}`
                .replace(`{${"imageId"}}`, encodeURIComponent(String(imageId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Deletes the app/project with the given ID
         * @param {string} appId The UUID of the app or project
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteProjectById: async (appId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'appId' is not null or undefined
            assertParamExists('deleteProjectById', 'appId', appId)
            const localVarPath = `/projects/{appId}`
                .replace(`{${"appId"}}`, encodeURIComponent(String(appId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} imageId The ID of the image
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTemporaryImage: async (imageId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'imageId' is not null or undefined
            assertParamExists('deleteTemporaryImage', 'imageId', imageId)
            const localVarPath = `/data/image/temporary/{imageId}`
                .replace(`{${"imageId"}}`, encodeURIComponent(String(imageId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id id can be \&#39;germany\&#39;, \&#39;county\&#39; or \&#39;federalStates\&#39;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadGeoData: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('downloadGeoData', 'id', id)
            const localVarPath = `/data/geo/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} imageId The ID of the image
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadImage: async (imageId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'imageId' is not null or undefined
            assertParamExists('downloadImage', 'imageId', imageId)
            const localVarPath = `/data/image/{imageId}`
                .replace(`{${"imageId"}}`, encodeURIComponent(String(imageId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Array<string>} counties the counties for which the geo data should be downloaded
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadSpecificCountiesGeoData: async (counties: Array<string>, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'counties' is not null or undefined
            assertParamExists('downloadSpecificCountiesGeoData', 'counties', counties)
            const localVarPath = `/data/geo/counties/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (counties) {
                localVarQueryParameter['counties'] = counties;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Array<string>} federalStates the federalStates for which the geo data should be downloaded
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadSpecificFederalStatesGeoData: async (federalStates: Array<string>, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'federalStates' is not null or undefined
            assertParamExists('downloadSpecificFederalStatesGeoData', 'federalStates', federalStates)
            const localVarPath = `/data/geo/federalstates/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (federalStates) {
                localVarQueryParameter['federalStates'] = federalStates;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} imageId The ID of the image
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadTemporaryImage: async (imageId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'imageId' is not null or undefined
            assertParamExists('downloadTemporaryImage', 'imageId', imageId)
            const localVarPath = `/data/image/temporary/{imageId}`
                .replace(`{${"imageId"}}`, encodeURIComponent(String(imageId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns all projects near by the user position
         * @param {number} userLatitude The latitude of the user position
         * @param {number} userLongitude The longitude of the user position
         * @param {number} radiusInWhichTheProjectsMustBe The radius in which the projects must be
         * @param {string} userFederalState The federalState of the user position
         * @param {string} userCounty The county of the user position
         * @param {boolean} getAllProjects Indicates that all projects are needed
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllProjectsNearTheUserPosition: async (userLatitude: number, userLongitude: number, radiusInWhichTheProjectsMustBe: number, userFederalState: string, userCounty: string, getAllProjects: boolean, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'userLatitude' is not null or undefined
            assertParamExists('getAllProjectsNearTheUserPosition', 'userLatitude', userLatitude)
            // verify required parameter 'userLongitude' is not null or undefined
            assertParamExists('getAllProjectsNearTheUserPosition', 'userLongitude', userLongitude)
            // verify required parameter 'radiusInWhichTheProjectsMustBe' is not null or undefined
            assertParamExists('getAllProjectsNearTheUserPosition', 'radiusInWhichTheProjectsMustBe', radiusInWhichTheProjectsMustBe)
            // verify required parameter 'userFederalState' is not null or undefined
            assertParamExists('getAllProjectsNearTheUserPosition', 'userFederalState', userFederalState)
            // verify required parameter 'userCounty' is not null or undefined
            assertParamExists('getAllProjectsNearTheUserPosition', 'userCounty', userCounty)
            // verify required parameter 'getAllProjects' is not null or undefined
            assertParamExists('getAllProjectsNearTheUserPosition', 'getAllProjects', getAllProjects)
            const localVarPath = `/projects/nearuserposition/map`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (userLatitude !== undefined) {
                localVarQueryParameter['userLatitude'] = userLatitude;
            }

            if (userLongitude !== undefined) {
                localVarQueryParameter['userLongitude'] = userLongitude;
            }

            if (radiusInWhichTheProjectsMustBe !== undefined) {
                localVarQueryParameter['radiusInWhichTheProjectsMustBe'] = radiusInWhichTheProjectsMustBe;
            }

            if (userFederalState !== undefined) {
                localVarQueryParameter['userFederalState'] = userFederalState;
            }

            if (userCounty !== undefined) {
                localVarQueryParameter['userCounty'] = userCounty;
            }

            if (getAllProjects !== undefined) {
                localVarQueryParameter['getAllProjects'] = getAllProjects;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary returns a list of android versions
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAndroidVersions: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/data/android`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary returns a list of iOS versions
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getIOSVersions: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/data/ios`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns the project with the given ID
         * @param {string} appId The UUID of the app or project
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProjectById: async (appId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'appId' is not null or undefined
            assertParamExists('getProjectById', 'appId', appId)
            const localVarPath = `/projects/{appId}`
                .replace(`{${"appId"}}`, encodeURIComponent(String(appId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns the app/project with the given slug
         * @param {string} nameSlug The name slug of the app or project
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProjectBySlug: async (nameSlug: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'nameSlug' is not null or undefined
            assertParamExists('getProjectBySlug', 'nameSlug', nameSlug)
            const localVarPath = `/projects/slug/{nameSlug}`
                .replace(`{${"nameSlug"}}`, encodeURIComponent(String(nameSlug)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns available products
         * @param {number} [page] The page number to return
         * @param {number} [pageSize] The number of items to return in one page
         * @param {string} [q] The string used for searching the list of apps
         * @param {'ios' | 'android' | 'web' | 'project-only'} [filterBy] The App/Project type to filter the results by
         * @param {'name' | 'date'} [sortBy] The property to sort the results by
         * @param {'asc' | 'desc'} [order] Determines ascending or descending sorting
         * @param {Array<PublishTypeEnum>} [publishStates] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProjects: async (page?: number, pageSize?: number, q?: string, filterBy?: 'ios' | 'android' | 'web' | 'project-only', sortBy?: 'name' | 'date', order?: 'asc' | 'desc', publishStates?: Array<PublishTypeEnum>, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/projects`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (q !== undefined) {
                localVarQueryParameter['q'] = q;
            }

            if (filterBy !== undefined) {
                localVarQueryParameter['filterBy'] = filterBy;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sortBy'] = sortBy;
            }

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }

            if (publishStates) {
                localVarQueryParameter['publishStates'] = publishStates;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns all app/project by the developer specified by the given ID
         * @param {string} developerId The name ID of a developer
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProjectsByDeveloperUserId: async (developerId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'developerId' is not null or undefined
            assertParamExists('getProjectsByDeveloperUserId', 'developerId', developerId)
            const localVarPath = `/projects/developer/{developerId}`
                .replace(`{${"developerId"}}`, encodeURIComponent(String(developerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns all app/project by the developer specified by the given ID which uses surveys
         * @param {string} developerId The name ID of a developer
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProjectsByDeveloperUserIdWhichUsesSurveys: async (developerId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'developerId' is not null or undefined
            assertParamExists('getProjectsByDeveloperUserIdWhichUsesSurveys', 'developerId', developerId)
            const localVarPath = `/projects/developer/surveys/{developerId}`
                .replace(`{${"developerId"}}`, encodeURIComponent(String(developerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary returns a list of apps with innovamologin
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProjectsWithInnovamoLogin: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/projects/innovamologin`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns available products
         * @param {number} [page] The page number to return
         * @param {number} [pageSize] The number of items to return in one page
         * @param {string} [q] The string used for searching the list of apps
         * @param {'ios' | 'android' | 'web' | 'project-only'} [filterBy] The App/Project type to filter the results by
         * @param {'name' | 'date'} [sortBy] The property to sort the results by
         * @param {'asc' | 'desc'} [order] Determines ascending or descending sorting
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPublishedProjects: async (page?: number, pageSize?: number, q?: string, filterBy?: 'ios' | 'android' | 'web' | 'project-only', sortBy?: 'name' | 'date', order?: 'asc' | 'desc', options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/projects/published`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (q !== undefined) {
                localVarQueryParameter['q'] = q;
            }

            if (filterBy !== undefined) {
                localVarQueryParameter['filterBy'] = filterBy;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sortBy'] = sortBy;
            }

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns available products
         * @param {number} [page] The page number to return
         * @param {number} [pageSize] The number of items to return in one page
         * @param {string} [q] The string used for searching the list of apps
         * @param {'ios' | 'android' | 'web' | 'project-only'} [filterBy] The App/Project type to filter the results by
         * @param {'name' | 'date'} [sortBy] The property to sort the results by
         * @param {'asc' | 'desc'} [order] Determines ascending or descending sorting
         * @param {number} [userLatitude] The latitude of the user position
         * @param {number} [userLongitude] The longitude of the user position
         * @param {number} [radiusInWhichTheProjectsMustBe] The radius in which the projects must be
         * @param {string} [userFederalState] The federalState of the user position
         * @param {string} [userCounty] The county of the user position
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPublishedProjectsNearUserPosition: async (page?: number, pageSize?: number, q?: string, filterBy?: 'ios' | 'android' | 'web' | 'project-only', sortBy?: 'name' | 'date', order?: 'asc' | 'desc', userLatitude?: number, userLongitude?: number, radiusInWhichTheProjectsMustBe?: number, userFederalState?: string, userCounty?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/projects/published/user-position`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (q !== undefined) {
                localVarQueryParameter['q'] = q;
            }

            if (filterBy !== undefined) {
                localVarQueryParameter['filterBy'] = filterBy;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sortBy'] = sortBy;
            }

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }

            if (userLatitude !== undefined) {
                localVarQueryParameter['userLatitude'] = userLatitude;
            }

            if (userLongitude !== undefined) {
                localVarQueryParameter['userLongitude'] = userLongitude;
            }

            if (radiusInWhichTheProjectsMustBe !== undefined) {
                localVarQueryParameter['radiusInWhichTheProjectsMustBe'] = radiusInWhichTheProjectsMustBe;
            }

            if (userFederalState !== undefined) {
                localVarQueryParameter['userFederalState'] = userFederalState;
            }

            if (userCounty !== undefined) {
                localVarQueryParameter['userCounty'] = userCounty;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns all apps/projects liked by a particular user
         * @param {string} userId The user id provided by keycloak
         * @param {number} [page] The page number to return
         * @param {string} [q] The string used for searching the list of apps
         * @param {'name' | 'date'} [sortBy] The property to sort the results by
         * @param {'asc' | 'desc'} [order] Determines ascending or descending sorting
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserLikedProjects: async (userId: string, page?: number, q?: string, sortBy?: 'name' | 'date', order?: 'asc' | 'desc', options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('getUserLikedProjects', 'userId', userId)
            const localVarPath = `/projects/favourites`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (userId !== undefined) {
                localVarQueryParameter['userId'] = userId;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (q !== undefined) {
                localVarQueryParameter['q'] = q;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sortBy'] = sortBy;
            }

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} appId The ID of the app or project
         * @param {string} userId The user id provided by keycloak
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        isProjectLikedByUser: async (appId: string, userId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'appId' is not null or undefined
            assertParamExists('isProjectLikedByUser', 'appId', appId)
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('isProjectLikedByUser', 'userId', userId)
            const localVarPath = `/projects/favourites/{appId}`
                .replace(`{${"appId"}}`, encodeURIComponent(String(appId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (userId !== undefined) {
                localVarQueryParameter['userId'] = userId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * an admin can reject a app/project proposed by a developer
         * @summary Reject proposed app/project
         * @param {RejectionReason} rejectionReason Rejection reason
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rejectProject: async (rejectionReason: RejectionReason, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'rejectionReason' is not null or undefined
            assertParamExists('rejectProject', 'rejectionReason', rejectionReason)
            const localVarPath = `/projects/reject`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(rejectionReason, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Removes a Project to the list of a particular user
         * @param {string} userId The user id provided by keycloak
         * @param {string} projectId The id of the project that shall be removed from the users list
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeProjectFromUserLikeList: async (userId: string, projectId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('removeProjectFromUserLikeList', 'userId', userId)
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('removeProjectFromUserLikeList', 'projectId', projectId)
            const localVarPath = `/projects/favourites`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (userId !== undefined) {
                localVarQueryParameter['userId'] = userId;
            }

            if (projectId !== undefined) {
                localVarQueryParameter['projectId'] = projectId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Updates the app/project with the given ID
         * @param {string} appId The UUID of the app or project
         * @param {ProjectRestDto} projectRestDto The new version of the app/project
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateProjectById: async (appId: string, projectRestDto: ProjectRestDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'appId' is not null or undefined
            assertParamExists('updateProjectById', 'appId', appId)
            // verify required parameter 'projectRestDto' is not null or undefined
            assertParamExists('updateProjectById', 'projectRestDto', projectRestDto)
            const localVarPath = `/projects/{appId}`
                .replace(`{${"appId"}}`, encodeURIComponent(String(appId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(projectRestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary uploads image files for an app/project
         * @param {number} appId The ID of the app or project
         * @param {boolean} [isLogo] Specifies if uploaded image is the app logo
         * @param {any} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadImage: async (appId: number, isLogo?: boolean, file?: any, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'appId' is not null or undefined
            assertParamExists('uploadImage', 'appId', appId)
            const localVarPath = `/data/{appId}/image`
                .replace(`{${"appId"}}`, encodeURIComponent(String(appId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (isLogo !== undefined) {
                localVarQueryParameter['isLogo'] = isLogo;
            }


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary uploads image files for an app/project
         * @param {boolean} [isLogo] Specifies if uploaded image is the app logo
         * @param {any} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadImageTemporary: async (isLogo?: boolean, file?: any, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/data/image/temporary`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (isLogo !== undefined) {
                localVarQueryParameter['isLogo'] = isLogo;
            }


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DefaultApi - functional programming interface
 * @export
 */
export const DefaultApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DefaultApiAxiosParamCreator(configuration)
    return {
        /**
         * an admin can accept a project proposed by a developer
         * @summary Accept proposed project
         * @param {string} appId The UUID of the project
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async acceptProject(appId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.acceptProject(appId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Adds a Project to the list of a particular user
         * @param {string} userId The user id provided by keycloak
         * @param {string} projectId The id of the project that shall be added to the users list
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addProjectToUserLikeList(userId: string, projectId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addProjectToUserLikeList(userId, projectId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Creates a new app or project
         * @param {ProjectRestDto} projectRestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createProject(projectRestDto: ProjectRestDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProjectRestDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createProject(projectRestDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} imageId The ID of the image
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteImage(imageId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteImage(imageId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Deletes the app/project with the given ID
         * @param {string} appId The UUID of the app or project
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteProjectById(appId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteProjectById(appId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} imageId The ID of the image
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteTemporaryImage(imageId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteTemporaryImage(imageId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id id can be \&#39;germany\&#39;, \&#39;county\&#39; or \&#39;federalStates\&#39;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async downloadGeoData(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.downloadGeoData(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} imageId The ID of the image
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async downloadImage(imageId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.downloadImage(imageId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {Array<string>} counties the counties for which the geo data should be downloaded
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async downloadSpecificCountiesGeoData(counties: Array<string>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.downloadSpecificCountiesGeoData(counties, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {Array<string>} federalStates the federalStates for which the geo data should be downloaded
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async downloadSpecificFederalStatesGeoData(federalStates: Array<string>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.downloadSpecificFederalStatesGeoData(federalStates, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} imageId The ID of the image
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async downloadTemporaryImage(imageId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.downloadTemporaryImage(imageId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Returns all projects near by the user position
         * @param {number} userLatitude The latitude of the user position
         * @param {number} userLongitude The longitude of the user position
         * @param {number} radiusInWhichTheProjectsMustBe The radius in which the projects must be
         * @param {string} userFederalState The federalState of the user position
         * @param {string} userCounty The county of the user position
         * @param {boolean} getAllProjects Indicates that all projects are needed
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllProjectsNearTheUserPosition(userLatitude: number, userLongitude: number, radiusInWhichTheProjectsMustBe: number, userFederalState: string, userCounty: string, getAllProjects: boolean, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ProjectOverviewRestDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllProjectsNearTheUserPosition(userLatitude, userLongitude, radiusInWhichTheProjectsMustBe, userFederalState, userCounty, getAllProjects, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary returns a list of android versions
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAndroidVersions(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AndroidVersion>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAndroidVersions(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary returns a list of iOS versions
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getIOSVersions(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<IOSVersion>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getIOSVersions(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Returns the project with the given ID
         * @param {string} appId The UUID of the app or project
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProjectById(appId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProjectRestDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProjectById(appId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Returns the app/project with the given slug
         * @param {string} nameSlug The name slug of the app or project
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProjectBySlug(nameSlug: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProjectRestDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProjectBySlug(nameSlug, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Returns available products
         * @param {number} [page] The page number to return
         * @param {number} [pageSize] The number of items to return in one page
         * @param {string} [q] The string used for searching the list of apps
         * @param {'ios' | 'android' | 'web' | 'project-only'} [filterBy] The App/Project type to filter the results by
         * @param {'name' | 'date'} [sortBy] The property to sort the results by
         * @param {'asc' | 'desc'} [order] Determines ascending or descending sorting
         * @param {Array<PublishTypeEnum>} [publishStates] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProjects(page?: number, pageSize?: number, q?: string, filterBy?: 'ios' | 'android' | 'web' | 'project-only', sortBy?: 'name' | 'date', order?: 'asc' | 'desc', publishStates?: Array<PublishTypeEnum>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedProjectOverviewRestDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProjects(page, pageSize, q, filterBy, sortBy, order, publishStates, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Returns all app/project by the developer specified by the given ID
         * @param {string} developerId The name ID of a developer
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProjectsByDeveloperUserId(developerId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ProjectOverviewRestDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProjectsByDeveloperUserId(developerId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Returns all app/project by the developer specified by the given ID which uses surveys
         * @param {string} developerId The name ID of a developer
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProjectsByDeveloperUserIdWhichUsesSurveys(developerId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ProjectOverviewRestDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProjectsByDeveloperUserIdWhichUsesSurveys(developerId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary returns a list of apps with innovamologin
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProjectsWithInnovamoLogin(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ProjectOverviewRestDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProjectsWithInnovamoLogin(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Returns available products
         * @param {number} [page] The page number to return
         * @param {number} [pageSize] The number of items to return in one page
         * @param {string} [q] The string used for searching the list of apps
         * @param {'ios' | 'android' | 'web' | 'project-only'} [filterBy] The App/Project type to filter the results by
         * @param {'name' | 'date'} [sortBy] The property to sort the results by
         * @param {'asc' | 'desc'} [order] Determines ascending or descending sorting
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPublishedProjects(page?: number, pageSize?: number, q?: string, filterBy?: 'ios' | 'android' | 'web' | 'project-only', sortBy?: 'name' | 'date', order?: 'asc' | 'desc', options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedProjectOverviewRestDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPublishedProjects(page, pageSize, q, filterBy, sortBy, order, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Returns available products
         * @param {number} [page] The page number to return
         * @param {number} [pageSize] The number of items to return in one page
         * @param {string} [q] The string used for searching the list of apps
         * @param {'ios' | 'android' | 'web' | 'project-only'} [filterBy] The App/Project type to filter the results by
         * @param {'name' | 'date'} [sortBy] The property to sort the results by
         * @param {'asc' | 'desc'} [order] Determines ascending or descending sorting
         * @param {number} [userLatitude] The latitude of the user position
         * @param {number} [userLongitude] The longitude of the user position
         * @param {number} [radiusInWhichTheProjectsMustBe] The radius in which the projects must be
         * @param {string} [userFederalState] The federalState of the user position
         * @param {string} [userCounty] The county of the user position
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPublishedProjectsNearUserPosition(page?: number, pageSize?: number, q?: string, filterBy?: 'ios' | 'android' | 'web' | 'project-only', sortBy?: 'name' | 'date', order?: 'asc' | 'desc', userLatitude?: number, userLongitude?: number, radiusInWhichTheProjectsMustBe?: number, userFederalState?: string, userCounty?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedProjectOverviewRestDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPublishedProjectsNearUserPosition(page, pageSize, q, filterBy, sortBy, order, userLatitude, userLongitude, radiusInWhichTheProjectsMustBe, userFederalState, userCounty, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Returns all apps/projects liked by a particular user
         * @param {string} userId The user id provided by keycloak
         * @param {number} [page] The page number to return
         * @param {string} [q] The string used for searching the list of apps
         * @param {'name' | 'date'} [sortBy] The property to sort the results by
         * @param {'asc' | 'desc'} [order] Determines ascending or descending sorting
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserLikedProjects(userId: string, page?: number, q?: string, sortBy?: 'name' | 'date', order?: 'asc' | 'desc', options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedProjectOverviewRestDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUserLikedProjects(userId, page, q, sortBy, order, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} appId The ID of the app or project
         * @param {string} userId The user id provided by keycloak
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async isProjectLikedByUser(appId: string, userId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProjectLikeStatus>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.isProjectLikedByUser(appId, userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * an admin can reject a app/project proposed by a developer
         * @summary Reject proposed app/project
         * @param {RejectionReason} rejectionReason Rejection reason
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async rejectProject(rejectionReason: RejectionReason, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.rejectProject(rejectionReason, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Removes a Project to the list of a particular user
         * @param {string} userId The user id provided by keycloak
         * @param {string} projectId The id of the project that shall be removed from the users list
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async removeProjectFromUserLikeList(userId: string, projectId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.removeProjectFromUserLikeList(userId, projectId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Updates the app/project with the given ID
         * @param {string} appId The UUID of the app or project
         * @param {ProjectRestDto} projectRestDto The new version of the app/project
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateProjectById(appId: string, projectRestDto: ProjectRestDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProjectRestDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateProjectById(appId, projectRestDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary uploads image files for an app/project
         * @param {number} appId The ID of the app or project
         * @param {boolean} [isLogo] Specifies if uploaded image is the app logo
         * @param {any} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uploadImage(appId: number, isLogo?: boolean, file?: any, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProjectUploadRestDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.uploadImage(appId, isLogo, file, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary uploads image files for an app/project
         * @param {boolean} [isLogo] Specifies if uploaded image is the app logo
         * @param {any} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uploadImageTemporary(isLogo?: boolean, file?: any, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProjectUploadRestDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.uploadImageTemporary(isLogo, file, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DefaultApi - factory interface
 * @export
 */
export const DefaultApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DefaultApiFp(configuration)
    return {
        /**
         * an admin can accept a project proposed by a developer
         * @summary Accept proposed project
         * @param {string} appId The UUID of the project
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        acceptProject(appId: string, options?: any): AxiosPromise<void> {
            return localVarFp.acceptProject(appId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Adds a Project to the list of a particular user
         * @param {string} userId The user id provided by keycloak
         * @param {string} projectId The id of the project that shall be added to the users list
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addProjectToUserLikeList(userId: string, projectId: string, options?: any): AxiosPromise<void> {
            return localVarFp.addProjectToUserLikeList(userId, projectId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Creates a new app or project
         * @param {ProjectRestDto} projectRestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createProject(projectRestDto: ProjectRestDto, options?: any): AxiosPromise<ProjectRestDto> {
            return localVarFp.createProject(projectRestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} imageId The ID of the image
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteImage(imageId: number, options?: any): AxiosPromise<void> {
            return localVarFp.deleteImage(imageId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Deletes the app/project with the given ID
         * @param {string} appId The UUID of the app or project
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteProjectById(appId: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteProjectById(appId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} imageId The ID of the image
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTemporaryImage(imageId: number, options?: any): AxiosPromise<void> {
            return localVarFp.deleteTemporaryImage(imageId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id id can be \&#39;germany\&#39;, \&#39;county\&#39; or \&#39;federalStates\&#39;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadGeoData(id: string, options?: any): AxiosPromise<string> {
            return localVarFp.downloadGeoData(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} imageId The ID of the image
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadImage(imageId: number, options?: any): AxiosPromise<any> {
            return localVarFp.downloadImage(imageId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Array<string>} counties the counties for which the geo data should be downloaded
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadSpecificCountiesGeoData(counties: Array<string>, options?: any): AxiosPromise<string> {
            return localVarFp.downloadSpecificCountiesGeoData(counties, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Array<string>} federalStates the federalStates for which the geo data should be downloaded
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadSpecificFederalStatesGeoData(federalStates: Array<string>, options?: any): AxiosPromise<string> {
            return localVarFp.downloadSpecificFederalStatesGeoData(federalStates, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} imageId The ID of the image
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadTemporaryImage(imageId: number, options?: any): AxiosPromise<any> {
            return localVarFp.downloadTemporaryImage(imageId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns all projects near by the user position
         * @param {number} userLatitude The latitude of the user position
         * @param {number} userLongitude The longitude of the user position
         * @param {number} radiusInWhichTheProjectsMustBe The radius in which the projects must be
         * @param {string} userFederalState The federalState of the user position
         * @param {string} userCounty The county of the user position
         * @param {boolean} getAllProjects Indicates that all projects are needed
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllProjectsNearTheUserPosition(userLatitude: number, userLongitude: number, radiusInWhichTheProjectsMustBe: number, userFederalState: string, userCounty: string, getAllProjects: boolean, options?: any): AxiosPromise<Array<ProjectOverviewRestDto>> {
            return localVarFp.getAllProjectsNearTheUserPosition(userLatitude, userLongitude, radiusInWhichTheProjectsMustBe, userFederalState, userCounty, getAllProjects, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary returns a list of android versions
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAndroidVersions(options?: any): AxiosPromise<Array<AndroidVersion>> {
            return localVarFp.getAndroidVersions(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary returns a list of iOS versions
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getIOSVersions(options?: any): AxiosPromise<Array<IOSVersion>> {
            return localVarFp.getIOSVersions(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns the project with the given ID
         * @param {string} appId The UUID of the app or project
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProjectById(appId: string, options?: any): AxiosPromise<ProjectRestDto> {
            return localVarFp.getProjectById(appId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns the app/project with the given slug
         * @param {string} nameSlug The name slug of the app or project
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProjectBySlug(nameSlug: string, options?: any): AxiosPromise<ProjectRestDto> {
            return localVarFp.getProjectBySlug(nameSlug, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns available products
         * @param {number} [page] The page number to return
         * @param {number} [pageSize] The number of items to return in one page
         * @param {string} [q] The string used for searching the list of apps
         * @param {'ios' | 'android' | 'web' | 'project-only'} [filterBy] The App/Project type to filter the results by
         * @param {'name' | 'date'} [sortBy] The property to sort the results by
         * @param {'asc' | 'desc'} [order] Determines ascending or descending sorting
         * @param {Array<PublishTypeEnum>} [publishStates] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProjects(page?: number, pageSize?: number, q?: string, filterBy?: 'ios' | 'android' | 'web' | 'project-only', sortBy?: 'name' | 'date', order?: 'asc' | 'desc', publishStates?: Array<PublishTypeEnum>, options?: any): AxiosPromise<PaginatedProjectOverviewRestDto> {
            return localVarFp.getProjects(page, pageSize, q, filterBy, sortBy, order, publishStates, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns all app/project by the developer specified by the given ID
         * @param {string} developerId The name ID of a developer
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProjectsByDeveloperUserId(developerId: string, options?: any): AxiosPromise<Array<ProjectOverviewRestDto>> {
            return localVarFp.getProjectsByDeveloperUserId(developerId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns all app/project by the developer specified by the given ID which uses surveys
         * @param {string} developerId The name ID of a developer
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProjectsByDeveloperUserIdWhichUsesSurveys(developerId: string, options?: any): AxiosPromise<Array<ProjectOverviewRestDto>> {
            return localVarFp.getProjectsByDeveloperUserIdWhichUsesSurveys(developerId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary returns a list of apps with innovamologin
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProjectsWithInnovamoLogin(options?: any): AxiosPromise<Array<ProjectOverviewRestDto>> {
            return localVarFp.getProjectsWithInnovamoLogin(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns available products
         * @param {number} [page] The page number to return
         * @param {number} [pageSize] The number of items to return in one page
         * @param {string} [q] The string used for searching the list of apps
         * @param {'ios' | 'android' | 'web' | 'project-only'} [filterBy] The App/Project type to filter the results by
         * @param {'name' | 'date'} [sortBy] The property to sort the results by
         * @param {'asc' | 'desc'} [order] Determines ascending or descending sorting
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPublishedProjects(page?: number, pageSize?: number, q?: string, filterBy?: 'ios' | 'android' | 'web' | 'project-only', sortBy?: 'name' | 'date', order?: 'asc' | 'desc', options?: any): AxiosPromise<PaginatedProjectOverviewRestDto> {
            return localVarFp.getPublishedProjects(page, pageSize, q, filterBy, sortBy, order, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns available products
         * @param {number} [page] The page number to return
         * @param {number} [pageSize] The number of items to return in one page
         * @param {string} [q] The string used for searching the list of apps
         * @param {'ios' | 'android' | 'web' | 'project-only'} [filterBy] The App/Project type to filter the results by
         * @param {'name' | 'date'} [sortBy] The property to sort the results by
         * @param {'asc' | 'desc'} [order] Determines ascending or descending sorting
         * @param {number} [userLatitude] The latitude of the user position
         * @param {number} [userLongitude] The longitude of the user position
         * @param {number} [radiusInWhichTheProjectsMustBe] The radius in which the projects must be
         * @param {string} [userFederalState] The federalState of the user position
         * @param {string} [userCounty] The county of the user position
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPublishedProjectsNearUserPosition(page?: number, pageSize?: number, q?: string, filterBy?: 'ios' | 'android' | 'web' | 'project-only', sortBy?: 'name' | 'date', order?: 'asc' | 'desc', userLatitude?: number, userLongitude?: number, radiusInWhichTheProjectsMustBe?: number, userFederalState?: string, userCounty?: string, options?: any): AxiosPromise<PaginatedProjectOverviewRestDto> {
            return localVarFp.getPublishedProjectsNearUserPosition(page, pageSize, q, filterBy, sortBy, order, userLatitude, userLongitude, radiusInWhichTheProjectsMustBe, userFederalState, userCounty, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns all apps/projects liked by a particular user
         * @param {string} userId The user id provided by keycloak
         * @param {number} [page] The page number to return
         * @param {string} [q] The string used for searching the list of apps
         * @param {'name' | 'date'} [sortBy] The property to sort the results by
         * @param {'asc' | 'desc'} [order] Determines ascending or descending sorting
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserLikedProjects(userId: string, page?: number, q?: string, sortBy?: 'name' | 'date', order?: 'asc' | 'desc', options?: any): AxiosPromise<PaginatedProjectOverviewRestDto> {
            return localVarFp.getUserLikedProjects(userId, page, q, sortBy, order, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} appId The ID of the app or project
         * @param {string} userId The user id provided by keycloak
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        isProjectLikedByUser(appId: string, userId: string, options?: any): AxiosPromise<ProjectLikeStatus> {
            return localVarFp.isProjectLikedByUser(appId, userId, options).then((request) => request(axios, basePath));
        },
        /**
         * an admin can reject a app/project proposed by a developer
         * @summary Reject proposed app/project
         * @param {RejectionReason} rejectionReason Rejection reason
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rejectProject(rejectionReason: RejectionReason, options?: any): AxiosPromise<void> {
            return localVarFp.rejectProject(rejectionReason, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Removes a Project to the list of a particular user
         * @param {string} userId The user id provided by keycloak
         * @param {string} projectId The id of the project that shall be removed from the users list
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeProjectFromUserLikeList(userId: string, projectId: string, options?: any): AxiosPromise<void> {
            return localVarFp.removeProjectFromUserLikeList(userId, projectId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Updates the app/project with the given ID
         * @param {string} appId The UUID of the app or project
         * @param {ProjectRestDto} projectRestDto The new version of the app/project
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateProjectById(appId: string, projectRestDto: ProjectRestDto, options?: any): AxiosPromise<ProjectRestDto> {
            return localVarFp.updateProjectById(appId, projectRestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary uploads image files for an app/project
         * @param {number} appId The ID of the app or project
         * @param {boolean} [isLogo] Specifies if uploaded image is the app logo
         * @param {any} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadImage(appId: number, isLogo?: boolean, file?: any, options?: any): AxiosPromise<ProjectUploadRestDto> {
            return localVarFp.uploadImage(appId, isLogo, file, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary uploads image files for an app/project
         * @param {boolean} [isLogo] Specifies if uploaded image is the app logo
         * @param {any} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadImageTemporary(isLogo?: boolean, file?: any, options?: any): AxiosPromise<ProjectUploadRestDto> {
            return localVarFp.uploadImageTemporary(isLogo, file, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DefaultApi - object-oriented interface
 * @export
 * @class DefaultApi
 * @extends {BaseAPI}
 */
export class DefaultApi extends BaseAPI {
    /**
     * an admin can accept a project proposed by a developer
     * @summary Accept proposed project
     * @param {string} appId The UUID of the project
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public acceptProject(appId: string, options?: any) {
        return DefaultApiFp(this.configuration).acceptProject(appId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Adds a Project to the list of a particular user
     * @param {string} userId The user id provided by keycloak
     * @param {string} projectId The id of the project that shall be added to the users list
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public addProjectToUserLikeList(userId: string, projectId: string, options?: any) {
        return DefaultApiFp(this.configuration).addProjectToUserLikeList(userId, projectId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Creates a new app or project
     * @param {ProjectRestDto} projectRestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public createProject(projectRestDto: ProjectRestDto, options?: any) {
        return DefaultApiFp(this.configuration).createProject(projectRestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} imageId The ID of the image
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public deleteImage(imageId: number, options?: any) {
        return DefaultApiFp(this.configuration).deleteImage(imageId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Deletes the app/project with the given ID
     * @param {string} appId The UUID of the app or project
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public deleteProjectById(appId: string, options?: any) {
        return DefaultApiFp(this.configuration).deleteProjectById(appId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} imageId The ID of the image
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public deleteTemporaryImage(imageId: number, options?: any) {
        return DefaultApiFp(this.configuration).deleteTemporaryImage(imageId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id id can be \&#39;germany\&#39;, \&#39;county\&#39; or \&#39;federalStates\&#39;
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public downloadGeoData(id: string, options?: any) {
        return DefaultApiFp(this.configuration).downloadGeoData(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} imageId The ID of the image
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public downloadImage(imageId: number, options?: any) {
        return DefaultApiFp(this.configuration).downloadImage(imageId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {Array<string>} counties the counties for which the geo data should be downloaded
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public downloadSpecificCountiesGeoData(counties: Array<string>, options?: any) {
        return DefaultApiFp(this.configuration).downloadSpecificCountiesGeoData(counties, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {Array<string>} federalStates the federalStates for which the geo data should be downloaded
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public downloadSpecificFederalStatesGeoData(federalStates: Array<string>, options?: any) {
        return DefaultApiFp(this.configuration).downloadSpecificFederalStatesGeoData(federalStates, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} imageId The ID of the image
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public downloadTemporaryImage(imageId: number, options?: any) {
        return DefaultApiFp(this.configuration).downloadTemporaryImage(imageId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns all projects near by the user position
     * @param {number} userLatitude The latitude of the user position
     * @param {number} userLongitude The longitude of the user position
     * @param {number} radiusInWhichTheProjectsMustBe The radius in which the projects must be
     * @param {string} userFederalState The federalState of the user position
     * @param {string} userCounty The county of the user position
     * @param {boolean} getAllProjects Indicates that all projects are needed
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getAllProjectsNearTheUserPosition(userLatitude: number, userLongitude: number, radiusInWhichTheProjectsMustBe: number, userFederalState: string, userCounty: string, getAllProjects: boolean, options?: any) {
        return DefaultApiFp(this.configuration).getAllProjectsNearTheUserPosition(userLatitude, userLongitude, radiusInWhichTheProjectsMustBe, userFederalState, userCounty, getAllProjects, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary returns a list of android versions
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getAndroidVersions(options?: any) {
        return DefaultApiFp(this.configuration).getAndroidVersions(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary returns a list of iOS versions
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getIOSVersions(options?: any) {
        return DefaultApiFp(this.configuration).getIOSVersions(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns the project with the given ID
     * @param {string} appId The UUID of the app or project
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getProjectById(appId: string, options?: any) {
        return DefaultApiFp(this.configuration).getProjectById(appId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns the app/project with the given slug
     * @param {string} nameSlug The name slug of the app or project
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getProjectBySlug(nameSlug: string, options?: any) {
        return DefaultApiFp(this.configuration).getProjectBySlug(nameSlug, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns available products
     * @param {number} [page] The page number to return
     * @param {number} [pageSize] The number of items to return in one page
     * @param {string} [q] The string used for searching the list of apps
     * @param {'ios' | 'android' | 'web' | 'project-only'} [filterBy] The App/Project type to filter the results by
     * @param {'name' | 'date'} [sortBy] The property to sort the results by
     * @param {'asc' | 'desc'} [order] Determines ascending or descending sorting
     * @param {Array<PublishTypeEnum>} [publishStates] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getProjects(page?: number, pageSize?: number, q?: string, filterBy?: 'ios' | 'android' | 'web' | 'project-only', sortBy?: 'name' | 'date', order?: 'asc' | 'desc', publishStates?: Array<PublishTypeEnum>, options?: any) {
        return DefaultApiFp(this.configuration).getProjects(page, pageSize, q, filterBy, sortBy, order, publishStates, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns all app/project by the developer specified by the given ID
     * @param {string} developerId The name ID of a developer
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getProjectsByDeveloperUserId(developerId: string, options?: any) {
        return DefaultApiFp(this.configuration).getProjectsByDeveloperUserId(developerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns all app/project by the developer specified by the given ID which uses surveys
     * @param {string} developerId The name ID of a developer
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getProjectsByDeveloperUserIdWhichUsesSurveys(developerId: string, options?: any) {
        return DefaultApiFp(this.configuration).getProjectsByDeveloperUserIdWhichUsesSurveys(developerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary returns a list of apps with innovamologin
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getProjectsWithInnovamoLogin(options?: any) {
        return DefaultApiFp(this.configuration).getProjectsWithInnovamoLogin(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns available products
     * @param {number} [page] The page number to return
     * @param {number} [pageSize] The number of items to return in one page
     * @param {string} [q] The string used for searching the list of apps
     * @param {'ios' | 'android' | 'web' | 'project-only'} [filterBy] The App/Project type to filter the results by
     * @param {'name' | 'date'} [sortBy] The property to sort the results by
     * @param {'asc' | 'desc'} [order] Determines ascending or descending sorting
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getPublishedProjects(page?: number, pageSize?: number, q?: string, filterBy?: 'ios' | 'android' | 'web' | 'project-only', sortBy?: 'name' | 'date', order?: 'asc' | 'desc', options?: any) {
        return DefaultApiFp(this.configuration).getPublishedProjects(page, pageSize, q, filterBy, sortBy, order, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns available products
     * @param {number} [page] The page number to return
     * @param {number} [pageSize] The number of items to return in one page
     * @param {string} [q] The string used for searching the list of apps
     * @param {'ios' | 'android' | 'web' | 'project-only'} [filterBy] The App/Project type to filter the results by
     * @param {'name' | 'date'} [sortBy] The property to sort the results by
     * @param {'asc' | 'desc'} [order] Determines ascending or descending sorting
     * @param {number} [userLatitude] The latitude of the user position
     * @param {number} [userLongitude] The longitude of the user position
     * @param {number} [radiusInWhichTheProjectsMustBe] The radius in which the projects must be
     * @param {string} [userFederalState] The federalState of the user position
     * @param {string} [userCounty] The county of the user position
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getPublishedProjectsNearUserPosition(page?: number, pageSize?: number, q?: string, filterBy?: 'ios' | 'android' | 'web' | 'project-only', sortBy?: 'name' | 'date', order?: 'asc' | 'desc', userLatitude?: number, userLongitude?: number, radiusInWhichTheProjectsMustBe?: number, userFederalState?: string, userCounty?: string, options?: any) {
        return DefaultApiFp(this.configuration).getPublishedProjectsNearUserPosition(page, pageSize, q, filterBy, sortBy, order, userLatitude, userLongitude, radiusInWhichTheProjectsMustBe, userFederalState, userCounty, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns all apps/projects liked by a particular user
     * @param {string} userId The user id provided by keycloak
     * @param {number} [page] The page number to return
     * @param {string} [q] The string used for searching the list of apps
     * @param {'name' | 'date'} [sortBy] The property to sort the results by
     * @param {'asc' | 'desc'} [order] Determines ascending or descending sorting
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getUserLikedProjects(userId: string, page?: number, q?: string, sortBy?: 'name' | 'date', order?: 'asc' | 'desc', options?: any) {
        return DefaultApiFp(this.configuration).getUserLikedProjects(userId, page, q, sortBy, order, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} appId The ID of the app or project
     * @param {string} userId The user id provided by keycloak
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public isProjectLikedByUser(appId: string, userId: string, options?: any) {
        return DefaultApiFp(this.configuration).isProjectLikedByUser(appId, userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * an admin can reject a app/project proposed by a developer
     * @summary Reject proposed app/project
     * @param {RejectionReason} rejectionReason Rejection reason
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public rejectProject(rejectionReason: RejectionReason, options?: any) {
        return DefaultApiFp(this.configuration).rejectProject(rejectionReason, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Removes a Project to the list of a particular user
     * @param {string} userId The user id provided by keycloak
     * @param {string} projectId The id of the project that shall be removed from the users list
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public removeProjectFromUserLikeList(userId: string, projectId: string, options?: any) {
        return DefaultApiFp(this.configuration).removeProjectFromUserLikeList(userId, projectId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Updates the app/project with the given ID
     * @param {string} appId The UUID of the app or project
     * @param {ProjectRestDto} projectRestDto The new version of the app/project
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public updateProjectById(appId: string, projectRestDto: ProjectRestDto, options?: any) {
        return DefaultApiFp(this.configuration).updateProjectById(appId, projectRestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary uploads image files for an app/project
     * @param {number} appId The ID of the app or project
     * @param {boolean} [isLogo] Specifies if uploaded image is the app logo
     * @param {any} [file] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public uploadImage(appId: number, isLogo?: boolean, file?: any, options?: any) {
        return DefaultApiFp(this.configuration).uploadImage(appId, isLogo, file, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary uploads image files for an app/project
     * @param {boolean} [isLogo] Specifies if uploaded image is the app logo
     * @param {any} [file] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public uploadImageTemporary(isLogo?: boolean, file?: any, options?: any) {
        return DefaultApiFp(this.configuration).uploadImageTemporary(isLogo, file, options).then((request) => request(this.axios, this.basePath));
    }
}


