













import Vue from 'vue';
import { Component, Watch } from 'vue-property-decorator';
import smoothscroll from 'smoothscroll-polyfill';
import ContentPage from '@/views/contentPages/ContentPage.vue';
import NavigationBar from '@/components/partials/NavigationBar.vue';
import Footer from '@/components/partials/Footer.vue';
import Authservice from './assets/services/Authservice';

smoothscroll.polyfill();

@Component({
  components: { ContentPage, Footer, Header: NavigationBar },
  metaInfo: {
    title: 'innovaMo - digitaler Mobilitäsmarktplatz',
    meta: [
      {
        vmid: 'description',
        name: 'description',
        content: 'innovaMo – der Mobilitätsmarktplatz, der bestehende und neue intelligente Mobilitätslösungen für den ländlichen Raum bereitstellt und miteinander verbindet.',
      },
      {
        vmid: 'preview-image',
        property: 'og:image',
        content: 'https://www.innovamo.de/img/seo/highway.png',
      },
      {
        vmid: 'preview-image-twitter',
        property: 'twitter:image',
        content: 'https://www.innovamo.de/img/seo/highway.png',
      },
    ],
  },
})
export default class App extends Vue {
  private showHeaderAndFooter:boolean = false;

  @Watch('$route')
  private onRouteChange() {
    if (!this.$route.query.openedFromMobileApp) {
      this.showHeaderAndFooter = true;
    }
  }

  private mounted() {
    console.log(process.env.VUE_APP_API_GATEWAY_URL);
    this.startCheckIfLoggedIn();
  }

  private startCheckIfLoggedIn() {
    setInterval(async () => {
      if (this.$store.state.isLoggedIn) {
        const isLoggedIn = await Authservice.isLoggedIn();
        if (!isLoggedIn) {
          Authservice.login();
          // TODO: keep same page open when reload
        }
      }
    }, 600000);
  }

}
