/* tslint:disable */
/* eslint-disable */
/**
 * User Service
 * This is the API documentation of the innovaMo user service
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface CompanyInformation
 */
export interface CompanyInformation {
    /**
     * 
     * @type {string}
     * @memberof CompanyInformation
     */
    companyName?: string;
    /**
     * 
     * @type {string}
     * @memberof CompanyInformation
     */
    companyDescription?: string;
    /**
     * 
     * @type {FileUpload}
     * @memberof CompanyInformation
     */
    companyLogo?: FileUpload;
}
/**
 * 
 * @export
 * @interface FileUpload
 */
export interface FileUpload {
    /**
     * 
     * @type {number}
     * @memberof FileUpload
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof FileUpload
     */
    uuid?: string;
    /**
     * 
     * @type {string}
     * @memberof FileUpload
     */
    filename?: string;
    /**
     * 
     * @type {string}
     * @memberof FileUpload
     */
    filetype?: string;
    /**
     * 
     * @type {boolean}
     * @memberof FileUpload
     */
    isAppLogo?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FileUpload
     */
    isTemporaryUpload?: boolean;
}
/**
 * 
 * @export
 * @interface InnovamoUser
 */
export interface InnovamoUser {
    /**
     * 
     * @type {number}
     * @memberof InnovamoUser
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof InnovamoUser
     */
    userId?: string;
    /**
     * 
     * @type {UserState}
     * @memberof InnovamoUser
     */
    userState?: UserState;
    /**
     * 
     * @type {UserRole}
     * @memberof InnovamoUser
     */
    role?: UserRole;
    /**
     * 
     * @type {string}
     * @memberof InnovamoUser
     */
    emailAddress?: string;
    /**
     * 
     * @type {string}
     * @memberof InnovamoUser
     */
    salutation?: string;
    /**
     * 
     * @type {string}
     * @memberof InnovamoUser
     */
    firstname?: string;
    /**
     * 
     * @type {string}
     * @memberof InnovamoUser
     */
    lastname?: string;
    /**
     * 
     * @type {string}
     * @memberof InnovamoUser
     */
    birthdayDate?: string;
    /**
     * 
     * @type {string}
     * @memberof InnovamoUser
     */
    countryRegion?: string;
    /**
     * 
     * @type {string}
     * @memberof InnovamoUser
     */
    password?: string;
    /**
     * 
     * @type {string}
     * @memberof InnovamoUser
     */
    passwordAgain?: string;
    /**
     * 
     * @type {string}
     * @memberof InnovamoUser
     */
    companyName?: string;
    /**
     * 
     * @type {string}
     * @memberof InnovamoUser
     */
    companyDescription?: string;
    /**
     * 
     * @type {FileUpload}
     * @memberof InnovamoUser
     */
    companyLogo?: FileUpload;
    /**
     * 
     * @type {string}
     * @memberof InnovamoUser
     */
    registerDate?: string;
    /**
     * 
     * @type {string}
     * @memberof InnovamoUser
     */
    lastLoginDate?: string;
}
/**
 * 
 * @export
 * @interface InnovamoUserConsent
 */
export interface InnovamoUserConsent {
    /**
     * 
     * @type {string}
     * @memberof InnovamoUserConsent
     */
    name?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof InnovamoUserConsent
     */
    grantedClientScopes?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof InnovamoUserConsent
     */
    created?: string;
    /**
     * 
     * @type {string}
     * @memberof InnovamoUserConsent
     */
    updated?: string;
}
/**
 * 
 * @export
 * @interface PaginatedListOfInnovamoUsers
 */
export interface PaginatedListOfInnovamoUsers {
    /**
     * 
     * @type {Array<InnovamoUser>}
     * @memberof PaginatedListOfInnovamoUsers
     */
    users?: Array<InnovamoUser>;
    /**
     * 
     * @type {number}
     * @memberof PaginatedListOfInnovamoUsers
     */
    totalPages?: number;
    /**
     * 
     * @type {number}
     * @memberof PaginatedListOfInnovamoUsers
     */
    totalItemsFound?: number;
    /**
     * 
     * @type {number}
     * @memberof PaginatedListOfInnovamoUsers
     */
    currentPage?: number;
}
/**
 * 
 * @export
 * @interface UserRole
 */
export interface UserRole {
    /**
     * 
     * @type {string}
     * @memberof UserRole
     */
    role?: string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export enum UserState {
    Unlocked = 'UNLOCKED',
    Blocked = 'BLOCKED',
    Examination = 'EXAMINATION'
}


/**
 * DefaultApi - axios parameter creator
 * @export
 */
export const DefaultApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Adds a role to the current user
         * @param {UserRole} userRole Role that needs to added
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addRoleToCurrentUser: async (userRole: UserRole, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'userRole' is not null or undefined
            assertParamExists('addRoleToCurrentUser', 'userRole', userRole)
            const localVarPath = `/user/role`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userRole, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary creates a new user
         * @param {InnovamoUser} innovamoUser 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUser: async (innovamoUser: InnovamoUser, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'innovamoUser' is not null or undefined
            assertParamExists('createUser', 'innovamoUser', innovamoUser)
            const localVarPath = `/user/innovamouser`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(innovamoUser, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} imageId The ID of the image
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteImage: async (imageId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'imageId' is not null or undefined
            assertParamExists('deleteImage', 'imageId', imageId)
            const localVarPath = `/user/image/{imageId}`
                .replace(`{${"imageId"}}`, encodeURIComponent(String(imageId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} imageId The ID of the image
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTemporaryImage: async (imageId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'imageId' is not null or undefined
            assertParamExists('deleteTemporaryImage', 'imageId', imageId)
            const localVarPath = `/user/image/temporary/{imageId}`
                .replace(`{${"imageId"}}`, encodeURIComponent(String(imageId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete the user by the given userId
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUser: async (userId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('deleteUser', 'userId', userId)
            const localVarPath = `/user/innovamouser/{userId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} imageId The ID of the image
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadImage: async (imageId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'imageId' is not null or undefined
            assertParamExists('downloadImage', 'imageId', imageId)
            const localVarPath = `/user/image/{imageId}`
                .replace(`{${"imageId"}}`, encodeURIComponent(String(imageId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} imageId The ID of the image
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadTemporaryImage: async (imageId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'imageId' is not null or undefined
            assertParamExists('downloadTemporaryImage', 'imageId', imageId)
            const localVarPath = `/user/image/temporary/{imageId}`
                .replace(`{${"imageId"}}`, encodeURIComponent(String(imageId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary returns all users
         * @param {string} [searchTerm] The string used for searching the list of users
         * @param {number} [page] 
         * @param {number} [numberOfPageEntries] 
         * @param {'asc' | 'desc'} [order] Determines ascending or descending sorting
         * @param {Array<UserState>} [userStates] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllUsersPaginated: async (searchTerm?: string, page?: number, numberOfPageEntries?: number, order?: 'asc' | 'desc', userStates?: Array<UserState>, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/user`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required

            if (searchTerm !== undefined) {
                localVarQueryParameter['searchTerm'] = searchTerm;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (numberOfPageEntries !== undefined) {
                localVarQueryParameter['numberOfPageEntries'] = numberOfPageEntries;
            }

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }

            if (userStates) {
                localVarQueryParameter['userStates'] = userStates;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns the current user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCurrentUser: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/user/current`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} userId The userId to get the user consents
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInnovamoUserConsents: async (userId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('getInnovamoUserConsents', 'userId', userId)
            const localVarPath = `/user/consents/{userId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns the specified user by the given user id.
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserByUserId: async (userId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('getUserByUserId', 'userId', userId)
            const localVarPath = `/user/innovamouser/{userId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns the company information includes the companyName, companyDescription und companyLogo by the given user id.
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserCompanyInformationByUserId: async (userId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('getUserCompanyInformationByUserId', 'userId', userId)
            const localVarPath = `/user/companyinformation/{userId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Check if the current User is logged in
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        isLoggedIn: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/user/login`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} userId The userId to revoke the user consent for that specific user
         * @param {string} clientId The clientId to revoke the user consent for that specific client
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        revokeInnovamoUserConsent: async (userId: string, clientId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('revokeInnovamoUserConsent', 'userId', userId)
            // verify required parameter 'clientId' is not null or undefined
            assertParamExists('revokeInnovamoUserConsent', 'clientId', clientId)
            const localVarPath = `/user/consents/revoke`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required

            if (userId !== undefined) {
                localVarQueryParameter['userId'] = userId;
            }

            if (clientId !== undefined) {
                localVarQueryParameter['clientId'] = clientId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Updates the currentuser
         * @param {InnovamoUser} innovamoUser 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCurrentUser: async (innovamoUser: InnovamoUser, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'innovamoUser' is not null or undefined
            assertParamExists('updateCurrentUser', 'innovamoUser', innovamoUser)
            const localVarPath = `/user/current`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(innovamoUser, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Updates the user specified by the given user id.
         * @param {string} userId 
         * @param {InnovamoUser} innovamoUser 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUserByUserId: async (userId: string, innovamoUser: InnovamoUser, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('updateUserByUserId', 'userId', userId)
            // verify required parameter 'innovamoUser' is not null or undefined
            assertParamExists('updateUserByUserId', 'innovamoUser', innovamoUser)
            const localVarPath = `/user/innovamouser/{userId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(innovamoUser, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Updates the userstatus specified by the given user id.
         * @param {string} userId 
         * @param {string} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUserStateByUserId: async (userId: string, body: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('updateUserStateByUserId', 'userId', userId)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('updateUserStateByUserId', 'body', body)
            const localVarPath = `/user/userstate/{userId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary uploads image files for an app/project
         * @param {any} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadImage: async (file?: any, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/user/upload-image`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication cookieAuth required


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary uploads image files for an app/project
         * @param {any} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadImageTemporary: async (file?: any, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/user/upload-image-temporary`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication cookieAuth required


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DefaultApi - functional programming interface
 * @export
 */
export const DefaultApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DefaultApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Adds a role to the current user
         * @param {UserRole} userRole Role that needs to added
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addRoleToCurrentUser(userRole: UserRole, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addRoleToCurrentUser(userRole, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary creates a new user
         * @param {InnovamoUser} innovamoUser 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createUser(innovamoUser: InnovamoUser, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InnovamoUser>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createUser(innovamoUser, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} imageId The ID of the image
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteImage(imageId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteImage(imageId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} imageId The ID of the image
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteTemporaryImage(imageId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteTemporaryImage(imageId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete the user by the given userId
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteUser(userId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteUser(userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} imageId The ID of the image
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async downloadImage(imageId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.downloadImage(imageId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} imageId The ID of the image
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async downloadTemporaryImage(imageId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.downloadTemporaryImage(imageId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary returns all users
         * @param {string} [searchTerm] The string used for searching the list of users
         * @param {number} [page] 
         * @param {number} [numberOfPageEntries] 
         * @param {'asc' | 'desc'} [order] Determines ascending or descending sorting
         * @param {Array<UserState>} [userStates] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllUsersPaginated(searchTerm?: string, page?: number, numberOfPageEntries?: number, order?: 'asc' | 'desc', userStates?: Array<UserState>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedListOfInnovamoUsers>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllUsersPaginated(searchTerm, page, numberOfPageEntries, order, userStates, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Returns the current user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCurrentUser(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InnovamoUser>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCurrentUser(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} userId The userId to get the user consents
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getInnovamoUserConsents(userId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<InnovamoUserConsent>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getInnovamoUserConsents(userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Returns the specified user by the given user id.
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserByUserId(userId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InnovamoUser>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUserByUserId(userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Returns the company information includes the companyName, companyDescription und companyLogo by the given user id.
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserCompanyInformationByUserId(userId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompanyInformation>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUserCompanyInformationByUserId(userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Check if the current User is logged in
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async isLoggedIn(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.isLoggedIn(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} userId The userId to revoke the user consent for that specific user
         * @param {string} clientId The clientId to revoke the user consent for that specific client
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async revokeInnovamoUserConsent(userId: string, clientId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.revokeInnovamoUserConsent(userId, clientId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Updates the currentuser
         * @param {InnovamoUser} innovamoUser 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateCurrentUser(innovamoUser: InnovamoUser, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InnovamoUser>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateCurrentUser(innovamoUser, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Updates the user specified by the given user id.
         * @param {string} userId 
         * @param {InnovamoUser} innovamoUser 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateUserByUserId(userId: string, innovamoUser: InnovamoUser, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InnovamoUser>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateUserByUserId(userId, innovamoUser, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Updates the userstatus specified by the given user id.
         * @param {string} userId 
         * @param {string} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateUserStateByUserId(userId: string, body: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateUserStateByUserId(userId, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary uploads image files for an app/project
         * @param {any} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uploadImage(file?: any, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FileUpload>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.uploadImage(file, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary uploads image files for an app/project
         * @param {any} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uploadImageTemporary(file?: any, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FileUpload>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.uploadImageTemporary(file, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DefaultApi - factory interface
 * @export
 */
export const DefaultApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DefaultApiFp(configuration)
    return {
        /**
         * 
         * @summary Adds a role to the current user
         * @param {UserRole} userRole Role that needs to added
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addRoleToCurrentUser(userRole: UserRole, options?: any): AxiosPromise<void> {
            return localVarFp.addRoleToCurrentUser(userRole, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary creates a new user
         * @param {InnovamoUser} innovamoUser 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUser(innovamoUser: InnovamoUser, options?: any): AxiosPromise<InnovamoUser> {
            return localVarFp.createUser(innovamoUser, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} imageId The ID of the image
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteImage(imageId: number, options?: any): AxiosPromise<void> {
            return localVarFp.deleteImage(imageId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} imageId The ID of the image
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTemporaryImage(imageId: number, options?: any): AxiosPromise<void> {
            return localVarFp.deleteTemporaryImage(imageId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete the user by the given userId
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUser(userId: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteUser(userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} imageId The ID of the image
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadImage(imageId: number, options?: any): AxiosPromise<any> {
            return localVarFp.downloadImage(imageId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} imageId The ID of the image
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadTemporaryImage(imageId: number, options?: any): AxiosPromise<any> {
            return localVarFp.downloadTemporaryImage(imageId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary returns all users
         * @param {string} [searchTerm] The string used for searching the list of users
         * @param {number} [page] 
         * @param {number} [numberOfPageEntries] 
         * @param {'asc' | 'desc'} [order] Determines ascending or descending sorting
         * @param {Array<UserState>} [userStates] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllUsersPaginated(searchTerm?: string, page?: number, numberOfPageEntries?: number, order?: 'asc' | 'desc', userStates?: Array<UserState>, options?: any): AxiosPromise<PaginatedListOfInnovamoUsers> {
            return localVarFp.getAllUsersPaginated(searchTerm, page, numberOfPageEntries, order, userStates, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns the current user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCurrentUser(options?: any): AxiosPromise<InnovamoUser> {
            return localVarFp.getCurrentUser(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} userId The userId to get the user consents
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInnovamoUserConsents(userId: string, options?: any): AxiosPromise<Array<InnovamoUserConsent>> {
            return localVarFp.getInnovamoUserConsents(userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns the specified user by the given user id.
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserByUserId(userId: string, options?: any): AxiosPromise<InnovamoUser> {
            return localVarFp.getUserByUserId(userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns the company information includes the companyName, companyDescription und companyLogo by the given user id.
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserCompanyInformationByUserId(userId: string, options?: any): AxiosPromise<CompanyInformation> {
            return localVarFp.getUserCompanyInformationByUserId(userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Check if the current User is logged in
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        isLoggedIn(options?: any): AxiosPromise<void> {
            return localVarFp.isLoggedIn(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} userId The userId to revoke the user consent for that specific user
         * @param {string} clientId The clientId to revoke the user consent for that specific client
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        revokeInnovamoUserConsent(userId: string, clientId: string, options?: any): AxiosPromise<void> {
            return localVarFp.revokeInnovamoUserConsent(userId, clientId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Updates the currentuser
         * @param {InnovamoUser} innovamoUser 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCurrentUser(innovamoUser: InnovamoUser, options?: any): AxiosPromise<InnovamoUser> {
            return localVarFp.updateCurrentUser(innovamoUser, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Updates the user specified by the given user id.
         * @param {string} userId 
         * @param {InnovamoUser} innovamoUser 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUserByUserId(userId: string, innovamoUser: InnovamoUser, options?: any): AxiosPromise<InnovamoUser> {
            return localVarFp.updateUserByUserId(userId, innovamoUser, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Updates the userstatus specified by the given user id.
         * @param {string} userId 
         * @param {string} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUserStateByUserId(userId: string, body: string, options?: any): AxiosPromise<void> {
            return localVarFp.updateUserStateByUserId(userId, body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary uploads image files for an app/project
         * @param {any} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadImage(file?: any, options?: any): AxiosPromise<FileUpload> {
            return localVarFp.uploadImage(file, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary uploads image files for an app/project
         * @param {any} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadImageTemporary(file?: any, options?: any): AxiosPromise<FileUpload> {
            return localVarFp.uploadImageTemporary(file, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DefaultApi - object-oriented interface
 * @export
 * @class DefaultApi
 * @extends {BaseAPI}
 */
export class DefaultApi extends BaseAPI {
    /**
     * 
     * @summary Adds a role to the current user
     * @param {UserRole} userRole Role that needs to added
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public addRoleToCurrentUser(userRole: UserRole, options?: any) {
        return DefaultApiFp(this.configuration).addRoleToCurrentUser(userRole, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary creates a new user
     * @param {InnovamoUser} innovamoUser 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public createUser(innovamoUser: InnovamoUser, options?: any) {
        return DefaultApiFp(this.configuration).createUser(innovamoUser, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} imageId The ID of the image
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public deleteImage(imageId: number, options?: any) {
        return DefaultApiFp(this.configuration).deleteImage(imageId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} imageId The ID of the image
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public deleteTemporaryImage(imageId: number, options?: any) {
        return DefaultApiFp(this.configuration).deleteTemporaryImage(imageId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete the user by the given userId
     * @param {string} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public deleteUser(userId: string, options?: any) {
        return DefaultApiFp(this.configuration).deleteUser(userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} imageId The ID of the image
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public downloadImage(imageId: number, options?: any) {
        return DefaultApiFp(this.configuration).downloadImage(imageId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} imageId The ID of the image
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public downloadTemporaryImage(imageId: number, options?: any) {
        return DefaultApiFp(this.configuration).downloadTemporaryImage(imageId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary returns all users
     * @param {string} [searchTerm] The string used for searching the list of users
     * @param {number} [page] 
     * @param {number} [numberOfPageEntries] 
     * @param {'asc' | 'desc'} [order] Determines ascending or descending sorting
     * @param {Array<UserState>} [userStates] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getAllUsersPaginated(searchTerm?: string, page?: number, numberOfPageEntries?: number, order?: 'asc' | 'desc', userStates?: Array<UserState>, options?: any) {
        return DefaultApiFp(this.configuration).getAllUsersPaginated(searchTerm, page, numberOfPageEntries, order, userStates, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns the current user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getCurrentUser(options?: any) {
        return DefaultApiFp(this.configuration).getCurrentUser(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} userId The userId to get the user consents
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getInnovamoUserConsents(userId: string, options?: any) {
        return DefaultApiFp(this.configuration).getInnovamoUserConsents(userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns the specified user by the given user id.
     * @param {string} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getUserByUserId(userId: string, options?: any) {
        return DefaultApiFp(this.configuration).getUserByUserId(userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns the company information includes the companyName, companyDescription und companyLogo by the given user id.
     * @param {string} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getUserCompanyInformationByUserId(userId: string, options?: any) {
        return DefaultApiFp(this.configuration).getUserCompanyInformationByUserId(userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Check if the current User is logged in
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public isLoggedIn(options?: any) {
        return DefaultApiFp(this.configuration).isLoggedIn(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} userId The userId to revoke the user consent for that specific user
     * @param {string} clientId The clientId to revoke the user consent for that specific client
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public revokeInnovamoUserConsent(userId: string, clientId: string, options?: any) {
        return DefaultApiFp(this.configuration).revokeInnovamoUserConsent(userId, clientId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Updates the currentuser
     * @param {InnovamoUser} innovamoUser 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public updateCurrentUser(innovamoUser: InnovamoUser, options?: any) {
        return DefaultApiFp(this.configuration).updateCurrentUser(innovamoUser, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Updates the user specified by the given user id.
     * @param {string} userId 
     * @param {InnovamoUser} innovamoUser 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public updateUserByUserId(userId: string, innovamoUser: InnovamoUser, options?: any) {
        return DefaultApiFp(this.configuration).updateUserByUserId(userId, innovamoUser, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Updates the userstatus specified by the given user id.
     * @param {string} userId 
     * @param {string} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public updateUserStateByUserId(userId: string, body: string, options?: any) {
        return DefaultApiFp(this.configuration).updateUserStateByUserId(userId, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary uploads image files for an app/project
     * @param {any} [file] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public uploadImage(file?: any, options?: any) {
        return DefaultApiFp(this.configuration).uploadImage(file, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary uploads image files for an app/project
     * @param {any} [file] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public uploadImageTemporary(file?: any, options?: any) {
        return DefaultApiFp(this.configuration).uploadImageTemporary(file, options).then((request) => request(this.axios, this.basePath));
    }
}


