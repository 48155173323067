// eslint-disable-next-line no-shadow
enum ButtonType {
    PRIMARY,
    PRIMARY_INVERSE,
    SECONDARY,
    TERTIARY,
    WHITE,
    DELETE,
    SUCCESS
}

export default ButtonType;
